import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_CUSTOMERS_STACK = actionComposer('GET_CUSTOMERS_STACK');
export const getCustomersStack = () => ({
  type: GET_CUSTOMERS_STACK.type,
  payload: queryContentStackByUrl('customers', '/customers', {
    includedReferences: [
      'header.customer_logos',
      'cards.blog_post.blog_post_reference',
      'cards.resource.resource_reference',
      'cards.quote.quote_reference',
      'cards.enhanced_publication.publication_reference',
      'cards.enhanced_publication.publication_reference.categories_tags.product',
      'cards.enhanced_publication.publication_reference.categories_tags.application',
      'cards.enhanced_publication.publication_reference.categories_tags.research_area',
      'cards.case_study.case_study_reference',
      'cards.case_study.case_study_reference.categories_tags.application',
      'cards.case_study.case_study_reference.categories_tags.research_area',
      'cards.case_study.case_study_reference.categories_tags.editing_method',
      'cards.case_study.case_study_reference.categories_tags.paper_type',
      'cards.case_study.case_study_reference.categories_tags.product',
      'cards.case_study.case_study_reference.categories_tags.product.family',
      'cards.case_study.case_study_reference.categories_tags.model_organism',
      'cards.case_study.case_study_reference.categories_tags.customer_affiliation',

      'cards.customer.customer_reference'
    ]
  })
});
