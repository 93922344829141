import {
  GET_DISTRIBUTOR_STACK,
  GET_DISTRIBUTOR_MODAL_STACK
} from 'features/distributor/actions';
import { distributorLockedCountries } from 'src/constants/Countries';
import Cookies from 'js-cookie';

export const getInitialState = () => ({
  distributorStack: {
    name: '',
    address: '',
    address_2: '',
    email: '',
    telephone_number: '',
    fax_number: '',
    website: '',
    country_name: '',
    country_code: ''
  },
  distributorModalStack: {
    name: '',
    address: '',
    address_2: '',
    email: '',
    telephone_number: '',
    fax_number: '',
    website: '',
    country_name: '',
    country_code: ''
  },
  loading: false,
  error: false,
  isLocked: distributorLockedCountries.includes(Cookies.get('ip_country'))
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_DISTRIBUTOR_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_DISTRIBUTOR_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        distributorStack: action.payload[0],
        error: false
      };
    case GET_DISTRIBUTOR_STACK.REJECTED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case GET_DISTRIBUTOR_MODAL_STACK.FULFILLED:
      return {
        ...state,
        distributorModalStack: action.payload[0]
      };
    default:
      return state;
  }
};
