import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_GUIDE_HOME_STACK = actionComposer('GET_GUIDE_HOME_STACK');
export const getGuideHomeStack = url => ({
  type: GET_GUIDE_HOME_STACK.type,
  payload: queryContentStackByUrl('guides_home', url, {
    includedReferences: [
      'chapter_references',
      'related_references.resource_references',
      'related_references.blog_references'
    ]
  })
});
