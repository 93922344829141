import { GET_PODCAST_STACK, GET_ADJACENT_PODCAST_EPISODES } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    author: [],
    content: '',
    date: '',
    cover_image: {
      url: ''
    },
    title: ''
  },
  adjacentEpisodes: []
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PODCAST_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PODCAST_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_PODCAST_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_ADJACENT_PODCAST_EPISODES.FULFILLED:
      return {
        ...state,
        loading: false,
        adjacentEpisodes: action.payload
      };
    default:
      return state;
  }
};
