import localforage from 'localforage';

localforage.config({
  name: 'Synthego Website'
});

export const loadState = async () => {
  try {
    // Since we've updated the cache to use indexedDB, check if the user still has anything in localstorage
    const serializedState = localStorage.getItem('state');
    if (serializedState) {
      const parsedState = JSON.parse(serializedState);
      //Move to indexedDB and remove
      await localforage.setItem('state', parsedState);
      localStorage.removeItem('state');
    }
    const state = await localforage.getItem('state');
    if (state === null) {
      return undefined;
    }
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    localforage.setItem('state', state);
  } catch (err) {
    // Ignore write errors.
  }
};
