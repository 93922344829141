import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';

export const GET_CALCULATOR_STACK = actionComposer('GET_CALCULATOR_STACK');
export const getCalculatorStack = url => ({
  type: GET_CALCULATOR_STACK.type,
  payload: queryContentStackByType('cost_calculator', {
    includedReferences: ['quotes', 'related_content']
  })
});
