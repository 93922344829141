import _ from 'lodash';
import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackById,
  serializeContentStackFormAnswers
} from 'utilities/contentStack';
import { identifyAndTrack } from 'utilities/hubspotTracker';
import { api_add_advanced_request } from 'features/order/advanced/rna/api';

export const ORDER_ADVANCED_RNA_RESET = 'ORDER_ADVANCED_RNA_RESET';
export const reset = () => ({
  type: ORDER_ADVANCED_RNA_RESET
});

export const TOGGLE_EXPANDED = 'TOGGLE_EXPANDED';
export const toggleExpanded = (panel, current) => ({
  type: TOGGLE_EXPANDED,
  expanded_panel: panel === current ? false : panel
});

export const ORDER_ADVANCED_RNA_CHECK_CHECKBOX =
  'ORDER_ADVANCED_RNA_CHECK_CHECKBOX';
export const ORDER_ADVANCED_RNA_UNCHECK_CHECKBOX =
  'ORDER_ADVANCED_RNA_UNCHECK_CHECKBOX';
export const toggleCheckbox = (box, checked, parent_question_id, value) => {
  if (checked) {
    return {
      type: ORDER_ADVANCED_RNA_CHECK_CHECKBOX,
      box: box,
      value: value,
      parent: parent_question_id
    };
  } else {
    return {
      type: ORDER_ADVANCED_RNA_UNCHECK_CHECKBOX,
      box: box,
      value: value,
      parent: parent_question_id
    };
  }
};

export const ORDER_ADVANCED_RNA_TOGGLE_TOGGLE =
  'ORDER_ADVANCED_RNA_TOGGLE_TOGGLE';
export const toggleToggle = (question_id, value) => ({
  question_id,
  value,
  type: ORDER_ADVANCED_RNA_TOGGLE_TOGGLE
});

export const ORDER_ADVANCED_RNA_SET_ANSWER = 'ORDER_ADVANCED_RNA_SET_ANSWER';
export const setAnswer = (id, answer) => ({
  type: ORDER_ADVANCED_RNA_SET_ANSWER,
  id: id,
  answer: answer
});

export const ORDER_ADVANCED_RNA_GET_CS_DATA = actionComposer(
  'ORDER_ADVANCED_RNA_GET_CS_DATA'
);
export const getContentStackData = () => ({
  type: ORDER_ADVANCED_RNA_GET_CS_DATA.type,
  payload: queryContentStackById('advanced_rna_form', 'bltc3a4206e80d91bfe')
});

export const ORDER_ADVANCED_RNA_SUBMIT = actionComposer(
  'ORDER_ADVANCED_RNA_SUBMIT'
);
export const submit = (contentStack, answers) => {
  let email = null;
  for (let question_id in answers) {
    if (_.includes(question_id, 'email')) {
      email = answers[question_id];
    }
  }
  if (email) {
    identifyAndTrack(email, 'Custom RNA Request Form Submitted');
  }
  var formData = serializeContentStackFormAnswers(contentStack, answers);
  return {
    type: ORDER_ADVANCED_RNA_SUBMIT.type,
    payload: api_add_advanced_request(formData)
  };
};
