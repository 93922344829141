import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_OFFER_STACK = actionComposer('GET_OFFER_STACK');
export const getContentStackData = url => ({
  type: GET_OFFER_STACK.type,
  payload: queryContentStackByUrl('offer_detail', url, {
    includedReferences: [
      'quote',
      'related_references.resource_references',
      'related_references.blog_references'
    ]
  })
});

export const SET_CLAIM_FORM_VISIBILITY = 'SET_CLAIM_FORM_VISIBILITY';
export const setClaimFormVisibility = value => ({
  type: SET_CLAIM_FORM_VISIBILITY,
  payload: value
});
