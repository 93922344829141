import React from 'react';
import loadable from '@loadable/component';

const MarketingBase = loadable(() => import('views/Layout/MarketingBase'));

class App extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <MarketingBase />
      </React.Fragment>
    );
  }
}

export default App;
