import actionComposer from 'utilities/actionComposer';
import {
  api_getOrderDetails,
  api_getOrderShippingEstimates,
  api_updateOrderAddress,
  api_validateAddress,
  api_updateOrderShippingMethod
} from './api';

export const GET_ORDER_DETAILS = actionComposer('GET_ORDER_DETAILS');
export const getOrderDetails = orderToken => ({
  type: GET_ORDER_DETAILS.type,
  payload: api_getOrderDetails(orderToken)
});

export const GET_ORDER_SHIPPING_ESTIMATES = actionComposer(
  'GET_ORDER_SHIPPING_ESTIMATES'
);
export const getOrderShippingEstimates = orderToken => ({
  type: GET_ORDER_SHIPPING_ESTIMATES.type,
  payload: api_getOrderShippingEstimates(orderToken)
});

export const UPDATE_ORDER_ADDRESS = actionComposer('UPDATE_ORDER_ADDRESS');
export const updateOrderAddress = (
  orderToken,
  addressType,
  addressId,
  address
) => ({
  type: UPDATE_ORDER_ADDRESS.type,
  payload: api_updateOrderAddress(orderToken, addressType, addressId, address)
});

export const VALIDATE_ADDRESS = actionComposer('VALIDATE_ADDRESS');
export const validateAddress = address => ({
  type: VALIDATE_ADDRESS.type,
  payload: api_validateAddress(address)
});

export const UPDATE_ORDER_SHIPPING_METHOD = actionComposer(
  'UPDATE_ORDER_SHIPPING_METHOD'
);
export const updateOrderShippingMethod = (
  orderToken,
  shippingMethod,
  accountNumber,
  shippingCarrier
) => ({
  type: UPDATE_ORDER_SHIPPING_METHOD.type,
  payload: api_updateOrderShippingMethod(
    orderToken,
    shippingMethod,
    accountNumber,
    shippingCarrier
  )
});

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const setActiveStep = step => ({
  type: SET_ACTIVE_STEP,
  step: step
});
