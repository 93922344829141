import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_TEAM_DETAIL_STACK = actionComposer('GET_TEAM_DETAIL_STACK');
export const getTeamDetailStack = url => ({
  type: GET_TEAM_DETAIL_STACK.type,
  payload: queryContentStackByUrl('author', url, {
    includedReferences: ['featured']
  })
});
