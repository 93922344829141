import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';

export const GET_HOME_STACK = actionComposer('GET_HOME_STACK');
export const getHomeStack = () => ({
  type: GET_HOME_STACK.type,
  payload: queryContentStackByType('enhanced_home', {
    includedReferences: [
      'modular_blocks.section.modular_blocks.publications_list.filter_combos.facets',
      'modular_blocks.section.modular_blocks.experience.experience_reference'
    ]
  })
});

export const GET_CAROUSEL_SLIDES = actionComposer('GET_CAROUSEL_SLIDES');
export const getCarouselSlides = () => ({
  type: GET_CAROUSEL_SLIDES.type,
  payload: queryContentStackByType('carousel_slide', {
    includedReferences: ['content.card_cta.card_cta_reference']
  })
});
