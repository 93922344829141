import {
  ORDER_PRICE_WIDGET_SET_FLAVOR_IDX,
  ORDER_WIDGET_GET_CELLGKO_CELL_LINES,
  ORDER_WIDGET_GET_CELLGKO_GENES,
  ORDER_WIDGET_SET_CELLGKO_LINE,
  ORDER_WIDGET_SET_CELLGKO_GENE,
  ORDER_WIDGET_ADD_CELL_POOL_TO_CART
} from 'features/order/elements/actions';

const getInitialState = () => ({});

const getWidgetInitialState = () => ({
  selectedFlavorIdx: 0,
  pendingAddToCart: false,
  shouldRedirect: false,
  showErrorMessage: false,
  selectedCellLine: '',
  selectedGene: '',
  options: {
    genes: [],
    cellLines: []
  }
});

const widgetReducer = (state = getWidgetInitialState(), action) => {
  switch (action.type) {
    case ORDER_PRICE_WIDGET_SET_FLAVOR_IDX:
      return {
        ...state,
        selectedFlavorIdx: action.selectedIdx
      };
    case ORDER_WIDGET_GET_CELLGKO_CELL_LINES.FULFILLED:
      return {
        ...state,
        options: {
          ...state.options,
          cellLines: action.payload
        }
      };
    case ORDER_WIDGET_GET_CELLGKO_GENES.FULFILLED:
      return {
        ...state,
        options: {
          ...state.options,
          genes: action.payload
        }
      };
    case ORDER_WIDGET_SET_CELLGKO_LINE:
      return {
        ...state,
        selectedCellLine: action.cellLine
      };
    case ORDER_WIDGET_SET_CELLGKO_GENE:
      return {
        ...state,
        selectedGene: action.gene
      };
    case ORDER_WIDGET_ADD_CELL_POOL_TO_CART.FULFILLED:
      return {
        ...state,
        pendingAddToCart: false,
        shouldRedirect: true
      };
    case ORDER_WIDGET_ADD_CELL_POOL_TO_CART.PENDING:
      return {
        ...state,
        pendingAddToCart: true,
        showErrorMessage: false
      };
    case ORDER_WIDGET_ADD_CELL_POOL_TO_CART.REJECTED:
      return {
        ...state,
        pendingAddToCart: false,
        showErrorMessage: true
      };
    default:
      return state;
  }
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case ORDER_WIDGET_GET_CELLGKO_CELL_LINES.FULFILLED:
    case ORDER_WIDGET_GET_CELLGKO_GENES.FULFILLED:
    case ORDER_WIDGET_ADD_CELL_POOL_TO_CART.FULFILLED:
    case ORDER_WIDGET_ADD_CELL_POOL_TO_CART.PENDING:
    case ORDER_WIDGET_ADD_CELL_POOL_TO_CART.REJECTED:
      return {
        ...state,
        [action.meta.url]: widgetReducer(state[action.meta.url], action)
      };
    case ORDER_PRICE_WIDGET_SET_FLAVOR_IDX:
    case ORDER_WIDGET_SET_CELLGKO_LINE:
    case ORDER_WIDGET_SET_CELLGKO_GENE:
      return {
        ...state,
        [action.url]: widgetReducer(state[action.url], action)
      };
    default:
      return state;
  }
};
