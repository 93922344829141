import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_HELP_STACK = actionComposer('GET_HELP_STACK');
export const getHelpStack = () => ({
  type: GET_HELP_STACK.type,
  payload: queryContentStackByUrl('help_landing', '/help', {
    includedReferences: ['featured_articles']
  })
});
