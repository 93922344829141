import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_RESOURCE_DETAIL_STACK = actionComposer(
  'GET_RESOURCE_DETAIL_STACK'
);
export const getResourceDetailStack = url => ({
  type: GET_RESOURCE_DETAIL_STACK.type,
  payload: queryContentStackByUrl('resources_landing_page', url, {
    includedReferences: [
      'reference',
      'resource_category',
      'quote_references',
      'footer_reference'
    ]
  })
});
