import {
  GET_GUIDED_EDIT_LANDING_STACK,
  GET_GUIDED_EDIT_QUESTION_STACK,
  CLEAR_GUIDED_EDIT_QUESTION_STACK,
  GET_GUIDED_EDIT_DESTINATION_STACK,
  CLEAR_GUIDED_EDIT_DESTINATION_STACK,
  GET_GUIDED_EDIT_ALL_QUESTIONS,
  GET_GUIDED_EDIT_ALL_DESTINATIONS,
  SAVE_GUIDED_EDIT_CHOICE,
  GET_GUIDED_EDIT_CHOICES,
  CLEAR_GUIDED_EDIT_CHOICES,
  REPLACE_GUIDED_EDIT_CHOICES,
  RESET_GUIDED_EDIT,
  TOGGLE_ZORK_MODE
} from './actions';

export const getInitialState = () => ({
  loading: true,
  landing: {},
  destination: null,
  question: null,
  savedChoices: [],
  zorkMode: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_GUIDED_EDIT_LANDING_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_GUIDED_EDIT_LANDING_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        landing: action.payload
      };
    case GET_GUIDED_EDIT_LANDING_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_GUIDED_EDIT_QUESTION_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_GUIDED_EDIT_QUESTION_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        question: action.payload
      };
    case GET_GUIDED_EDIT_QUESTION_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_GUIDED_EDIT_DESTINATION_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_GUIDED_EDIT_DESTINATION_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        destination: action.payload
      };
    case GET_GUIDED_EDIT_DESTINATION_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case SAVE_GUIDED_EDIT_CHOICE:
      return {
        ...state,
        savedChoices: action.payload
      };
    case GET_GUIDED_EDIT_CHOICES:
      return {
        ...state,
        savedChoices: action.payload
      };
    case CLEAR_GUIDED_EDIT_CHOICES:
      return {
        ...state,
        savedChoices: action.payload
      };
    case REPLACE_GUIDED_EDIT_CHOICES:
      return {
        ...state,
        savedChoices: action.payload
      };
    case TOGGLE_ZORK_MODE:
      return {
        ...state,
        zorkMode: !state.zorkMode
      };
    case RESET_GUIDED_EDIT:
      return {
        ...state,
        savedChoices: [],
        destination: null,
        question: null
      };
    case CLEAR_GUIDED_EDIT_QUESTION_STACK:
      return {
        ...state,
        question: null
      };
    case CLEAR_GUIDED_EDIT_DESTINATION_STACK:
      return {
        ...state,
        destination: null
      };
    case GET_GUIDED_EDIT_ALL_QUESTIONS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_GUIDED_EDIT_ALL_QUESTIONS.FULFILLED:
      return {
        ...state,
        loading: false,
        allQuestions: action.payload
      };
    case GET_GUIDED_EDIT_ALL_QUESTIONS.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_GUIDED_EDIT_ALL_DESTINATIONS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_GUIDED_EDIT_ALL_DESTINATIONS.FULFILLED:
      return {
        ...state,
        loading: false,
        allDestinations: action.payload
      };
    case GET_GUIDED_EDIT_ALL_DESTINATIONS.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
