import { fRequest } from 'utilities/api';
import config from 'init/config';
import URLS from 'constants/URLS';

export const api_getCellGKOGenes = () =>
  fRequest(`${config.buckRootUrl}/api/products/cells/genes`, {
    method: 'GET'
  });

export const api_getCellGKOCellLines = () =>
  fRequest(`${config.buckRootUrl}/api/products/cells/cell-lines`, {
    method: 'GET'
  });

export const api_addCellPoolGKOToCart = (cellLine, gene, productUrl) => {
  let slug =
    productUrl === URLS.ORDER.CELLGKO_POOL ? 'cell_gko' : 'cell_clonal';
  return fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: [{ cell_line: cellLine, gene_symbol: gene, product_slug: slug }]
  });
};
