import { SET_SECTION } from 'features/welcome/section/actions';

export const getInitialState = () => ({
  section_state: [
    { scrolledTo: false, inView: false, expanded: false },
    { scrolledTo: false, inView: false, expanded: false },
    { scrolledTo: false, inView: false, expanded: false }
  ]
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_SECTION:
      return {
        ...state,
        section_state: {
          ...state.section_state,
          [action.key]: {
            ...state.section_state[action.key],
            ...action.value
          }
        }
      };
    default:
      return state;
  }
};
