import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_WORKFLOWS_DETAIL_STACK = actionComposer(
  'GET_WORKFLOWS_DETAIL_STACK'
);
export const getWorkflowDetailStack = url => ({
  type: GET_WORKFLOWS_DETAIL_STACK.type,
  payload: queryContentStackByUrl('workflow_detail', url, {
    includedReferences: [
      'phases.application_page',
      'phases.application_page.sections.subsections.product_references'
    ]
  })
});
