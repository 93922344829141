import actionComposer from 'utilities/actionComposer';
import {
  api_applyCustomerReferenceCode,
  api_removeCustomerReferenceCode
} from './api';

export const APPLY_CUSTOMER_REFERENCE_CODE = actionComposer(
  'APPLY_CUSTOMER_REFERENCE_CODE'
);
export const applyCustomerReferenceCode = (
  orderToken,
  customerReferenceCode
) => ({
  type: APPLY_CUSTOMER_REFERENCE_CODE.type,
  payload: api_applyCustomerReferenceCode(orderToken, customerReferenceCode)
});

export const REMOVE_CUSTOMER_REFERENCE_CODE = actionComposer(
  'REMOVE_CUSTOMER_REFERENCE_CODE'
);
export const removeCustomerReferenceCode = orderToken => ({
  type: REMOVE_CUSTOMER_REFERENCE_CODE.type,
  payload: api_removeCustomerReferenceCode(orderToken)
});
