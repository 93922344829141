import { GET_PRODUCT_FAMILY_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    cta: [],
    description: '',
    icon: {
      url: ''
    },
    gradient: '',
    product: [],
    quote: [],
    title: ''
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PRODUCT_FAMILY_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PRODUCT_FAMILY_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_PRODUCT_FAMILY_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
