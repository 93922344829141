import _ from 'lodash';
import shortid from 'shortid';
import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';
import { genomeOptions } from './constants';
import { geneArrayToWells, getPlateOption } from './utilities';
import { api_AddToBuckCart, api_GetODLGenes, api_GetODLPrice } from './api';

export const GET_ODL_STACK = actionComposer('GET_ODL_STACK');
export const getODLStack = url => ({
  type: GET_ODL_STACK.type,
  payload: queryContentStackByType('order_odl', {
    includedReferences: ['benefits']
  })
});

export const GET_ODL_GENES = actionComposer('GET_ODL_GENES');
export const getODLGenes = species => ({
  type: GET_ODL_GENES.type,
  payload: api_GetODLGenes(species)
});

export const ADD_ODL_PROJECT_TO_CART = actionComposer(
  'ADD_ODL_PROJECT_TO_CART'
);
export const addProjectToCart = project => ({
  type: ADD_ODL_PROJECT_TO_CART.type,
  payload: api_AddToBuckCart(project)
});

export const CLEAR_ODL_CART_ERRORS = 'CLEAR_ODL_CART_ERRORS';
export const clearCartErrors = () => {
  return {
    type: CLEAR_ODL_CART_ERRORS
  };
};

export const SET_ODL_PROJECT_DETAILS = 'SET_ODL_PROJECT_DETAILS';
export const setProjectDetails = (name, genome, plateType, quantityPerWell) => {
  return {
    type: SET_ODL_PROJECT_DETAILS,
    newDetails: {
      name: name,
      genome: _.find(genomeOptions, ['slug', genome]),
      plateType: getPlateOption(genome, plateType),
      quantityPerWell: quantityPerWell
    }
  };
};

export const SET_ODL_PROJECT_NAME = 'SET_ODL_PROJECT_NAME';
export const setProjectName = name => {
  return {
    type: SET_ODL_PROJECT_NAME,
    newName: name
  };
};

export const SET_ODL_PROJECT_QUANTITY = 'SET_ODL_PROJECT_QUANTITY';
export const setProjectQuantity = quantity => {
  return {
    type: SET_ODL_PROJECT_QUANTITY,
    newQuantity: quantity
  };
};

export const SET_ODL_PROJECT_REPLICATES = 'SET_ODL_PROJECT_REPLICATES';
export const setProjectReplicates = newReplicatesCount => {
  return {
    type: SET_ODL_PROJECT_REPLICATES,
    newReplicatesCount: newReplicatesCount
  };
};

export const SET_ODL_ACTIVE_PLATE = 'SET_ODL_ACTIVE_PLATE';
export const setActivePlate = plateIdx => {
  return {
    type: SET_ODL_ACTIVE_PLATE,
    plateIdx: plateIdx
  };
};

export const SET_ODL_ADD_DIALOG_METHOD = 'SET_ODL_ADD_DIALOG_METHOD';
export const setAddDialogMethod = value => {
  return {
    type: SET_ODL_ADD_DIALOG_METHOD,
    value: value
  };
};

export const RESET_ODL_PROJECT = 'RESET_ODL_PROJECT';
export const resetProject = () => {
  return {
    type: RESET_ODL_PROJECT
  };
};

export const ADD_ODL_EMPTY_PLATE = 'ADD_ODL_EMPTY_PLATE';
export const addEmptyPlate = plateType => {
  //Generate an empty array of the appropriate size and fill it with empty well objects
  const wellsArray = _.range(0, plateType.wellCount)
    .fill({
      value: '',
      id: null,
      type: null,
      errors: [],
      warnings: []
    })
    //Wells need a unique id for drag and drop to work
    .map((w, i) => ({ ...w, id: `well-${shortid.generate()}` }));
  return {
    type: ADD_ODL_EMPTY_PLATE,
    plate: {
      //Plates need a unique id for tabs to work
      id: `plate-${shortid.generate()}`,
      new: true,
      loading: false,
      wells: wellsArray
    }
  };
};

export const ADD_ODL_GENES_TO_PLATE = actionComposer('ADD_ODL_GENES_TO_PLATE');
export const addGenesToPlate = (
  plateIdx,
  genes,
  validGenes,
  wellDirection,
  project
) => {
  return {
    type: ADD_ODL_GENES_TO_PLATE.type,
    meta: {
      plateIdx: plateIdx
    },
    payload: geneArrayToWells(genes, validGenes, wellDirection, project)
  };
};

export const DELETE_ODL_PLATE = 'DELETE_ODL_PLATE';
export const deletePlate = plateIdx => {
  return {
    type: DELETE_ODL_PLATE,
    plateIdx: plateIdx
  };
};

export const SET_ODL_PLATE_NEW = 'SET_ODL_PLATE_NEW';
export const setPlateNew = (plateIdx, value) => {
  return {
    type: SET_ODL_PLATE_NEW,
    plateIdx: plateIdx,
    value: value
  };
};

export const UPDATE_ODL_PLATE_WELLS = 'UPDATE_ODL_PLATE_WELLS';
export const updatePlateWells = (plateIdx, wells) => {
  return {
    type: UPDATE_ODL_PLATE_WELLS,
    plateIdx: plateIdx,
    newWells: wells
  };
};

export const UPDATE_ODL_WELL = 'UPDATE_ODL_WELL';
export const updateWell = (plateIdx, wellIdx, well) => {
  return {
    type: UPDATE_ODL_WELL,
    plateIdx: plateIdx,
    wellIdx: wellIdx,
    newWell: well
  };
};

export const GET_ODL_PRICE = actionComposer('GET_ODL_PRICE');
export const getODLPrice = (
  quantityPerWell,
  replicates,
  totalNumGenes,
  plateFormat,
  species
) => ({
  type: GET_ODL_PRICE.type,
  payload: api_GetODLPrice(
    quantityPerWell,
    replicates,
    totalNumGenes,
    plateFormat,
    species
  )
});
