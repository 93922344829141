export const buildFilterQuery = filters => {
  const queries = {
    year: function(value) {
      return { date: { $regex: `^${value}` } };
    },
    product: function(value) {
      return { products: { $in_query: { uid: value } } };
    },
    categories_tags_product: function(value) {
      return {
        [`categories_tags.product`]: {
          $in_query: { uid: value }
        }
      };
    },
    location: function(value) {
      return { 'location.geodata.address_components.long_name': value };
    },
    type: function(value) {
      return { type: { $in_query: { slug: value } } };
    },
    category: function(value) {
      return { categories: { $in_query: { slug: value } } };
    },
    blog_category: function(value) {
      return { blog_category: { $in_query: { key: value } } };
    },
    application: function(value) {
      return { applications: { $in_query: { uid: value } } };
    },
    research_area: function(value) {
      return { research_areas: { $in_query: { uid: value } } };
    },
    resource: function(value) {
      return { resource_category: { $in_query: { slug: value } } };
    }
  };

  let query = { $and: [] };

  Object.keys(filters).forEach(function(key) {
    if (filters[key]) {
      query.$and.push(queries[key](filters[key]));
    }
  });

  return query.$and.length > 0 ? query : null;
};
