import { GET_BLOG_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    author: [],
    content: '',
    date: '',
    hero_image: {
      url: ''
    },
    summary: '',
    title: '',
    blog_category: [{ slug: null }]
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_BLOG_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BLOG_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_BLOG_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
