import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_applyOrderCoupon = (orderToken, couponCode) =>
  fRequest(`${config.buckRootUrl}/order/${orderToken}/apply-voucher/`, {
    method: 'POST',
    data: { code: couponCode }
  });

export const api_removeOrderCoupon = orderToken =>
  fRequest(`${config.buckRootUrl}/order/${orderToken}/remove-voucher/`, {
    method: 'POST'
  });
