import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_SURVEY_STACK = actionComposer('GET_SURVEY_STACK');
export const getSurveyStack = url => ({
  type: GET_SURVEY_STACK.type,
  payload: queryContentStackByUrl('survey', url, {
    includedReferences: [
      'related_references.resource_references',
      'related_references.blog_references'
    ]
  })
});
