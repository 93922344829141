import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_EVENTS_STACK = actionComposer('GET_EVENTS_STACK');
export const getContentStackData = url => ({
  type: GET_EVENTS_STACK.type,
  payload: queryContentStackByUrl('event_detail', url, {
    includedReferences: ['topic', 'type']
  })
});
