import actionComposer from 'utilities/actionComposer';
import { queryContentStackByTypeWithCount } from 'utilities/contentStack';

export const GET_BLOG_RELATED_POSTS_STACK = actionComposer(
  'GET_BLOG_RELATED_POSTS_STACK'
);

export const getRelatedPosts = (limit, skip, query) => ({
  type: GET_BLOG_RELATED_POSTS_STACK.type,
  payload: queryContentStackByTypeWithCount('blog_pages', {
    includedReferences: ['blog_category'],
    limit: limit,
    skip: skip,
    query: query
  })
});
