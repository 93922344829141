import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_CONTENT_STACK_DATA = actionComposer('GET_CONTENT_STACK_DATA');
export const getContentStackData = () => ({
  type: GET_CONTENT_STACK_DATA.type,
  payload: queryContentStackByUrl('home', '/', {
    includedReferences: [
      'sections.quote',
      'footer_ctas.resource_reference',
      'footer_ctas.blog_reference',
      'order.reference.pricing.order_page',
      'order.reference.quotes'
    ]
  })
});
