import {
  GET_PRODUCT_STACK,
  CLEAR_PRODUCT_STACK,
  SET_FLAVOR_IDX,
  SET_LEGACY_PRODUCT
} from 'features/product/detail/actions';

export const getInitialState = () => ({
  loading: true,
  selectedFlavorIdx: 0,
  stack: {},
  legacy: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PRODUCT_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PRODUCT_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_PRODUCT_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case CLEAR_PRODUCT_STACK:
      return {
        ...state,
        stack: {},
        legacy: true
      };
    case SET_LEGACY_PRODUCT:
      return {
        ...state,
        legacy: action.legacy
      };
    case SET_FLAVOR_IDX:
      return {
        ...state,
        selectedFlavorIdx: action.selectedIdx
      };
    default:
      return state;
  }
};
