import {
  RESET_ORDER_PAYMENT,
  CREATE_STRIPE_PAYMENT,
  CREATE_PURCHASE_ORDER_PAYMENT,
  CREATE_BLANKET_PURCHASE_ORDER_PAYMENT,
  CREATE_EDIT_CREDIT_PAYMENT,
  CONFIRM_ORDER
} from './actions';

export const getInitialState = () => ({
  creditCardPayment: {},
  editCreditPayment: [],
  purchaseOrderPayment: {},
  orderConfirmed: false,
  loading: false,
  error: null
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_ORDER_PAYMENT:
      return getInitialState();
    case CREATE_STRIPE_PAYMENT.PENDING:
      return {
        ...state,
        loading: true
      };
    case CREATE_STRIPE_PAYMENT.FULFILLED:
      return {
        ...state,
        creditCardPayment: action.payload,
        loading: false
      };
    case CREATE_STRIPE_PAYMENT.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_PURCHASE_ORDER_PAYMENT.PENDING:
      return {
        ...state,
        loading: true
      };
    case CREATE_PURCHASE_ORDER_PAYMENT.FULFILLED:
      return {
        ...state,
        purchaseOrderPayment: action.payload,
        loading: false
      };
    case CREATE_PURCHASE_ORDER_PAYMENT.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_BLANKET_PURCHASE_ORDER_PAYMENT.PENDING:
      return {
        ...state,
        loading: true
      };
    case CREATE_BLANKET_PURCHASE_ORDER_PAYMENT.FULFILLED:
      return {
        ...state,
        purchaseOrderPayment: action.payload,
        loading: false
      };
    case CREATE_BLANKET_PURCHASE_ORDER_PAYMENT.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case CREATE_EDIT_CREDIT_PAYMENT.PENDING:
      return {
        ...state,
        loading: true
      };
    case CREATE_EDIT_CREDIT_PAYMENT.FULFILLED:
      return {
        ...state,
        editCreditPayment: action.payload,
        loading: false
      };
    case CREATE_EDIT_CREDIT_PAYMENT.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case CONFIRM_ORDER.PENDING:
      return {
        ...state,
        loading: true,
        error: null,
        orderConfirmed: false
      };
    case CONFIRM_ORDER.FULFILLED:
      return {
        ...state,
        loading: false,
        error: null,
        orderConfirmed: true
      };
    case CONFIRM_ORDER.REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
        orderConfirmed: false
      };

    default:
      return state;
  }
};
