import {
  REGISTRATION_UPDATE_ACCOUNT,
  REGISTRATION_END_ACTION,
  REGISTRATION_SEND_EMAIL_VERIFICATION_LINK,
  REGISTRATION_LOGOUT,
  REGISTRATION_LOGIN,
  REGISTRATION_GET_LOGIN_CONTENT_STACK_DATA,
  REGISTRATION_GET_MENU_CONTENT_STACK_DATA,
  REGISTRATION_GET_ACCOUNT_CONTENT_STACK_DATA,
  REGISTRATION_CREATE_NEW_USER,
  REGISTRATION_SET_ACTION,
  REGISTRATION_UPDATE_FORM,
  REGISTRATION_RESET_FORM,
  REGISTRATION_RESET_PASSWORD,
  REGISTRATION_SEND_PASSWORD_RESET
} from 'features/registration/actions';
import { injectSignedoutErrorCode } from 'utilities/api';

export const getInitialState = () => ({
  action: null,
  content_stack: {
    account: {},
    login: {},
    menu: {}
  },
  form: {},
  submit_response: {},
  submit_status: null
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case REGISTRATION_END_ACTION:
      return {
        ...state,
        action: null,
        submit_status: 'success',
        submit_response: {}
      };
    case REGISTRATION_SET_ACTION:
      return {
        ...state,
        action: action.action,
        submit_status: null,
        submit_response: {}
      };
    case REGISTRATION_UPDATE_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          ...action.data
        },
        submit_status: null,
        submit_response: {}
      };
    case REGISTRATION_RESET_FORM:
      return {
        ...state,
        form: {},
        submit_status: null,
        submit_response: {}
      };
    case REGISTRATION_GET_MENU_CONTENT_STACK_DATA.FULFILLED:
      return {
        ...state,
        content_stack: {
          ...state.content_stack,
          menu: action.payload
        }
      };
    case REGISTRATION_GET_LOGIN_CONTENT_STACK_DATA.FULFILLED:
      return {
        ...state,
        content_stack: {
          ...state.content_stack,
          login: action.payload
        }
      };
    case REGISTRATION_GET_ACCOUNT_CONTENT_STACK_DATA.FULFILLED:
      return {
        ...state,
        content_stack: {
          ...state.content_stack,
          account: action.payload
        }
      };
    case REGISTRATION_LOGOUT.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case REGISTRATION_LOGOUT.FULFILLED:
      return {
        ...state,
        submit_status: 'success',
        submit_response: action.payload
      };
    case REGISTRATION_LOGOUT.REJECTED:
      return {
        ...state,
        submit_status: 'error',
        submit_response: action.payload
      };
    case REGISTRATION_LOGIN.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case REGISTRATION_LOGIN.FULFILLED:
      return {
        ...state,
        submit_status: 'success',
        submit_response: action.payload
      };
    case REGISTRATION_LOGIN.REJECTED:
      return {
        ...state,
        submit_status: 'error',
        submit_response: action.payload
      };
    case REGISTRATION_RESET_PASSWORD.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case REGISTRATION_RESET_PASSWORD.FULFILLED:
      return {
        ...state,
        submit_status: 'success_acknowledge',
        submit_response: action.payload,
        action: 'password_is_reset'
      };
    case REGISTRATION_RESET_PASSWORD.REJECTED:
      return {
        ...state,
        submit_status: 'error',
        submit_response: action.payload
      };
    case REGISTRATION_SEND_EMAIL_VERIFICATION_LINK.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case REGISTRATION_SEND_EMAIL_VERIFICATION_LINK.FULFILLED:
      return {
        ...state,
        submit_status: 'success',
        submit_response: action.payload,
        action: 'verification_email_sent'
      };
    case REGISTRATION_SEND_EMAIL_VERIFICATION_LINK.REJECTED:
      return {
        ...state,
        submit_status: 'error',
        submit_response: injectSignedoutErrorCode(action.payload),
        action: 'verification_email_sent'
      };
    case REGISTRATION_SEND_PASSWORD_RESET.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case REGISTRATION_SEND_PASSWORD_RESET.FULFILLED:
      return {
        ...state,
        submit_status: 'success',
        submit_response: action.payload,
        action: 'password_reset_email_sent'
      };
    case REGISTRATION_SEND_PASSWORD_RESET.REJECTED:
      return {
        ...state,
        submit_status: 'error',
        submit_response: action.payload
      };
    case REGISTRATION_CREATE_NEW_USER.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case REGISTRATION_CREATE_NEW_USER.FULFILLED:
      return {
        ...state,
        submit_status: 'success',
        submit_response: action.payload,
        action: 'verification_email_sent'
      };
    case REGISTRATION_CREATE_NEW_USER.REJECTED:
      return {
        ...state,
        submit_status: 'error',
        submit_response: action.payload
      };
    case REGISTRATION_UPDATE_ACCOUNT.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case REGISTRATION_UPDATE_ACCOUNT.FULFILLED:
      return {
        ...state,
        submit_status: 'success',
        submit_response: action.payload,
        action: action.payload.success_code
      };
    case REGISTRATION_UPDATE_ACCOUNT.REJECTED:
      return {
        ...state,
        submit_status: 'error',
        submit_response: action.payload
      };
    default:
      return state;
  }
};
