import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_COLLECTION_DETAIL_STACK = actionComposer(
  'GET_COLLECTION_DETAIL_STACK'
);

export const getBlogCollectionDetail = url => ({
  type: GET_COLLECTION_DETAIL_STACK.type,
  payload: queryContentStackByUrl('blog_collection', url, {
    includedReferences: ['posts', 'posts.blog_category', 'posts.author']
  })
});
