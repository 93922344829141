import { GET_GUIDE_CHAPTER_STACK } from 'features/guide/chapter/actions';

export const getInitialState = () => ({
  stack: {
    summary: '',
    body: '',
    description: '',
    title: '',
    content: [],
    guide_home_reference: [
      {
        title: '',
        background_image: { url: '' },
        url: '',
        chapter_references: [{ url: '' }],
        background_gradient: {
          color1: '',
          color2: ''
        }
      }
    ]
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_GUIDE_CHAPTER_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_GUIDE_CHAPTER_STACK.FULFILLED:
      return {
        stack: action.payload,
        loading: false
      };
    case GET_GUIDE_CHAPTER_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
