import actionComposer from 'utilities/actionComposer';

import {
  api_getCellGKOCellLines,
  api_getCellGKOGenes,
  api_addCellPoolGKOToCart
} from './api';

export const ORDER_PRICE_WIDGET_SET_FLAVOR_IDX =
  'ORDER_PRICE_WIDGET_SET_FLAVOR_IDX';
export const setFlavorIdx = (idx, url) => ({
  type: ORDER_PRICE_WIDGET_SET_FLAVOR_IDX,
  selectedIdx: idx,
  url: url
});

export const ORDER_WIDGET_ADD_CELL_POOL_TO_CART = actionComposer(
  'ORDER_WIDGET_ADD_CELL_POOL_TO_CART'
);
export const addCellPoolToCart = (cellLine, gene, product_url) => ({
  type: ORDER_WIDGET_ADD_CELL_POOL_TO_CART.type,
  payload: api_addCellPoolGKOToCart(cellLine, gene, product_url),
  meta: { url: product_url }
});

export const ORDER_WIDGET_GET_CELLGKO_CELL_LINES = actionComposer(
  'ORDER_WIDGET_GET_CELLGKO_CELL_LINES'
);
export const getCellGKOCellLines = product_url => ({
  type: ORDER_WIDGET_GET_CELLGKO_CELL_LINES.type,
  payload: api_getCellGKOCellLines(),
  meta: { url: product_url }
});

export const ORDER_WIDGET_GET_CELLGKO_GENES = actionComposer(
  'ORDER_WIDGET_GET_CELLGKO_GENES'
);
export const getCellGKOGenes = product_url => ({
  type: ORDER_WIDGET_GET_CELLGKO_GENES.type,
  payload: api_getCellGKOGenes(),
  meta: { url: product_url }
});

export const ORDER_WIDGET_SET_CELLGKO_LINE = 'ORDER_WIDGET_SET_CELLGKO_LINE';
export const setCellGKOLine = (cellLine, product_url) => ({
  type: ORDER_WIDGET_SET_CELLGKO_LINE,
  cellLine: cellLine,
  url: product_url
});

export const ORDER_WIDGET_SET_CELLGKO_GENE = 'ORDER_WIDGET_SET_CELLGKO_GENE';
export const setCellGKOGene = (gene, product_url) => ({
  type: ORDER_WIDGET_SET_CELLGKO_GENE,
  gene: gene,
  url: product_url
});
