import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';

export const GET_DISTRIBUTOR_STACK = actionComposer('GET_DISTRIBUTOR_STACK');
export const getDistributorContentStackData = country_code => ({
  type: GET_DISTRIBUTOR_STACK.type,
  payload: queryContentStackByType('distributor', {
    query: { country_code: country_code }
  })
});

export const GET_DISTRIBUTOR_MODAL_STACK = actionComposer(
  'GET_DISTRIBUTOR_MODAL_STACK'
);
export const getDistributorModalStack = country_code => ({
  type: GET_DISTRIBUTOR_MODAL_STACK.type,
  payload: queryContentStackByType('distributor', {
    query: { country_code: country_code }
  })
});
