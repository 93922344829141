import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_GetProductPricing = () =>
  fRequest(`${config.buckRootUrl}/api/products/options`, {
    method: 'GET'
  });

export const api_GetSKUPricing = query =>
  fRequest(`${config.buckRootUrl}/products/sku-lookup/`, {
    method: 'POST',
    data: [query]
  });
