import { GET_COMPANY_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    header: {
      background_image: {
        url: ''
      },
      background_video: {
        video_files: []
      },
      bold_heading: '',
      heading: '',
      subheadings: [],
      ctas: []
    },
    overview: {
      title: '',
      body: ''
    },
    quotes: [],
    benefits: {
      title: '',
      vision: '',
      team: ''
    }
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_COMPANY_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_COMPANY_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        loading: false
      };
    case GET_COMPANY_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
