import {
  GET_TOP_NAV_STACK_DATA,
  SET_TOP_NAV_COLOR,
  SET_TOP_NAV_MINIMAL,
  SET_TOP_NAV_HIDDEN
} from 'features/navigation/header/actions';

export const getInitialState = () => ({
  color: 'primary',
  minimal: false,
  hidden: false,
  stack: {},
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_TOP_NAV_STACK_DATA.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_TOP_NAV_STACK_DATA.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_TOP_NAV_STACK_DATA.REJECTED:
      return {
        ...state,
        loading: false
      };
    case SET_TOP_NAV_COLOR:
      return {
        ...state,
        color: action.color
      };
    case SET_TOP_NAV_MINIMAL:
      return {
        ...state,
        minimal: action.minimal
      };
    case SET_TOP_NAV_HIDDEN:
      return {
        ...state,
        hidden: action.hidden
      };

    default:
      return state;
  }
};
