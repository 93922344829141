import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_HELP_DETAIL_STACK = actionComposer('GET_HELP_DETAIL_STACK');
export const getHelpDetailStack = url => ({
  type: GET_HELP_DETAIL_STACK.type,
  payload: queryContentStackByUrl('help_article', url, {
    includedReferences: [
      'categories',
      'related_help_articles',
      'other_related_content',
      'author',
      'tables.table',
      'categories_tags.product',
      'categories_tags.product.family'
    ]
  })
});
