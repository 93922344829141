export const orderRequirements = {
  minPlateWells: 24
};

export const genomeOptions = [
  {
    label: 'Homo sapiens',
    commonName: 'human',
    slug: 'homo_sapiens'
  },
  {
    label: 'Mus musculus',
    commonName: 'mouse',
    slug: 'mus_musculus'
  },
  {
    label: 'Other',
    commonName: 'other',
    slug: 'other'
  }
];

//These are just used to populate new plates and tubes, available quanities are fetched from API
export const defaultYieldOptions = {
  sgrna_kit: {
    plates: { display: '1.5 nmol', id: '1.5nmol' },
    tubes: { display: '1.5 nmol', id: '1.5nmol' }
  },
  custom_rna: {
    plates: { display: '5 nmol', id: '5nmol' },
    tubes: { display: '5 nmol', id: '5nmol' }
  }
};

export const defaultNucleaseOptions = {
  sgrna_kit: 'SpCas9',
  custom_rna: 'Other'
};

export const formatOptions = {
  sgrna_kit: [
    {
      label: 'Tubes',
      caption: 'Includes tubes of Tris-EDTA Buffer\n and Nuclease-free Water',
      shortCaption: 'sgRNA Kits',
      slug: 'tubes'
    },
    {
      label: 'Plates',
      caption: '96 Well Plates,\n Minimum of 24 filled wells per plate',
      shortCaption: '96 Well',
      slug: 'plates'
    }
  ],
  custom_rna: [
    {
      label: 'Tubes',
      caption: '',
      shortCaption: 'sgRNA Kits',
      slug: 'tubes'
    },
    {
      label: 'Plates',
      caption: '96 Well Plates,\n Minimum of 24 filled wells per plate',
      shortCaption: '96 Well',
      slug: 'plates'
    }
  ]
};

export const plateOptions = [
  {
    label: '96-well',
    caption: 'Polypropylene',
    slug: '1mL deep well',
    wellCount: 96,
    wellRows: 8,
    wellColumns: 12
  }
];

export const sequenceHelpText = {
  sgrna_kit: {
    SpCas9: {
      text:
        "SpCas9 sgRNA Target Sequence can be between 17-35 bases in length. Enter in 5' to 3' orientation.",
      tooltip:
        "We will automatically add an 80-mer SpCas9 scaffold to create a single guide RNA. For 5' modifications, internal modifications, 3' modifications, or mixed bases please contact us."
    },
    hfCas12Max: {
      text:
        "hfCas12Max sgRNA Target sequence is 17-23 bases in length. Enter in 5' to 3' orientation.",
      tooltip:
        'We will automatically add a 23-mer hfCas12Max scaffold to create a single guide RNA. It will be prepended to the beginning of your target sequence.'
    }
  },
  custom_rna: {
    Other: {
      text: "20-115 nucleotide genome targeting sequence in 5' to 3' order",
      tooltip:
        "Enter the desired RNA sequence 5' -> 3'. <br> For 5' modifications, internal modifications, 3' modifications, or mixed bases please contact us."
    }
  }
};

export const warningColor = '#ffca28';

export const ERRORS = {
  SEQUENCE_INVALID: 'SEQUENCE_INVALID',
  NAME_INVALID: 'NAME_INVALID',
  NAME_NON_UNIQUE: 'NAME_NON_UNIQUE',
  NAME_EXISTS_IN_CART: 'NAME_EXISTS_IN_CART'
};

export const hfCas12MaxBlockedCountries = ['CN'];
