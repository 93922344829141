import actionComposer from 'utilities/actionComposer';
import { queryContentStackById } from 'utilities/contentStack';

export const GET_COMPANY_FOOTER_STACK = actionComposer(
  'GET_COMPANY_FOOTER_STACK'
);
export const getCompanyFooterStack = () => ({
  type: GET_COMPANY_FOOTER_STACK.type,
  payload: queryContentStackById('company_footer', 'blt7f821fc099f71126', {
    includedReferences: [
      'in_the_news.resource_reference',
      'in_the_news.blog_reference',
      'in_the_news.press_reference'
    ]
  })
});
