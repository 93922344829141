import { GET_EVENTS_THANKS_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    title: '',
    body: '',
    hubspot_forms: []
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_EVENTS_THANKS_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload
      };
    default:
      return state;
  }
};
