import _ from 'lodash';
import dayjs from 'dayjs';
import shortid from 'shortid';
import {
  orderRequirements,
  staticControlPools,
  defaultPool,
  ERRORS,
  WARNINGS
} from './constants';

// Get pricing
export const getPricing = chargeablePoolCount => {
  const totalPoolCount = chargeablePoolCount;
  const listPricePerPool = 700;
  let total = 'N/A';
  let subTotal = 'N/A';
  let discount = 'N/A';
  let pricePerPool;
  if (totalPoolCount <= 89) {
    pricePerPool = listPricePerPool;
  } else if (totalPoolCount <= 179) {
    pricePerPool = 650;
  } else {
    pricePerPool = 600;
  }
  if (pricePerPool) {
    subTotal = chargeablePoolCount * pricePerPool;
    discount = Math.round(
      ((listPricePerPool - pricePerPool) / listPricePerPool) * 100
    );
    total = subTotal;
  }
  return { listPricePerPool, pricePerPool, total, discount };
};

//Chunk all project pools into an array of racks
export const selectProjectRacks = project => {
  return arrayChunkByRack(project.pools, project);
};

//Chunk filled project pools into an array of racks
export const selectFilledProjectRacks = project =>
  arrayChunkByRack(selectFilledProjectPools(project), project);

//Select all filled pools on the project
export const selectFilledProjectPools = project =>
  _.filter(project.pools, pool => !_.isEmpty(pool?.value));

//Get count of all controls including default controls
export const getTotalControlCount = project =>
  project.controls.ntc1 +
  project.controls.ntc2 +
  project.controls.mockWT +
  staticControlPools[project.genome.slug].length;

//Get count of only user controls excluding default controls
export const getUserControlCount = project =>
  project.controls.ntc1 -
  orderRequirements.minControls['ntc1'] +
  project.controls.ntc2 -
  orderRequirements.minControls['ntc2'] +
  project.controls.mockWT -
  orderRequirements.minControls['mockWT'];

//Get count of all user filled pools on the project
export const getUserProjectPoolsCount = project =>
  selectFilledProjectPools(project).length +
  getUserControlCount(project) * selectProjectRacks(project).length;

//Get count of pools the user will actually be charged for - only the first rack of controls
export const getUserChargeablePoolsCount = project =>
  (selectFilledProjectPools(project).length + getUserControlCount(project)) *
  project.replicates;

//Select pools that are filled on a rack
export const selectFilledRackPools = rack =>
  _.filter(rack, pool => !_.isEmpty(pool?.value));

//Select all pools on a rack that have any warnings
export const selectWarningRackPools = rack =>
  _.filter(rack, pool => pool.warnings?.length);

//Select all pools on a rack that have any errors
export const selectErrorRackPools = rack =>
  _.filter(rack, pool => pool.errors?.length);

//Select all pools on a rack that have a GENE_NOT_FOUND error
export const selectGeneNotFoundRackPools = rack =>
  _.filter(rack, pool => pool.errors?.includes(ERRORS.GENE_NOT_FOUND));

//Select all pools on a rack that have a ZERO_GUIDES error
export const selectZeroGuidesRackPools = rack =>
  _.filter(rack, pool => pool.errors?.includes(ERRORS.ZERO_GUIDES));

//Select all pools on a rack with a FEWER_THAN_THREE_GUIDES warning
export const selectFewerThanThreeGuidesRackPools = rack =>
  _.filter(rack, pool =>
    pool.warnings?.includes(WARNINGS.FEWER_THAN_THREE_GUIDES)
  );

//Select all pools on a rack with a ESSENTIAL_GENE warning
export const selectEssentialGeneRackPools = rack =>
  _.filter(rack, pool => pool.warnings?.includes(WARNINGS.ESSENTIAL_GENE));

//Select all pools on a rack with a PARALOGS_FOUND warning
export const selectParalogsFoundRackPools = rack =>
  _.filter(rack, pool => pool.warnings?.includes(WARNINGS.PARALOGS_FOUND));

//Select all pools with warnings on the project keeping them grouped by rack
export const selectWarningProjectPools = project =>
  _.map(selectProjectRacks(project), rack => selectWarningRackPools(rack));

//Select all invalid pools on the project keeping them grouped by rack
export const selectErrorProjectPools = project =>
  _.map(selectProjectRacks(project), rack => selectErrorRackPools(rack));

//Select the indexes and pool objects of all invalid pools on the project
export const selectGeneNotFoundPoolRacks = project =>
  _.reduce(
    _.cloneDeep(selectProjectRacks(project)),
    (result, rack, idx) => {
      //Store the index of the invalid pool so we can show it's location to the user
      rack = rack.map((w, idx) => ({ ...w, index: idx }));
      const invalidPools = selectGeneNotFoundRackPools(rack);
      if (invalidPools.length) {
        result.push({
          rackIdx: idx,
          pools: invalidPools
        });
      }
      return result;
    },
    []
  );

//Select the indexes and pool objects of all pools that have zero guides
export const selectZeroGuidesPoolRacks = project =>
  _.reduce(
    _.cloneDeep(selectProjectRacks(project)),
    (result, rack, idx) => {
      //Store the index of the invalid pool so we can show it's location to the user
      rack = rack.map((w, idx) => ({ ...w, index: idx }));
      const invalidPools = selectZeroGuidesRackPools(rack);
      if (invalidPools.length) {
        result.push({
          rackIdx: idx,
          pools: invalidPools
        });
      }
      return result;
    },
    []
  );

//Select the indexes and pool objects of all pools that more than zero but fewer than three guides
export const selectFewerThanThreeGuidesPoolRacks = project =>
  _.reduce(
    _.cloneDeep(selectProjectRacks(project)),
    (result, rack, idx) => {
      //Store the index of the invalid pool so we can show it's location to the user
      rack = rack.map((w, idx) => ({ ...w, index: idx }));
      const invalidPools = selectFewerThanThreeGuidesRackPools(rack);
      if (invalidPools.length) {
        result.push({
          rackIdx: idx,
          pools: invalidPools
        });
      }
      return result;
    },
    []
  );

//Select the indexes and pool objects of all pools that have essential genes
export const selectEssentialGenePoolRacks = project =>
  _.reduce(
    _.cloneDeep(selectProjectRacks(project)),
    (result, rack, idx) => {
      //Store the index of the invalid pool so we can show it's location to the user
      rack = rack.map((w, idx) => ({ ...w, index: idx }));
      const invalidPools = selectEssentialGeneRackPools(rack);
      if (invalidPools.length) {
        result.push({
          rackIdx: idx,
          pools: invalidPools
        });
      }
      return result;
    },
    []
  );

//Select the indexes and pool objects of all pools that have paralogs
export const selectParalogsFoundPoolRacks = project =>
  _.reduce(
    _.cloneDeep(selectProjectRacks(project)),
    (result, rack, idx) => {
      //Store the index of the invalid pool so we can show it's location to the user
      rack = rack.map((w, idx) => ({ ...w, index: idx }));
      const invalidPools = selectParalogsFoundRackPools(rack);
      if (invalidPools.length) {
        result.push({
          rackIdx: idx,
          pools: invalidPools
        });
      }
      return result;
    },
    []
  );

//Given a flat array of genes, chunk it into muliple arrays each of the correct rack size (excluding all controls)
export const arrayChunkByRack = (array, project) => {
  return _.chunk(
    array,
    project.rackType.poolCount - getTotalControlCount(project)
  );
};

//Given a pool index, calculate it's rack position
export const poolIndexToRackIndex = (index, project) =>
  Math.floor(
    index / (project.rackType.poolCount - getTotalControlCount(project))
  );

//Given an array index, calulate it's pool position
export const indexToPoolLocation = (index, project) => {
  const paddedIndex =
    index -
    (project.rackType.poolCount - getTotalControlCount(project)) *
      poolIndexToRackIndex(index, project);

  const row = String.fromCharCode(
    65 + Math.floor(paddedIndex / project.rackType.poolColumns)
  );
  const column =
    paddedIndex -
    project.rackType.poolColumns *
      Math.floor(paddedIndex / project.rackType.poolColumns) +
    1;
  return `${row}${column}`;
};

//Format a gene symbol's capitalization based on genome
export const formatGeneSymbol = (symbol, genome) => {
  //If human uppercase all
  if (genome === 'human') {
    return symbol.toUpperCase();
    //If mouse capitalize only if symbol doesn't start with number or is 'a' (a valid mouse gene symbol)
  } else if (
    genome === 'mouse' &&
    !isFinite(symbol.charAt(0)) &&
    symbol !== 'a'
  ) {
    return _.capitalize(symbol);
  } else {
    return symbol;
  }
};

export const getGeneWarnings = gene => {
  const { num_guides, common_essential, gene_off_targets } = gene;
  let warnings = [];
  if (num_guides > 0 && num_guides < 3) {
    warnings.push(WARNINGS.FEWER_THAN_THREE_GUIDES);
  }
  if (common_essential) {
    warnings.push(WARNINGS.ESSENTIAL_GENE);
  }
  if (gene_off_targets.length > 0) {
    warnings.push(WARNINGS.PARALOGS_FOUND);
  }
  return warnings;
};

export const getGeneErrors = gene => {
  const { num_guides } = gene;
  let errors = [];
  if (num_guides === 0) {
    errors.push(ERRORS.ZERO_GUIDES);
  }
  return errors;
};

//Convert an array of gene symbols to an array of pool objects, validating the genes
export const geneArrayToPools = async (genes, validGenes, project) => {
  const poolsArray = genes.map(gene => {
    let pool = { ...defaultPool };

    //Check the valid gene list to see if the gene is valid or not
    const formattedGene = formatGeneSymbol(gene, project.genome.commonName);

    pool.value = formattedGene;
    pool.id = `pool-${shortid.generate()}`;
    pool.type = 'gene';

    if (!(formattedGene in validGenes)) {
      pool.errors = [ERRORS.GENE_NOT_FOUND];
    } else {
      pool.type = 'gene';
      pool.ensemblId = validGenes[formattedGene].ensembl_id;
      pool.numGuides = validGenes[formattedGene].num_guides;
      pool.geneOffTargets = validGenes[formattedGene].gene_off_targets;
      pool.commonEssential = validGenes[formattedGene].common_essential;
      pool.errors = [...getGeneErrors(validGenes[formattedGene])];
      pool.warnings = [...getGeneWarnings(validGenes[formattedGene])];
    }
    return pool;
  });

  return poolsArray;
};

//Given an array of genes, preview how they will fill the racks on the project
export const geneArrayToRackPreview = (geneArray, project) => {
  const totalControlCount = getTotalControlCount(project);
  const racks = selectFilledProjectRacks(project);
  const lastRackAvailableCount =
    project.rackType.poolCount - _.last(racks)?.length - totalControlCount;
  let rackPreview = [];
  if (geneArray.length) {
    if (lastRackAvailableCount > 0) {
      rackPreview = [
        [..._.take(geneArray, lastRackAvailableCount)],
        ...arrayChunkByRack(_.drop(geneArray, lastRackAvailableCount), project)
      ];
    } else {
      rackPreview = arrayChunkByRack(geneArray, project);
    }
    rackPreview = rackPreview.map((rack, idx) => {
      return {
        number:
          racks.length +
          idx +
          (lastRackAvailableCount <= 0 || racks.length === 0 ? 1 : 0),
        geneCount: rack.length
      };
    });
  }
  return rackPreview;
};

//Convert a list of gene symbols separated by commas or line breaks into an array of geme symbols
export const geneListToArray = geneList => {
  const geneArray = geneList.length
    ? geneList.replace(/\r?\n/g, ',').split(',')
    : [];
  const trimmedGeneArray = geneArray
    .filter(gene => gene)
    .map(g => g.trimStart().trimEnd());
  return trimmedGeneArray;
};

//Given a csv or xlsx as text, see if it's a valid template (the only requirement is it's first cell is 'Gene')
export const isValidTemplate = geneText => {
  let rows = geneText.split('\n');
  if (rows.length) {
    rows = rows.map(cell => cell.replace(/['"\r]+/g, '').split(','));
    if (rows[0][0] === 'Gene') {
      return true;
    }
  } else {
    return false;
  }
};

//Convert a vertical list csv template
export const geneCsvToArray = geneText => {
  let geneArray;
  let rows = geneText.split('\n');
  rows.shift(); //Remove header
  geneArray = rows.map(row => {
    return row.split(',')?.[0].replace(/['"\r]+/g, '');
  });
  return geneArray.filter(Boolean);
};

//Convert an entire project into an .xlsx workbook in a vertical list layout
export const downloadVerticalProject = async project => {
  const XLSX = await import('xlsx');
  const { pools, name } = project;
  let workbook = XLSX.utils.book_new();

  //Add some extra useful information to the download
  const sheetArray = [
    [
      'Gene',
      'Rack',
      'Pool Position',
      'Number of Guides',
      'Essential',
      'Paralogs',
      'Valid'
    ]
  ];
  pools.forEach((pool, poolIdx) => {
    if (pool.value) {
      sheetArray.push([
        pool.value,
        poolIndexToRackIndex(poolIdx, project) + 1,
        indexToPoolLocation(poolIdx, project),
        pool.numGuides,
        pool.commonEssential ? 'Yes' : 'No',
        pool.geneOffTargets?.map(ot => ot.gene_symbol).join(' ,'),
        pool.errors.length ? 'No' : 'Yes'
      ]);
    }
  });
  let sheet = XLSX.utils.aoa_to_sheet(sheetArray);
  XLSX.utils.book_append_sheet(workbook, sheet);

  XLSX.writeFile(
    workbook,
    `${name}-synthego-pool-list-${dayjs().format(`YYYY-MM-DDTHHmmss`)}.xlsx`
  );
};
