import React from 'react';
import App from 'views/Layout/App';
import { lightTheme } from 'theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from 'components/ScrollToTop';

class Layout extends React.PureComponent {
  render() {
    return (
      <MuiThemeProvider theme={lightTheme}>
        <CssBaseline />
        <ScrollToTop />
        <App />
      </MuiThemeProvider>
    );
  }
}

export default Layout;
