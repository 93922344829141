import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByUrl,
  queryContentStackByType
} from 'utilities/contentStack';

export const GET_PODCAST_STACK = actionComposer('GET_PODCAST_STACK');
export const getPodcastDetailStack = url => ({
  type: GET_PODCAST_STACK.type,
  payload: queryContentStackByUrl('podcast_episode', url, {
    includedReferences: [
      'footer_cta',
      'related_content',
      'related_content.categories_tags.product',
      'related_content.categories_tags.application',
      'related_content.categories_tags.research_area',
      'related_content.blog_category',
      'blog_transcript.resource_cta.resource',
      'blog_transcript.resource_cta_inline.resource',
      'blog_transcript.sections.cta.cta_reference',
      'blog_transcript.sections.resource.resource_reference',
      'blog_transcript.sections.blog.blog_reference',
      'blog_transcript.sections.podcast.podcast_reference',
      'blog_transcript.sections.section.content.table.table_reference',
      'blog_transcript.sections.section.content.figure.figure_reference',
      'blog_transcript.sections.section.content.figure_gallery.figure_references',
      'blog_transcript.sections.section.content.quote.quote_reference',
      'blog_transcript.sections.section.content.publication.publication_reference',
      'blog_transcript.sections.section.content.publication.publication_reference.categories_tags.product',
      'blog_transcript.sections.section.content.publication.publication_reference.categories_tags.application',
      'blog_transcript.sections.section.content.publication.publication_reference.categories_tags.research_area',
      'transcript.sections.cta.cta_reference',
      'transcript.sections.resource.resource_reference',
      'transcript.sections.blog.blog_reference',
      'transcript.sections.section.content.table.table_reference',
      'transcript.sections.section.content.figure.figure_reference',
      'transcript.sections.section.content.figure_gallery.figure_references',
      'transcript.sections.section.content.quote.quote_reference',
      'transcript.sections.section.content.publication.publication_reference',
      'transcript.sections.section.content.publication.publication_reference.categories_tags.product',
      'transcript.sections.section.content.publication.publication_reference.categories_tags.application',
      'transcript.sections.section.content.publication.publication_reference.categories_tags.research_area',
      'categories_tags.application',
      'categories_tags.research_area',
      'categories_tags.editing_method',
      'categories_tags.paper_type',
      'categories_tags.product',
      'categories_tags.product.family',
      'categories_tags.model_organism',
      'categories_tags.customer_affiliation'
    ]
  })
});

export const GET_ADJACENT_PODCAST_EPISODES = actionComposer(
  'GET_ADJACENT_PODCAST_EPISODES'
);

export const getAdjacentEpisodes = currentEpisode => ({
  type: GET_ADJACENT_PODCAST_EPISODES.type,
  payload: queryContentStackByType('podcast_episode', {
    query: {
      $or: [
        { episode_number: currentEpisode - 1 },
        { episode_number: currentEpisode + 1 }
      ]
    },
    ascending: 'episode_number'
  })
});
