import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_getCurrentBuckUserCart = () =>
  fRequest(`${config.buckRootUrl}/api/user/cart/`, {
    method: 'GET'
  });

export const api_setActiveBuckUserCart = orderToken =>
  fRequest(`${config.buckRootUrl}/api/user/cart/set-active/`, {
    method: 'POST',
    data: { token: orderToken }
  });

export const api_clearCart = orderId =>
  fRequest(`${config.buckRootUrl}/api/user/cart/clear/${orderId}/`, {
    method: 'DELETE'
  });

export const api_getEditItem = (itemIdentifier, productConfig) =>
  fRequest(`${config.buckRootUrl}/api/order/orderitem/${itemIdentifier}/`, {
    method: 'GET'
  });

export const api_removeItem = orderedItemId =>
  fRequest(`${config.buckRootUrl}/api/user/cart/remove/${orderedItemId}/`, {
    method: 'DELETE'
  });

export const api_updateItemQuantity = (orderedItemId, quantity) =>
  fRequest(
    `${config.buckRootUrl}/api/user/cart/update-item-quantity/${orderedItemId}/`,
    {
      method: 'PATCH',
      data: { quantity: quantity }
    }
  );
