import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByType,
  queryContentStackByTypeWithCount
} from 'utilities/contentStack';
import { POSTS_PER_PAGE } from 'features/blog/constants';
import httpErrors from 'constants/HttpError';
import { redirect404 } from 'features/util/actions';
import _ from 'lodash';

export const GET_BLOG_POSTS = actionComposer('GET_BLOG_POSTS');
export const LOAD_MORE_BLOG_POSTS = actionComposer('LOAD_MORE_BLOG_POSTS');

export const getPosts = (options = {}) => ({
  type: options.append ? LOAD_MORE_BLOG_POSTS.type : GET_BLOG_POSTS.type,
  payload: queryContentStackByTypeWithCount('blog_pages', {
    includedReferences: ['blog_category'],
    limit: options.limit || POSTS_PER_PAGE,
    skip: options.skip || 0,
    query: options.filter || options.query,
    descending: 'date'
  })
});

export const GET_RECENT_BLOG_POSTS = actionComposer('GET_RECENT_BLOG_POSTS');

export const getRecentPosts = (options = {}) => ({
  type: GET_RECENT_BLOG_POSTS.type,
  payload: queryContentStackByTypeWithCount('blog_pages', {
    includedReferences: ['blog_category'],
    limit: 4,
    skip: 0,
    descending: 'date_updated'
  })
});

export const getPostsByParams = (options = {}, params) => dispatch => {
  const slugTypeMappings = {
    'product-family': 'product_family',
    product: 'product',
    'paper-type': 'paper_type',
    'editing-method': 'editing_method',
    'model-organism': 'model_organism',
    application: 'application_category',
    'research-area': 'research_area'
  };

  if (params.categorySlug) {
    queryContentStackByType('blog_categories', {
      query: { slug: params.categorySlug }
    }).then(categories => {
      if (categories.length > 0) {
        dispatch({
          type: options.append
            ? LOAD_MORE_BLOG_POSTS.type
            : GET_BLOG_POSTS.type,
          payload: queryContentStackByTypeWithCount('blog_pages', {
            includedReferences: ['blog_category'],
            limit: options.limit || POSTS_PER_PAGE,
            skip: options.skip || 0,
            query: {
              blog_category: { $in_query: { slug: categories[0].slug } }
            },
            descending: 'date'
          })
        });
        dispatch(setActiveCategoryTag(categories[0]));
      } else {
        dispatch(redirect404(httpErrors.HTTP404.redirectUrl));
      }
    });
  } else if (
    params.tagType &&
    params.tagType in slugTypeMappings &&
    params.tagSlug
  ) {
    queryContentStackByType(slugTypeMappings[params.tagType], {
      query: {
        slug: params.tagSlug
      }
    }).then(tags => {
      if (tags.length > 0) {
        dispatch({
          type: options.append
            ? LOAD_MORE_BLOG_POSTS.type
            : GET_BLOG_POSTS.type,
          payload: queryContentStackByTypeWithCount('blog_pages', {
            includedReferences: ['blog_category'],
            limit: options.limit || POSTS_PER_PAGE,
            skip: options.skip || 0,
            query: {
              [`categories_tags.${_.snakeCase(params.tagType)}`]: {
                $in_query: { slug: tags[0].slug },
                _content_type_uid: slugTypeMappings[params.tagType]
              }
            },
            descending: 'date'
          })
        });
        dispatch(setActiveCategoryTag(tags[0]));
      } else {
        dispatch(redirect404(httpErrors.HTTP404.redirectUrl));
      }
    });
  } else {
    dispatch(redirect404(httpErrors.HTTP404.redirectUrl));
  }
};

export const SET_ACTIVE_CATEGORY_TAG = 'SET_ACTIVE_CATEGORY_TAG';
export const setActiveCategoryTag = catTag => ({
  type: SET_ACTIVE_CATEGORY_TAG,
  payload: catTag
});

export const RESET_BLOG_POSTS = 'RESET_BLOG_POSTS';
export const resetPosts = () => ({
  type: RESET_BLOG_POSTS
});
