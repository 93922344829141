// Genome options, plate options and control labels get stored in the users localstorage once they start a project.
// If you change any of their properties increase the version, users will be prompted
// to download their project and start over so they don't have outdated information stored.
export const version = 23;

export const orderRequirements = {
  maxProjectWells: 500,
  minPlateWells: 20,
  minProjectCopies: 1,
  maxProjectCopies: 5
};

export const genomeOptions = [
  {
    label: 'Homo sapiens',
    caption: 'Gencode Release 26 (GRCh38.p10)',
    commonName: 'human',
    slug: 'homo_sapiens'
  },
  {
    label: 'Mus musculus',
    caption: 'Gencode Release M21 (GRCm38.p6)',
    commonName: 'mouse',
    slug: 'mus_musculus'
  }
];

export const plateOptions = [
  {
    label: '96-well',
    caption: 'Polypropylene',
    slug: '1mL deep well',
    wellCount: 96,
    wellRows: 8,
    wellColumns: 12,
    quantities: [500, 1500, 3000, 5000, 10000], //Well sizes in pmol this plate is available to order in
    genomes: ['homo_sapiens'] //The genomes this plate and quantities is available for
  },
  {
    label: '96-well',
    caption: 'Polypropylene',
    slug: '1mL deep well',
    wellCount: 96,
    wellRows: 8,
    wellColumns: 12,
    quantities: [1500],
    genomes: ['mus_musculus']
  },
  {
    label: '384-well',
    caption: 'Polypropylene',
    slug: '384-well V-bottom plate',
    wellCount: 384,
    wellRows: 16,
    wellColumns: 24,
    quantities: [150, 250, 300, 600],
    genomes: ['homo_sapiens']
  },
  {
    label: '384-well',
    caption: 'Echo-Validated',
    slug: '384-well Echo plate',
    wellCount: 384,
    wellRows: 16,
    wellColumns: 24,
    quantities: [150, 250, 300, 600],
    genomes: ['homo_sapiens']
  },
  {
    label: '384-well',
    caption: 'Echo-LDV',
    slug: '384-well Echo LDV plate',
    wellCount: 384,
    wellRows: 16,
    wellColumns: 24,
    quantities: [150, 250, 300],
    genomes: ['homo_sapiens']
  }
];

export const controlOptions = [
  {
    label: 'RELA Positive',
    validatedName: 'RELA',
    caption: 'Positive Control sgRNA (mod), Human RELA',
    slug: 'rela_positive',
    type: 'Single-guide',
    genomes: ['homo_sapiens'], //The genomes this control is available to use on
    synonyms: ['RELA CONTROL']
  },
  {
    label: 'CDC42BPB Positive',
    validatedName: 'CDC42BPB',
    caption: 'Positive Control sgRNA (mod), Human CDC42BPB',
    slug: 'cdcr2bpb',
    type: 'Single-guide',
    genomes: ['homo_sapiens'],
    synonyms: ['CDCR2BPB', 'CDC42BPB CONTROL']
  },
  {
    label: 'NTC1',
    validatedName: 'NTC1',
    caption: 'Negative Control sgRNA (mod) #1',
    slug: 'ntc1',
    type: 'Single-guide',
    genomes: ['homo_sapiens', 'mus_musculus'],
    synonyms: ['NTC1 CONTROL']
  },
  {
    label: 'NTC2',
    validatedName: 'NTC2',
    caption: 'Negative Control sgRNA (mod) #2',
    slug: 'ntc2',
    type: 'Single-guide',
    genomes: ['homo_sapiens', 'mus_musculus'],
    synonyms: ['NTC2 CONTROL']
  },
  {
    label: 'Rosa26',
    validatedName: 'ROSA',
    caption: 'Positive Control, Mouse Rosa26, mod-sgRNA',
    slug: 'rosa26',
    type: 'Single-guide',
    genomes: ['mus_musculus'],
    synonyms: ['Rosa26 CONTROL']
  },
  {
    label: 'TRAC Positive',
    validatedName: 'TRAC',
    caption: 'Positive Control sgRNA (mod), Human TRAC',
    slug: 'trac',
    type: 'Multi-guide',
    genomes: ['homo_sapiens'],
    synonyms: ['TRAC CONTROL']
  },
  {
    label: 'PTC1',
    validatedName: 'PTC1',
    caption: 'Positive Control sgRNA (mod), Human AAVS1 #2',
    slug: 'ptc1',
    type: 'sgRNA Pool',
    genomes: ['homo_sapiens'],
    synonyms: ['PTC1 CONTROL'],
    helpText:
      'Sequences:\nACCCCACAGUGGGGCCACUA\nGGGACCACCUUAUAUUCCCA\nUGUCCCUAGUGGCCCCACUG'
  },
  {
    label: 'PTC2',
    validatedName: 'PTC2',
    caption: 'Positive Control sgRNA (mod), Human AAVS1 #3',
    slug: 'ptc2',
    type: 'sgRNA Pool',
    genomes: ['homo_sapiens'],
    synonyms: ['PTC2 CONTROL'],
    helpText:
      'Sequences:\nAAACCUUAGAGGUUCUGGCA\nGGGACCACCUUAUAUUCCCA\nUAAGGAAUCUGCCUAACAGG'
  }
];

export const warningColors = {
  major: '#ff9c28',
  minor: '#ffca28'
};

export const ERRORS = {
  GENE_NOT_FOUND: 'GENE_NOT_FOUND',
  ZERO_GUIDES: 'ZERO_GUIDES'
};

export const WARNINGS = {
  FEWER_THAN_THREE_GUIDES: 'FEWER_THAN_THREE_GUIDES',
  ESSENTIAL_GENE: 'ESSENTIAL_GENE'
};
