import config from 'init/config';
import { fRequest } from 'utilities/api';
import { formatProjectForCart } from './utilities';

export const api_AddToBuckCart = sgrnaProject => {
  return fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: formatProjectForCart(sgrnaProject)
  });
};

export const api_GetOrderedItem = id => {
  return fRequest(`${config.buckRootUrl}/api/order/ordereditem/${id}/`, {
    method: 'GET'
  });
};

export const api_UpdateOrderedItem = (id, sgrnaProject) => {
  return fRequest(`${config.buckRootUrl}/api/order/ordereditem/${id}/`, {
    method: 'PUT',
    data: { data: formatProjectForCart(sgrnaProject)?.[0] }
  });
};
