import actionComposer from 'utilities/actionComposer';
import {
  api_getCurrentBuckUser,
  api_getCurrentBuckUserOrdersList,
  api_getCurrentBuckUserEditCredit,
  api_getBuckUserEditCreditTransactions,
  api_getCurrentBuckUserShippingAddresses,
  api_getCurrentBuckUserBillingAddresses,
  api_getCurrentBuckUserLastAddress
} from './api';

export const UPDATE_BUCK_USER = actionComposer('UPDATE_BUCK_USER');
export const updateBuckUser = () => ({
  type: UPDATE_BUCK_USER.type,
  payload: api_getCurrentBuckUser()
});

export const GET_BUCK_USER_ORDERS = actionComposer('GET_BUCK_USER_ORDERS');
export const getBuckUserOrders = () => ({
  type: GET_BUCK_USER_ORDERS.type,
  payload: api_getCurrentBuckUserOrdersList()
});

export const GET_BUCK_USER_EDIT_CREDIT = actionComposer(
  'GET_BUCK_USER_EDIT_CREDIT'
);
export const getBuckUserEditCredit = () => ({
  type: GET_BUCK_USER_EDIT_CREDIT.type,
  payload: api_getCurrentBuckUserEditCredit()
});

export const GET_BUCK_USER_EDIT_CREDIT_TRANSACTIONS = actionComposer(
  'GET_BUCK_USER_EDIT_CREDIT_TRANSACTIONS'
);
export const getBuckUserEditCreditTransactions = authcode => ({
  type: GET_BUCK_USER_EDIT_CREDIT_TRANSACTIONS.type,
  payload: api_getBuckUserEditCreditTransactions(authcode)
});

export const GET_BUCK_USER_SHIPPING_ADDRESSES = actionComposer(
  'GET_BUCK_USER_SHIPPING_ADDRESSES'
);
export const getShippingAddresses = () => ({
  type: GET_BUCK_USER_SHIPPING_ADDRESSES.type,
  payload: api_getCurrentBuckUserShippingAddresses()
});

export const GET_BUCK_USER_BILLING_ADDRESSES = actionComposer(
  'GET_BUCK_USER_BILLING_ADDRESSES'
);
export const getBillingAddresses = () => ({
  type: GET_BUCK_USER_BILLING_ADDRESSES.type,
  payload: api_getCurrentBuckUserBillingAddresses()
});

export const GET_BUCK_USER_LAST_SHIPPING_ADDRESS = actionComposer(
  'GET_BUCK_USER_LAST_SHIPPING_ADDRESS'
);
export const getLastShippingAddress = () => ({
  type: GET_BUCK_USER_LAST_SHIPPING_ADDRESS.type,
  payload: api_getCurrentBuckUserLastAddress('shipping')
});

export const GET_BUCK_USER_LAST_BILLING_ADDRESS = actionComposer(
  'GET_BUCK_USER_LAST_BILLING_ADDRESS'
);
export const getLastBillingAddress = () => ({
  type: GET_BUCK_USER_LAST_BILLING_ADDRESS.type,
  payload: api_getCurrentBuckUserLastAddress('billing')
});

export const SET_REQUESTED_ACADEMIC_PRICING = 'SET_REQUESTED_ACADEMIC_PRICING';
export const setRequestedAcademicPricing = () => ({
  type: SET_REQUESTED_ACADEMIC_PRICING
});
