import { GET_RESOURCE_DETAIL_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    back_to_resources_label: '',
    benefits: [],
    description: '',
    description_extended: '',
    form_id: '',
    form_label: '',
    main_image: { url: '' },
    reference: [],
    resource_type: '',
    title: '',
    top_cta_button_label: '',
    layout: '',
    quote_references: [],
    footer_reference: []
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_RESOURCE_DETAIL_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_RESOURCE_DETAIL_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_RESOURCE_DETAIL_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
