import { APPLY_ORDER_COUPON, REMOVE_ORDER_COUPON } from './actions';

export const getInitialState = () => ({
  status: null,
  loading: false,
  error: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case APPLY_ORDER_COUPON.PENDING:
      return {
        ...state,
        loading: true
      };
    case APPLY_ORDER_COUPON.FULFILLED:
      return {
        ...state,
        status: action.payload,
        loading: false,
        error: false
      };
    case APPLY_ORDER_COUPON.REJECTED:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: true
      };
    case REMOVE_ORDER_COUPON.PENDING:
      return {
        ...state,
        loading: true
      };
    case REMOVE_ORDER_COUPON.FULFILLED:
      return {
        ...state,
        status: action.payload,
        loading: false,
        error: false
      };
    case REMOVE_ORDER_COUPON.REJECTED:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: true
      };
    default:
      return state;
  }
};
