import { GET_PODCAST_VIDEOS, LOAD_MORE_PODCAST_VIDEOS } from './actions';

export const getInitialState = () => ({
  loading: true,
  episodes: [],
  episode_count: 0,
  total_episode_count: 0
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PODCAST_VIDEOS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PODCAST_VIDEOS.FULFILLED:
      return {
        ...state,
        episodes: action.payload[0],
        episode_count: action.payload[0].length,
        total_episode_count: action.payload[1],
        loading: false
      };
    case GET_PODCAST_VIDEOS.REJECTED:
      return {
        ...state,
        loading: false
      };
    case LOAD_MORE_PODCAST_VIDEOS.FULFILLED:
      return {
        ...state,
        episodes: [...state.episodes, ...action.payload[0]],
        episode_count: state.episodes.length + action.payload[0].length
      };
    default:
      return state;
  }
};
