import { GET_CAREERS_STACK, GET_LEVER_JOBS } from './actions';

export const getInitialState = () => ({
  stack: {
    header: {
      background_image: {
        url: ''
      },
      title: '',
      subtitle: '',
      group: []
    },
    overview: {
      title: '',
      body: ''
    },
    quotes: [],
    benefits: {
      title: '',
      body: '',
      features: []
    }
  },
  loading: true,
  lever: []
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_CAREERS_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_CAREERS_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        loading: false
      };
    case GET_CAREERS_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_LEVER_JOBS.FULFILLED:
      return {
        ...state,
        lever: action.payload
      };
    default:
      return state;
  }
};
