import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';
import { api_AddToBuckCart, api_GetGKOGenes, api_GetGKOCellLines } from './api';

export const GET_GKO_STACK = actionComposer('GET_GKO_STACK');
export const getGKOStack = url => ({
  type: GET_GKO_STACK.type,
  payload: queryContentStackByType('order_gko', {
    includedReferences: ['benefits']
  })
});

export const GET_GKO_GENES = actionComposer('GET_GKO_GENES');
export const getGKOGenes = species => ({
  type: GET_GKO_GENES.type,
  payload: api_GetGKOGenes(species)
});

export const GET_GKO_CELL_LINES = actionComposer('GET_GKO_CELL_LINES');
export const getGKOCellLines = species => ({
  type: GET_GKO_CELL_LINES.type,
  payload: api_GetGKOCellLines(species)
});

export const ADD_GKO_ORDER_TO_CART = actionComposer('ADD_GKO_ORDER_TO_CART');
export const addOrderToCart = gkoOrder => ({
  type: ADD_GKO_ORDER_TO_CART.type,
  payload: api_AddToBuckCart(gkoOrder)
});

export const CLEAR_GKO_CART_ERRORS = 'CLEAR_GKO_CART_ERRORS';
export const clearCartErrors = () => {
  return {
    type: CLEAR_GKO_CART_ERRORS
  };
};
