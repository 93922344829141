import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';
import URLS from 'constants/URLS';

export const ORDER_GET_ORDER_STACK = actionComposer('ORDER_GET_ORDER_STACK');
export const getOrderStack = url => {
  const contentType = URLS.URL_TO_CONTENT_TYPE[url.replace(/\/+$/, '')];

  return {
    type: ORDER_GET_ORDER_STACK.type,
    payload: queryContentStackByUrl(contentType, url)
  };
};
