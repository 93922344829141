import {
  GET_ECL_STACK,
  GET_ECL_GENES,
  GET_ECL_CELL_LINES,
  SET_ECL_PROJECT_DETAILS,
  SET_ECL_PROJECT_NAME,
  SET_ECL_PROJECT_REPLICATES,
  SET_ECL_PROJECT_CONTROLS,
  SET_ECL_ACTIVE_RACK,
  SET_ECL_ADD_DIALOG_METHOD,
  RESET_ECL_PROJECT,
  DELETE_ECL_POOLS,
  SET_ECL_PROJECT_NEW,
  ADD_ECL_EMPTY_POOL,
  ADD_ECL_GENES_TO_PROJECT,
  UPDATE_ECL_RACK_POOLS,
  UPDATE_ECL_POOL,
  ADD_ECL_PROJECT_TO_CART,
  CLEAR_ECL_CART_ERRORS
} from './actions';

import { selectFilledProjectPools } from './utilities';
import { version, rackOptions, orderRequirements } from './constants';

export const getInitialState = () => ({
  project: {
    version: version,
    name: '',
    genome: null,
    cellLine: null,
    rackType: rackOptions[0], // 96 pool rack is currently the only option
    replicates: 1,
    new: true,
    pools: [],
    controls: {
      ntc1: orderRequirements.minControls.ntc1,
      ntc2: orderRequirements.minControls.ntc2,
      mockWT: orderRequirements.minControls.mockWT
    },
    activeRack: 0,
    addDialogMethod: null
  },
  stack: {},
  stackLoading: true,
  validCellLines: [],
  validCellLinesLoading: false,
  validGenes: {},
  validGenesLoading: false,
  cartError: false,
  cartErrorMessage: null,
  cartLoading: false,
  redirectToCart: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_ECL_STACK.PENDING:
      return {
        ...state,
        stackLoading: true
      };
    case GET_ECL_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload[0],
        stackLoading: false
      };
    case GET_ECL_STACK.REJECTED:
      return {
        ...state,
        stackLoading: false
      };
    case GET_ECL_GENES.PENDING:
      return {
        ...state,
        validGenesLoading: true
      };
    case GET_ECL_GENES.FULFILLED:
      return {
        ...state,
        validGenes: action.payload,
        validGenesLoading: false
      };
    case GET_ECL_GENES.REJECTED:
      return {
        ...state,
        validGenesLoading: false
      };
    case GET_ECL_CELL_LINES.PENDING:
      return {
        ...state,
        validCellLinesLoading: true
      };
    case GET_ECL_CELL_LINES.FULFILLED:
      return {
        ...state,
        validCellLines: action.payload,
        validCellLinesLoading: false
      };
    case GET_ECL_CELL_LINES.REJECTED:
      return {
        ...state,
        validCellLinesLoading: false
      };
    case ADD_ECL_PROJECT_TO_CART.PENDING:
      return {
        ...state,
        cartLoading: true,
        cartError: false
      };
    case ADD_ECL_PROJECT_TO_CART.FULFILLED:
      return {
        ...state,
        cartLoading: false,
        cartError: false,
        redirectToCart: true
      };
    case ADD_ECL_PROJECT_TO_CART.REJECTED:
      return {
        ...state,
        cartLoading: false,
        cartError: true,
        cartErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload
      };
    case CLEAR_ECL_CART_ERRORS:
      return {
        ...state,
        cartError: false,
        cartErrorMessage: null,
        redirectToCart: false
      };
    case SET_ECL_PROJECT_DETAILS:
      return {
        ...state,
        project: { ...state.project, ...action.newDetails }
      };
    case SET_ECL_PROJECT_NAME:
      return {
        ...state,
        project: { ...state.project, name: action.newName }
      };
    case SET_ECL_PROJECT_REPLICATES:
      return {
        ...state,
        project: { ...state.project, replicates: action.newReplicatesCount }
      };
    case SET_ECL_PROJECT_CONTROLS:
      return {
        ...state,
        project: {
          ...state.project,
          controls: {
            ...state.project.controls,
            [action.controlType]: action.newControlCount
          }
        }
      };
    case SET_ECL_ACTIVE_RACK:
      return {
        ...state,
        project: { ...state.project, activeRack: action.rackIdx }
      };
    case SET_ECL_ADD_DIALOG_METHOD:
      return {
        ...state,
        project: { ...state.project, addDialogMethod: action.value }
      };
    case RESET_ECL_PROJECT:
      return getInitialState();

    case DELETE_ECL_POOLS:
      return {
        ...state,
        project: {
          ...state.project,
          pools: [
            ...state.project.pools.slice(0, action.startIdx),
            ...state.project.pools.slice(action.startIdx + action.deleteCount)
          ]
        }
      };
    case SET_ECL_PROJECT_NEW:
      return {
        ...state,
        project: {
          ...state.project,
          new: action.value
        }
      };

    case ADD_ECL_EMPTY_POOL:
      return {
        ...state,
        project: {
          ...state.project,
          pools: [...state.project.pools, action.pool]
        }
      };

    case ADD_ECL_GENES_TO_PROJECT.FULFILLED:
      return {
        ...state,
        project: {
          ...state.project,
          pools: [...selectFilledProjectPools(state.project), ...action.payload]
        }
      };

    case UPDATE_ECL_RACK_POOLS:
      return {
        ...state,
        project: {
          ...state.project,
          pools: action.newPools
        }
      };

    case UPDATE_ECL_POOL:
      return {
        ...state,
        project: {
          ...state.project,
          pools: Object.assign([...state.project.pools], {
            [action.poolIdx]: {
              ...state.project.pools[action.poolIdx],
              ...action.newPool
            }
          })
        }
      };

    default:
      return state;
  }
};
