import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_AddToBuckCart = eclProject => {
  const eclProjectForCart = {};

  //Remove included controls
  eclProjectForCart.name = eclProject.name;
  eclProjectForCart.num_mock_wells = eclProject.controls?.mockWT;
  eclProjectForCart.num_ntc1_controls = eclProject.controls?.ntc1;
  eclProjectForCart.num_ntc2_controls = eclProject.controls?.ntc2;
  eclProjectForCart.product_slug = 'engineered_cell_libraries';
  eclProjectForCart.replicates = eclProject?.replicates;
  eclProjectForCart.species = eclProject.genome?.commonName;
  eclProjectForCart.cell_line = eclProject.cellLine?.name;
  eclProjectForCart.genes = eclProject.pools
    .filter(pool => pool.value !== '')
    .map(pool => ({
      ensembl_id: pool.ensemblId,
      essential_gene: pool.commonEssential,
      gene_name: pool.value
    }));
  console.log(eclProjectForCart);
  return fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: [
      {
        ...eclProjectForCart
      }
    ]
  });
};

export const api_GetECLGenes = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/ecl/genes/?species=${species}`,
    {
      method: 'GET'
    }
  );
};

export const api_GetECLCellLines = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/ecl/cell-lines/?species=${species}`,
    {
      method: 'GET'
    }
  );
};
