import _ from 'lodash';
import shortid from 'shortid';
import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';
import { defaultPool, genomeOptions } from './constants';
import { geneArrayToPools } from './utilities';
import { api_AddToBuckCart, api_GetECLGenes, api_GetECLCellLines } from './api';

export const GET_ECL_STACK = actionComposer('GET_ECL_STACK');
export const getECLStack = url => ({
  type: GET_ECL_STACK.type,
  payload: queryContentStackByType('order_ecl', {
    includedReferences: ['benefits']
  })
});

export const GET_ECL_GENES = actionComposer('GET_ECL_GENES');
export const getECLGenes = species => ({
  type: GET_ECL_GENES.type,
  payload: api_GetECLGenes(species)
});

export const GET_ECL_CELL_LINES = actionComposer('GET_ECL_CELL_LINES');
export const getECLCellLines = species => ({
  type: GET_ECL_CELL_LINES.type,
  payload: api_GetECLCellLines(species)
});

export const ADD_ECL_PROJECT_TO_CART = actionComposer(
  'ADD_ECL_PROJECT_TO_CART'
);
export const addProjectToCart = project => ({
  type: ADD_ECL_PROJECT_TO_CART.type,
  payload: api_AddToBuckCart(project)
});

export const CLEAR_ECL_CART_ERRORS = 'CLEAR_ECL_CART_ERRORS';
export const clearCartErrors = () => {
  return {
    type: CLEAR_ECL_CART_ERRORS
  };
};

export const SET_ECL_PROJECT_DETAILS = 'SET_ECL_PROJECT_DETAILS';
export const setProjectDetails = (name, genome, cellLine, controls) => {
  return {
    type: SET_ECL_PROJECT_DETAILS,
    newDetails: {
      name: name,
      genome: _.find(genomeOptions, ['slug', genome]),
      cellLine: cellLine,
      controls: {
        ntc1: controls.ntc1,
        ntc2: controls.ntc2,
        mockWT: controls.mockWT
      }
    }
  };
};

export const SET_ECL_PROJECT_NAME = 'SET_ECL_PROJECT_NAME';
export const setProjectName = name => {
  return {
    type: SET_ECL_PROJECT_NAME,
    newName: name
  };
};

export const SET_ECL_PROJECT_REPLICATES = 'SET_ECL_PROJECT_REPLICATES';
export const setProjectReplicates = newReplicatesCount => {
  return {
    type: SET_ECL_PROJECT_REPLICATES,
    newReplicatesCount: parseInt(newReplicatesCount)
  };
};

export const SET_ECL_PROJECT_CONTROLS = 'SET_ECL_PROJECT_CONTROLS';
export const setProjectControls = (controlType, newControlCount) => {
  return {
    type: SET_ECL_PROJECT_CONTROLS,
    controlType: controlType,
    newControlCount: newControlCount
  };
};

export const SET_ECL_ACTIVE_RACK = 'SET_ECL_ACTIVE_RACK';
export const setActiveRack = rackIdx => {
  return {
    type: SET_ECL_ACTIVE_RACK,
    rackIdx: rackIdx
  };
};

export const SET_ECL_ADD_DIALOG_METHOD = 'SET_ECL_ADD_DIALOG_METHOD';
export const setAddDialogMethod = value => {
  return {
    type: SET_ECL_ADD_DIALOG_METHOD,
    value: value
  };
};

export const RESET_ECL_PROJECT = 'RESET_ECL_PROJECT';
export const resetProject = () => {
  return {
    type: RESET_ECL_PROJECT
  };
};

export const ADD_ECL_EMPTY_POOL = 'ADD_ECL_EMPTY_POOL';
export const addEmptyPool = () => {
  return {
    type: ADD_ECL_EMPTY_POOL,
    pool: {
      ...defaultPool,
      id: `pool-${shortid.generate()}`
    }
  };
};

export const ADD_ECL_GENES_TO_PROJECT = actionComposer(
  'ADD_ECL_GENES_TO_PROJECT'
);
export const addGenesToProject = (genes, validGenes, project) => {
  return {
    type: ADD_ECL_GENES_TO_PROJECT.type,
    payload: geneArrayToPools(genes, validGenes, project)
  };
};

export const DELETE_ECL_POOLS = 'DELETE_ECL_POOLS';
export const deletePools = (startIdx, deleteCount) => {
  return {
    type: DELETE_ECL_POOLS,
    startIdx: startIdx,
    deleteCount: deleteCount
  };
};

export const SET_ECL_PROJECT_NEW = 'SET_ECL_PROJECT_NEW';
export const setProjectNew = value => {
  return {
    type: SET_ECL_PROJECT_NEW,
    value: value
  };
};

export const UPDATE_ECL_RACK_POOLS = 'UPDATE_ECL_RACK_POOLS';
export const updateRackPools = pools => {
  return {
    type: UPDATE_ECL_RACK_POOLS,
    newPools: pools
  };
};

export const UPDATE_ECL_POOL = 'UPDATE_ECL_POOL';
export const updatePool = (poolIdx, pool) => {
  return {
    type: UPDATE_ECL_POOL,
    poolIdx: poolIdx,
    newPool: pool
  };
};
