import { combineReducers } from 'redux';
import applicationsDetail from 'features/applications/detail/reducers';
import applicationsLanding from 'features/applications/reducers';
import blogBase from 'features/blog/reducers';
import blogRelated from 'features/blog/related/reducers';
import blogPosts from 'features/blog/posts/reducers';
import blogDetail from 'features/blog/detail/reducers';
import blogCollections from 'features/blog/collections/reducers';
import blogCollectionDetail from 'features/blog/collections/detail/reducers';
import buckaneer from 'features/buckaneer/reducers';
import buckaneerEditCredit from 'features/buckaneer/editcredit/reducers';
import calculator from 'features/calculator/reducers';
import careers from 'features/company/careers/reducers';
import caseStudies from 'features/casestudies/reducers';
import caseStudyDetail from 'features/casestudies/detail/reducers';
import team from 'features/company/team/reducers';
import teamDetail from 'features/company/team/detail/reducers';
import cart from 'features/cart/reducers';
import checkout from 'features/checkout/reducers';
import company from 'features/company/reducers';
import company_footer from 'features/company/footer/reducers';
import contact from 'features/contact/reducers';
import contact_detail from 'features/contact/detail/reducers';
import customerReferenceCode from 'features/order/customerreferencecode/reducers';
import customers from 'features/customers/reducers';
import distributor from 'features/distributor/reducers';
import eventsDetail from 'features/events/detail/reducers';
import eventsLanding from 'features/events/reducers';
import eventsThanks from 'features/events/thanks/reducers';
import experienceDetail from 'features/experiences/detail/reducers';
import filters from 'features/filter/reducers';
import footer from 'features/navigation/footer/reducers';
import guideChapter from 'features/guide/chapter/reducers';
import guideHome from 'features/guide/home/reducers';
import guidedEdit from 'features/guidededit/reducers';
import hub from 'features/hub/reducers';
import hubspot from 'features/hubspot/reducers';
import helpDetail from 'features/help/detail/reducers';
import helpCategory from 'features/help/category/reducers';
import helpLanding from 'features/help/reducers';
import landing from 'features/landing/reducers';
import layout from 'features/layout/reducers';
import legal from 'features/legal/reducers';
import navigation from 'features/navigation/header/reducers';
import offersDetail from 'features/offers/detail/reducers';
import offersLanding from 'features/offers/reducers';
import order from 'features/order/reducers';
import order_advanced_cells from 'features/order/advanced/cells/reducers';
import order_advanced_gmp from 'features/order/advanced/gmp/reducers';
import order_advanced_request from 'features/order/advanced/reducers';
import order_advanced_rna from 'features/order/advanced/rna/reducers';
import orderRecalculateQuote from 'features/order/recalculate_quote/reducers';
import orderSummary from 'features/order/summary/reducers';
import orderCoupon from 'features/order/coupon/reducers';
import orderStatus from 'features/order/status/reducers';
import orderAgain from 'features/order/orderagain/reducers';
import orderPayment from 'features/order/payment/reducers';
import orderODL from 'features/order/odl/reducers';
import orderECL from 'features/order/ecl/reducers';
import orderXKO from 'features/order/xko/reducers';
import orderGKO from 'features/order/gko/reducers';
import orderSGRNA from 'features/order/sgrna/reducers';
import orderPricing from 'features/order/pricing/reducers';
import orderAddOns from 'features/order/addons/reducers';
import podcastBase from 'features/podcast/reducers';
import podcastVideos from 'features/podcast/videos/reducers';
import podcastEpisodes from 'features/podcast/episodes/reducers';
import podcastDetail from 'features/podcast/detail/reducers';
import press_detail from 'features/press/detail/reducers';
import press_landing from 'features/press/reducers';
import priceOrderWidgets from 'features/order/elements/reducers';
import product from 'features/product/detail/reducers';
import productFamily from 'features/product/family/reducers';
import productPricing from 'features/product/pricing/reducers';
import publicationsLanding from 'features/publications/reducers';
import publicationsDetail from 'features/publications/detail/reducers';
import registration from 'features/registration/reducers';
import reports from 'features/reports/reducers';
import resourcesDetail from 'features/resources/detail/reducers';
import resourcesLanding from 'features/resources/reducers';
import scrolltotop from 'features/scrolltotop/reducers';
import survey from 'features/survey/reducers';
import taxonomy from 'features/taxonomy/reducers';
import thankyou from 'features/thankyou/reducers';
import userPromo from 'features/userpromo/reducers';
import util from 'features/util/reducers';
import welcome from 'features/welcome/reducers';
import welcome_section from 'features/welcome/section/reducers';
import home from 'features/home/reducers';
import workflowDetail from 'features/workflows/detail/reducers';
import platformDetail from 'features/platforms/detail/reducers';

const rootReducer = combineReducers({
  applicationsDetail,
  applicationsLanding,
  blogBase,
  blogRelated,
  blogPosts,
  blogDetail,
  blogCollections,
  blogCollectionDetail,
  buckaneer,
  buckaneerEditCredit,
  calculator,
  careers,
  team,
  teamDetail,
  cart,
  checkout,
  company,
  company_footer,
  contact,
  contact_detail,
  customerReferenceCode,
  customers,
  caseStudies,
  caseStudyDetail,
  distributor,
  eventsDetail,
  eventsLanding,
  eventsThanks,
  experienceDetail,
  filters,
  footer,
  guideChapter,
  guideHome,
  guidedEdit,
  hub,
  hubspot,
  helpDetail,
  helpCategory,
  helpLanding,
  landing,
  layout,
  legal,
  navigation,
  offersDetail,
  offersLanding,
  order,
  order_advanced_cells,
  order_advanced_gmp,
  order_advanced_request,
  order_advanced_rna,
  orderRecalculateQuote,
  orderSummary,
  orderCoupon,
  orderStatus,
  orderAgain,
  orderPayment,
  orderODL,
  orderECL,
  orderXKO,
  orderGKO,
  orderSGRNA,
  orderPricing,
  orderAddOns,
  podcastBase,
  podcastEpisodes,
  podcastVideos,
  podcastDetail,
  press_detail,
  press_landing,
  priceOrderWidgets,
  product,
  productFamily,
  productPricing,
  publicationsLanding,
  publicationsDetail,
  registration,
  reports,
  resourcesDetail,
  resourcesLanding,
  scrolltotop,
  survey,
  taxonomy,
  thankyou,
  userPromo,
  util,
  welcome,
  welcome_section,
  home,
  workflowDetail,
  platformDetail
});

export default rootReducer;
