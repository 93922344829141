import { GET_CUSTOMERS_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    header: {
      title: '',
      subtitle: '',
      customer_logos: []
    }
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_CUSTOMERS_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_CUSTOMERS_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        loading: false
      };
    case GET_CUSTOMERS_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
