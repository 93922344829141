import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_EVENTS_THANKS_STACK = actionComposer(
  'GET_EVENTS_THANKS_STACK'
);
export const getEventsThanksStack = () => ({
  type: GET_EVENTS_THANKS_STACK.type,
  payload: queryContentStackByUrl('event_thanks', '/events/thanks')
});
