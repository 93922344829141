import _ from 'lodash';
import { isPromise } from 'utilities/promise';
import httpErrors from 'constants/HttpError';
import { redirect404 } from 'features/util/actions';

export default function errorMiddleware() {
  return next => action => {
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }

    /**
     * The error middleware serves to dispatch the initial pending promise to
     * the promise middleware, but adds a `catch`.
     */
    if (!action.meta || !action.meta.noErrorHandling) {
      // Dispatch initial pending promise, but catch any errors
      return next(action).catch(error => {
        let has_type_or_error = _.has(error, 'type') || _.has(error, 'error');
        if (_.isPlainObject(error) && has_type_or_error) {
          if (error.type === httpErrors.HTTP404.type) {
            return next(redirect404(error.redirectUrl));
          }
          if (error.error) {
            // Since error is not an Error proper and we don't want it being interpreted that way,
            // just return the message string
            error = error.error.message;
          }
        }

        /**
         * In testing we don't care about any unhandled promise rejection
         * because this is an allowed state in the UI
         */
        return process.env.ENV === 'test' ? error : Promise.reject(error);
      });
    }

    return next(action);
  };
}
