import {
  GET_PRESS_HEADER_LANDING_STACK,
  GET_PRESS_LANDING_POSTS_STACK,
  GET_PRESS_LANDING_NEWS_STACK,
  SET_PRESS_LANDING_POST_COUNT
} from './actions';

export const getInitialState = () => ({
  loading: true,
  header: null,
  posts: null,
  news: null,
  post_count: 10
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PRESS_HEADER_LANDING_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PRESS_HEADER_LANDING_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        header: action.payload
      };
    case GET_PRESS_HEADER_LANDING_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_PRESS_LANDING_POSTS_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PRESS_LANDING_POSTS_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        posts: action.payload
      };
    case GET_PRESS_LANDING_POSTS_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_PRESS_LANDING_NEWS_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PRESS_LANDING_NEWS_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        news: action.payload
      };
    case GET_PRESS_LANDING_NEWS_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case SET_PRESS_LANDING_POST_COUNT:
      return {
        ...state,
        post_count: action.post_count
      };
    default:
      return state;
  }
};
