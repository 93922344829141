import { GET_BLOG_RELATED_POSTS_STACK } from './actions';

export const getInitialState = () => ({
  posts: []
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_BLOG_RELATED_POSTS_STACK.FULFILLED:
      return {
        ...state,
        posts: action.payload[0]
      };
    default:
      return state;
  }
};
