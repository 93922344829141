import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByTypeWithCount,
  queryContentStackByUrl
} from 'utilities/contentStack';

import { EVENTS_PER_PAGE } from 'features/events/constants';

export const GET_EVENTS_LANDING_STACK = actionComposer(
  'GET_EVENTS_LANDING_STACK'
);
export const getEventsLandingStack = () => ({
  type: GET_EVENTS_LANDING_STACK.type,
  payload: queryContentStackByUrl('events', '/events', {
    includedReferences: ['filters.types']
  })
});

export const GET_EVENTS_LANDING_EVENTS_STACK = actionComposer(
  'GET_EVENTS_LANDING_EVENTS_STACK'
);
export const LOAD_MORE_EVENTS_LANDING_EVENTS_STACK = actionComposer(
  'LOAD_MORE_EVENTS_LANDING_EVENTS_STACK'
);

export const getEvents = (options = {}) => ({
  type: options.append
    ? LOAD_MORE_EVENTS_LANDING_EVENTS_STACK.type
    : GET_EVENTS_LANDING_EVENTS_STACK.type,
  payload: queryContentStackByTypeWithCount('event_detail', {
    includedReferences: ['type', 'topic'],
    limit: options.limit || EVENTS_PER_PAGE,
    skip: options.skip || 0,
    query: options.filter || options.query,
    notEqualTo: { key: 'unlisted', value: true },
    ascending: 'time.start_date'
  })
});

export const SET_EVENTS_LANDING_EVENT_COUNT = 'SET_EVENTS_LANDING_EVENT_COUNT';
export const setEventCount = event_count => ({
  type: SET_EVENTS_LANDING_EVENT_COUNT,
  event_count: event_count
});
