import { ORDER_GET_ORDER_STACK } from 'features/order/actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    add_to_cart_button_label: '',
    cell_line_input_label: '',
    cell_line_input_placeholder: '',
    gene_input_label: '',
    gene_input_placeholder: '',
    genome_input_label: '',
    genome_input_placeholder: '',
    genome_help_text: '',
    get_help_text: '',
    heading_prepend: '',
    order_error_message: '',
    pricing_type: [
      {
        actual_price: '',
        line_items: [],
        shipping_text: '',
        shipping_notes: ''
      }
    ],
    pricing_toggle_label: '',
    product_description: '',
    product_logo: {
      url: ''
    },
    product_family: '',
    product_name: ''
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case ORDER_GET_ORDER_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case ORDER_GET_ORDER_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case ORDER_GET_ORDER_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
