import actionComposer from 'utilities/actionComposer';
import {
  api_get_custom_request,
  api_update_custom_request,
  api_update_custom_request_sales_rep,
  api_list_custom_request_sales_reps
} from './api';
import { queryContentStackById } from 'utilities/contentStack';

// CORE COMPONENT ACTIONS

export const ORDER_CUSTOM_REQUEST_SUBMIT = actionComposer(
  'ORDER_CUSTOM_REQUEST_SUBMIT'
);
export const submit = (requestSlug, data) => {
  return {
    type: ORDER_CUSTOM_REQUEST_SUBMIT.type,
    payload: api_update_custom_request(requestSlug, data)
  };
};

export const ORDER_CUSTOM_REQUEST_SET_SALES_REP = actionComposer(
  'ORDER_CUSTOM_REQUEST_SET_SALES_REP'
);
export const setSalesRep = (requestSlug, salesRepId) => {
  return {
    type: ORDER_CUSTOM_REQUEST_SET_SALES_REP.type,
    payload: api_update_custom_request_sales_rep(requestSlug, {
      sales_rep_id: salesRepId
    })
  };
};

export const ORDER_CUSTOM_REQUEST_GET_SALES_REP_LIST = actionComposer(
  'ORDER_CUSTOM_REQUEST_GET_SALES_REP_LIST'
);
export const listSalesReps = () => {
  return {
    type: ORDER_CUSTOM_REQUEST_GET_SALES_REP_LIST.type,
    payload: api_list_custom_request_sales_reps()
  };
};

export const ORDER_CUSTOM_REQUEST_GET = actionComposer(
  'ORDER_CUSTOM_REQUEST_GET'
);
export const getCustomRequest = requestSlug => ({
  type: ORDER_CUSTOM_REQUEST_GET.type,
  payload: api_get_custom_request(requestSlug)
});

export const ORDER_CUSTOM_REQUEST_CHANGE_REQUEST_TYPE =
  'ORDER_CUSTOM_REQUEST_CHANGE_REQUEST_TYPE';
export const changeRequestType = childFormValue => ({
  type: ORDER_CUSTOM_REQUEST_CHANGE_REQUEST_TYPE,
  requestType: childFormValue
});

export const ORDER_CUSTOM_REQUEST_SET_ANNOTATION =
  'ORDER_CUSTOM_REQUEST_SET_ANNOTATION';
export const setAnnotation = text => ({
  type: ORDER_CUSTOM_REQUEST_SET_ANNOTATION,
  text: text
});

export const ORDER_CUSTOM_REQUEST_SET_ATTACHMENT =
  'ORDER_CUSTOM_REQUEST_SET_ATTACHMENT';
export const setAttachment = targetElement => ({
  type: ORDER_CUSTOM_REQUEST_SET_ATTACHMENT,
  file: targetElement.files ? targetElement.files[0] : {}
});

// PARENT FORM ACTIONS

export const ORDER_CUSTOM_REQUEST_PARENT_FORM_GET_CS_DATA = actionComposer(
  'ORDER_CUSTOM_REQUEST_PARENT_FORM_GET_CS_DATA'
);
export const getParentFormContentStackData = (
  contentStackTypeID,
  contentStackEntryID
) => ({
  type: ORDER_CUSTOM_REQUEST_PARENT_FORM_GET_CS_DATA.type,
  payload: queryContentStackById(contentStackTypeID, contentStackEntryID)
});

export const ORDER_CUSTOM_REQUEST_PARENT_FORM_RESET =
  'ORDER_CUSTOM_REQUEST_PARENT_FORM_RESET';
export const resetParentForm = () => ({
  type: ORDER_CUSTOM_REQUEST_PARENT_FORM_RESET
});

export const ORDER_CUSTOM_REQUEST_PARENT_FORM_SET_ANSWER =
  'ORDER_CUSTOM_REQUEST_PARENT_FORM_SET_ANSWER';
export const setParentFormAnswer = (id, answer) => ({
  type: ORDER_CUSTOM_REQUEST_PARENT_FORM_SET_ANSWER,
  id: id,
  answer: answer
});

export const ORDER_CUSTOM_REQUEST_PARENT_FORM_CHECK_CHECKBOX =
  'ORDER_CUSTOM_REQUEST_PARENT_FORM_CHECK_CHECKBOX';
export const ORDER_CUSTOM_REQUEST_PARENT_FORM_UNCHECK_CHECKBOX =
  'ORDER_CUSTOM_REQUEST_PARENT_FORM_UNCHECK_CHECKBOX';
export const toggleParentFormCheckbox = (
  box,
  checked,
  parent_question_id,
  value
) => {
  if (checked) {
    return {
      type: ORDER_CUSTOM_REQUEST_PARENT_FORM_CHECK_CHECKBOX,
      box: box,
      value: value,
      parent: parent_question_id
    };
  } else {
    return {
      type: ORDER_CUSTOM_REQUEST_PARENT_FORM_UNCHECK_CHECKBOX,
      box: box,
      value: value,
      parent: parent_question_id
    };
  }
};

export const ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_EXPANDED =
  'ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_EXPANDED';
export const toggleParentFormExpanded = (panel, current) => ({
  type: ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_EXPANDED,
  expandedPanel: panel === current ? false : panel
});

export const ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_TOGGLE =
  'ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_TOGGLE';
export const toggleParentFormToggle = (question_id, value) => ({
  question_id,
  value,
  type: ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_TOGGLE
});

// CHILD FORM ACTIONS

export const ORDER_CUSTOM_REQUEST_CHILD_FORM_GET_CS_DATA = actionComposer(
  'ORDER_CUSTOM_REQUEST_CHILD_FORM_GET_CS_DATA'
);
export const getChildFormContentStackData = (
  contentStackTypeID,
  contentStackEntryID
) => ({
  type: ORDER_CUSTOM_REQUEST_CHILD_FORM_GET_CS_DATA.type,
  payload: queryContentStackById(contentStackTypeID, contentStackEntryID)
});

export const ORDER_CUSTOM_REQUEST_CHILD_FORM_RESET =
  'ORDER_CUSTOM_REQUEST_CHILD_FORM_RESET';
export const resetChildForm = () => ({
  type: ORDER_CUSTOM_REQUEST_CHILD_FORM_RESET
});

export const ORDER_CUSTOM_REQUEST_CHILD_FORM_SET_ANSWER =
  'ORDER_CUSTOM_REQUEST_CHILD_FORM_SET_ANSWER';
export const setChildFormAnswer = (id, answer) => ({
  type: ORDER_CUSTOM_REQUEST_CHILD_FORM_SET_ANSWER,
  id: id,
  answer: answer
});

export const ORDER_CUSTOM_REQUEST_CHILD_FORM_CHECK_CHECKBOX =
  'ORDER_CUSTOM_REQUEST_CHILD_FORM_CHECK_CHECKBOX';
export const ORDER_CUSTOM_REQUEST_CHILD_FORM_UNCHECK_CHECKBOX =
  'ORDER_CUSTOM_REQUEST_CHILD_FORM_UNCHECK_CHECKBOX';
export const toggleChildFormCheckbox = (
  box,
  checked,
  parent_question_id,
  value
) => {
  if (checked) {
    return {
      type: ORDER_CUSTOM_REQUEST_CHILD_FORM_CHECK_CHECKBOX,
      box: box,
      value: value,
      parent: parent_question_id
    };
  } else {
    return {
      type: ORDER_CUSTOM_REQUEST_CHILD_FORM_UNCHECK_CHECKBOX,
      box: box,
      value: value,
      parent: parent_question_id
    };
  }
};

export const ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_EXPANDED =
  'ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_EXPANDED';
export const toggleChildFormExpanded = (panel, current) => ({
  type: ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_EXPANDED,
  expandedPanel: panel === current ? false : panel
});

export const ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_TOGGLE =
  'ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_TOGGLE';
export const toggleChildFormToggle = (question_id, value) => ({
  question_id,
  value,
  type: ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_TOGGLE
});
