import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PLATFORMS_DETAIL_STACK = actionComposer(
  'GET_PLATFORMS_DETAIL_STACK'
);
export const getPlatformDetailStack = url => ({
  type: GET_PLATFORMS_DETAIL_STACK.type,
  payload: queryContentStackByUrl('platform_detail', url, {
    includedReferences: [
      'modular_blocks.section.modular_blocks.quote.quote_reference',
      'modular_blocks.section.modular_blocks.figure.figure_reference'
    ]
  })
});
