import actionComposer from 'utilities/actionComposer';
import { queryContentStackByTypeWithCount } from 'utilities/contentStack';
import { EPISODES_PER_PAGE } from 'features/podcast/constants';

export const GET_PODCAST_EPISODES = actionComposer('GET_PODCAST_EPISODES');
export const LOAD_MORE_PODCAST_EPISODES = actionComposer(
  'LOAD_MORE_PODCAST_EPISODES'
);

export const getEpisodes = (options = {}) => ({
  type: options.append
    ? LOAD_MORE_PODCAST_EPISODES.type
    : GET_PODCAST_EPISODES.type,
  payload: queryContentStackByTypeWithCount('podcast_episode', {
    limit: options.limit || EPISODES_PER_PAGE,
    skip: options.skip || 0,
    query: { type: { $ne: 'video' } } || options.query,
    descending: 'episode_number'
  })
});
