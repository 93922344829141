import { GET_LEGAL_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    body_content: ''
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_LEGAL_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload
      };
    default:
      return state;
  }
};
