import {
  APPLY_CUSTOMER_REFERENCE_CODE,
  REMOVE_CUSTOMER_REFERENCE_CODE
} from './actions';

export const getInitialState = () => ({
  status: null,
  loading: false,
  error: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case APPLY_CUSTOMER_REFERENCE_CODE.PENDING:
      return {
        ...state,
        loading: true
      };
    case APPLY_CUSTOMER_REFERENCE_CODE.FULFILLED:
      return {
        ...state,
        status: action.payload,
        loading: false,
        error: false
      };
    case APPLY_CUSTOMER_REFERENCE_CODE.REJECTED:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: true
      };
    case REMOVE_CUSTOMER_REFERENCE_CODE.PENDING:
      return {
        ...state,
        loading: true
      };
    case REMOVE_CUSTOMER_REFERENCE_CODE.FULFILLED:
      return {
        ...state,
        status: action.payload,
        loading: false,
        error: false
      };
    case REMOVE_CUSTOMER_REFERENCE_CODE.REJECTED:
      return {
        ...state,
        loading: false,
        status: action.payload,
        error: true
      };
    default:
      return state;
  }
};
