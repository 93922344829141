import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PUBLICATIONS_DETAIL_STACK = actionComposer(
  'GET_PUBLICATIONS_DETAIL_STACK'
);
export const getPublicationsDetailStack = url => ({
  type: GET_PUBLICATIONS_DETAIL_STACK.type,
  payload: queryContentStackByUrl('enhanced_publication_detail', url, {
    includedReferences: [
      'sidebar_ctas',
      'footer_cta',
      'related_content',
      'featured_content.figure.figure_reference',
      'categories_tags.application',
      'categories_tags.research_area',
      'categories_tags.editing_method',
      'categories_tags.paper_type',
      'categories_tags.product',
      'categories_tags.product.family',
      'categories_tags.model_organism',
      'categories_tags.customer_affiliation'
    ]
  })
});
