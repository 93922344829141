import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_LANDING_STACK = actionComposer('GET_LANDING_STACK');
export const getLandingStack = url => ({
  type: GET_LANDING_STACK.type,
  payload: queryContentStackByUrl('landing', url, {
    includedReferences: [
      'content.quote.quote_reference',
      'content.blog.blog_reference',
      'content.resource.resource_reference',
      'content.scrolling_quotes.quotes',
      'content.table.table_reference',
      'section_content.sections.cta.cta_reference',
      'section_content.sections.resource.resource_reference',
      'section_content.sections.blog.blog_reference',
      'section_content.sections.podcast.podcast_reference',
      'section_content.sections.related_publications.filter_to.research_area',
      'section_content.sections.related_publications.filter_to.application',
      'section_content.sections.related_publications.filter_to.product',
      'section_content.sections.case_study.case_study_reference',
      'section_content.sections.case_study.case_study_reference.categories_tags.application',
      'section_content.sections.case_study.case_study_reference.categories_tags.research_area',
      'section_content.sections.case_study.case_study_reference.categories_tags.editing_method',
      'section_content.sections.case_study.case_study_reference.categories_tags.paper_type',
      'section_content.sections.case_study.case_study_reference.categories_tags.product',
      'section_content.sections.case_study.case_study_reference.categories_tags.product.family',
      'section_content.sections.case_study.case_study_reference.categories_tags.model_organism',
      'section_content.sections.case_study.case_study_reference.categories_tags.customer_affiliation',
      'section_content.sections.section.content.table.table_reference',
      'section_content.sections.section.content.figure.figure_reference',
      'section_content.sections.section.content.figure_gallery.figure_references',
      'section_content.sections.section.content.figure_gallery.figures.figure_reference',
      'section_content.sections.section.content.quote.quote_reference',
      'section_content.sections.section.content.tabbed_text.tabs.product_references',
      'section_content.sections.section.content.accordion.sections.tabs.product_references',
      'section_content.sections.section.content.publication.publication_reference',
      'section_content.sections.section.content.publication.publication_reference.categories_tags.product',
      'section_content.sections.section.content.publication.publication_reference.categories_tags.application',
      'section_content.sections.section.content.publication.publication_reference.categories_tags.research_area',
      'categories_tags.application',
      'categories_tags.research_area',
      'categories_tags.editing_method',
      'categories_tags.paper_type',
      'categories_tags.product',
      'categories_tags.product.family',
      'categories_tags.model_organism',
      'categories_tags.customer_affiliation',
      'footer.cta'
    ]
  })
});
