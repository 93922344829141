import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PRESS_DETAIL_STACK = actionComposer('GET_PRESS_DETAIL_STACK');
export const getContentStackData = url => ({
  type: GET_PRESS_DETAIL_STACK.type,
  payload: queryContentStackByUrl('press_release_page', url, {
    includedReferences: ['reference']
  })
});
