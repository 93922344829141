import actionComposer from 'utilities/actionComposer';
import { fRequest } from 'utilities/api';
import Cookies from 'js-cookie';

export const GET_HUBSPOT_CONTACT = actionComposer('GET_HUBSPOT_CONTACT');
export const getHubspotContact = (utk = Cookies.get('hubspotutk')) => ({
  type: GET_HUBSPOT_CONTACT.type,
  payload: fRequest(
    `https://orders.synthego.com/profile/hubspot-profile/utk/${utk}?&showListMemberships=False&propertyMode=value_only&property=lifecyclestage`,
    {
      method: 'GET',
      excludeCredentials: true,
      excludeHeaders: true
    }
  )
});
