import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByType,
  queryContentStackByUrl
} from 'utilities/contentStack';

export const GET_PRESS_HEADER_LANDING_STACK = actionComposer(
  'GET_PRESS_HEADER_LANDING_STACK'
);
export const getPressHeaderStack = () => ({
  type: GET_PRESS_HEADER_LANDING_STACK.type,
  payload: queryContentStackByUrl('press_landing_page', '/press')
});

export const GET_PRESS_LANDING_POSTS_STACK = actionComposer(
  'GET_PRESS_LANDING_POSTS_STACK'
);
export const getPressPosts = () => ({
  type: GET_PRESS_LANDING_POSTS_STACK.type,
  payload: queryContentStackByType('press_release_page')
});

export const GET_PRESS_LANDING_NEWS_STACK = actionComposer(
  'GET_PRESS_LANDING_NEWS_STACK'
);
export const getNewsPosts = () => ({
  type: GET_PRESS_LANDING_NEWS_STACK.type,
  payload: queryContentStackByType('press_news')
});

export const SET_PRESS_LANDING_POST_COUNT = 'SET_PRESS_LANDING_POST_COUNT';
export const setPostCount = post_count => ({
  type: SET_PRESS_LANDING_POST_COUNT,
  post_post: post_count
});
