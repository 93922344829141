import {
  GET_ADDONS_STACK,
  ADD_ADDON_TO_CART,
  CLEAR_ADDON_CART_ERRORS
} from './actions';

export const getInitialState = () => ({
  stack: {},
  stackLoading: true,
  cartError: false,
  cartErrorMessage: null,
  cartLoading: false,
  redirectToCart: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_ADDONS_STACK.PENDING:
      return {
        ...state,
        stackLoading: true
      };
    case GET_ADDONS_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload[0],
        stackLoading: false
      };
    case GET_ADDONS_STACK.REJECTED:
      return {
        ...state,
        stackLoading: false
      };
    case ADD_ADDON_TO_CART.PENDING:
      return {
        ...state,
        cartLoading: true,
        cartError: false
      };
    case ADD_ADDON_TO_CART.FULFILLED:
      return {
        ...state,
        cartLoading: false,
        cartError: false,
        redirectToCart: true
      };
    case ADD_ADDON_TO_CART.REJECTED:
      return {
        ...state,
        cartLoading: false,
        cartError: true,
        cartErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload
      };
    case CLEAR_ADDON_CART_ERRORS:
      return {
        ...state,
        cartError: false,
        cartErrorMessage: null,
        redirectToCart: false
      };
    default:
      return state;
  }
};
