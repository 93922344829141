import _ from 'lodash';
import Cookies from 'js-cookie';

export const JSON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

export const FORM_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded'
};

// Wrapper to remove boiler plate around api requests, returns a fetch promise
// Please expand functionality as needed
// Look at Hook's utilities/api.js for inspiration on extending
export const fRequest = (url, options = {}) => {
  const method = options.method ? options.method : 'GET';
  const django_csrf_headers = {
    'X-CSRFToken': Cookies.get('apicsrftoken')
  };
  var params = {
    method: method,
    credentials: options.excludeCredentials ? undefined : 'include'
  };
  if (options.data instanceof FormData) {
    params.body = options.data;
    params.headers = { ...django_csrf_headers };
  } else {
    params.body = options.data ? JSON.stringify(options.data) : null;
    if (options.excludeHeaders) {
      params.headers = undefined;
    } else {
      params.headers = { ...JSON_HEADERS, ...django_csrf_headers };
    }
  }
  return window.fetch(url, params).then(response => {
    // We only want to json-ify if JSON is the header
    const contentType = response.headers.get('content-type');
    const isJSONResponse =
      contentType && contentType.includes('application/json');
    if (response.status >= 400) {
      return response[isJSONResponse ? 'json' : 'text']().then(errorResp => {
        return Promise.reject(errorResp);
      });
    }
    return isJSONResponse ? response.json() : response.text();
  });
};

export const injectSignedoutErrorCode = response => {
  if (_.isObject(response)) {
    if (response.detail === 'Authentication credentials were not provided.') {
      if (!_.has(response, 'error_code')) {
        response['error_code'] = 'signed_out';
      }
    }
  }
  return response;
};
