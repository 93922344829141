import { GET_PRODUCT_TAXONOMY } from './actions';

export const getInitialState = () => ({
  loading: false,
  products: []
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PRODUCT_TAXONOMY.PENDING:
      return {
        ...state,
        loading: true,
        products: action.payload
      };
    case GET_PRODUCT_TAXONOMY.FULFILLED:
      return {
        ...state,
        loading: false,
        products: action.payload
      };
    case GET_PRODUCT_TAXONOMY.REJECTED:
      return {
        ...state,
        loading: false,
        products: action.payload
      };
    default:
      return state;
  }
};
