import { EXTENDED_FOOTER_GET_DATA } from 'features/layout/actions';

export const getInitialState = () => ({
  extended_footers: {}
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case EXTENDED_FOOTER_GET_DATA.FULFILLED:
      return {
        ...state,
        extended_footers: {
          ...state.extended_footers,
          [action.payload.uid]: action.payload
        }
      };
    default:
      return state;
  }
};
