import { GET_QCOLIGO_DOC_DATA } from 'features/reports/actions';

const getInitialState = () => ({
  qc_doc: {
    line_item_data: null,
    toc: null
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_QCOLIGO_DOC_DATA.FULFILLED:
      return {
        ...state,
        qc_doc: action.payload.filter(doc => doc.active)
      };
    default:
      return state;
  }
};
