import {
  GET_EXPERIENCE_DETAIL_STACK,
  SET_ACTIVE_SECTION,
  SET_NAV_COLLAPSED
} from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    settings: {},
    modular_blocks: []
  },
  activeSection: 'landing',
  navCollapsed: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_EXPERIENCE_DETAIL_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_EXPERIENCE_DETAIL_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_EXPERIENCE_DETAIL_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.value
      };
    case SET_NAV_COLLAPSED:
      return {
        ...state,
        navCollapsed: action.value
      };
    default:
      return state;
  }
};
