// Genome options, rack options and control labels get stored in the users localstorage once they start a project.
// If you change any of their properties increase the version, users will be prompted
// to download their project and start over so they don't have outdated information stored.
export const version = 5;

export const orderRequirements = {
  maxProjectPools: 360,
  minProjectPools: 20,
  minProjectCopies: 1,
  maxProjectCopies: 4,
  minControls: {
    ntc1: 0,
    ntc2: 0,
    mockWT: 2
  },
  maxControls: {
    ntc1: 12,
    ntc2: 12,
    mockWT: 32
  }
};

export const defaultPool = {
  value: '',
  id: null,
  type: null,
  errors: [],
  warnings: [],
  ensemblId: null,
  numGuides: null,
  geneOffTargets: [],
  commonEssential: null
};

export const genomeOptions = [
  {
    label: 'Homo sapiens',
    caption: 'Gencode Release 26 (GRCh38.p10)',
    commonName: 'human',
    slug: 'homo_sapiens'
  },
  {
    label: 'Mus musculus',
    caption: 'Gencode Release M21 (GRCm38.p6)',
    commonName: 'mouse',
    slug: 'mus_musculus'
  }
];

export const rackOptions = [
  {
    slug: '96-pool',
    poolCount: 96,
    poolRows: 8,
    poolColumns: 12
  }
];

// Controls that user cannot change quantity of: excludes mock WT
export const staticControlPools = {
  homo_sapiens: [
    {
      value: 'TRAC',
      type: 'control'
    },
    {
      value: 'RELA',
      type: 'control'
    },
    {
      value: 'IFNGR1',
      type: 'control'
    },
    {
      value: 'Viability',
      type: 'control',
      sacrificed: true
    }
  ],
  mus_musculus: [
    {
      value: 'Il1r1',
      type: 'control'
    },
    {
      value: 'Keap1',
      type: 'control'
    },
    {
      value: 'C3',
      type: 'control'
    },
    {
      value: 'Viability',
      type: 'control',
      sacrificed: true
    }
  ]
};

export const warningColor = '#ffca28';

export const ERRORS = {
  GENE_NOT_FOUND: 'GENE_NOT_FOUND',
  ZERO_GUIDES: 'ZERO_GUIDES'
};

export const WARNINGS = {
  FEWER_THAN_THREE_GUIDES: 'FEWER_THAN_THREE_GUIDES',
  ESSENTIAL_GENE: 'ESSENTIAL_GENE',
  PARALOGS_FOUND: 'PARALOGS_FOUND'
};
