import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_applyCustomerReferenceCode = (
  orderToken,
  customerReferenceCode
) =>
  fRequest(
    `${config.buckRootUrl}/api/order/${orderToken}/apply-customer-reference-code/`,
    {
      method: 'POST',
      data: { customer_reference_code: customerReferenceCode }
    }
  );

export const api_removeCustomerReferenceCode = orderToken =>
  fRequest(
    `${config.buckRootUrl}/api/order/${orderToken}/remove-customer-reference-code/`,
    {
      method: 'POST'
    }
  );
