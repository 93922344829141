import { GET_CONTENT_STACK_DATA } from 'features/welcome/actions';

export const getInitialState = () => ({
  stack: {
    feature_link: {},
    header: {
      headline: '',
      subheadline: '',
      ctas: [],
      background_type: '',
      background_video: {
        poster: {
          url: ''
        },
        video_files: []
      },
      background_lottie_animation: {
        animation_data: ''
      }
    },
    sections: [],
    definition: {
      headline: '',
      syllables: '',
      noun: '',
      audio_icon: {
        url: ''
      },
      audio: {},
      body: ''
    },
    order: {},
    footer_ctas: {
      title: '',
      blog_reference: [],
      resource_reference: [],
      form: {},
      learn_more: {
        icon: {
          url: ''
        },
        button_label: ''
      }
    }
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_CONTENT_STACK_DATA.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_CONTENT_STACK_DATA.FULFILLED:
      return {
        loading: false,
        stack: action.payload
      };
    case GET_CONTENT_STACK_DATA.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
