import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_refillEditCredit = amount =>
  fRequest(`${config.buckRootUrl}/api/user/edit_credit/refill/`, {
    method: 'POST',
    data: { amount: amount }
  });

export const api_buyEditCredit = (amount, type) =>
  fRequest(`${config.buckRootUrl}/api/user/edit_credit/buy/`, {
    method: 'POST',
    data: { amount: amount, credit_type: type }
  });
