export default {
  ORDER: {
    CELLGKO_CLONE: '/order/engineered-cells/crispr-knockout-cell-clone',
    CELLGKO_POOL: '/order/engineered-cells/crispr-knockout-cell-pool'
  },
  URL_TO_CONTENT_TYPE: {
    '/order/engineered-cells/crispr-knockout-cell-clone':
      'order_knockout_cell_clone',
    '/order/engineered-cells/crispr-knockout-cell-pool': 'order_cell_gko'
  }
};
