import _ from 'lodash';

import {
  GET_ORDER_DETAILS,
  GET_ORDER_SHIPPING_ESTIMATES,
  UPDATE_ORDER_ADDRESS,
  VALIDATE_ADDRESS,
  UPDATE_ORDER_SHIPPING_METHOD,
  SET_ACTIVE_STEP
} from './actions';

const getFirstIncompleteStep = order => {
  if (!_.isEmpty(order)) {
    if (_.get(order, 'groups[0].shipping_method_set')) {
      return null;
    }
    if (order.billing_address) {
      return 2;
    }
    if (order.shipping_address) {
      return 1;
    }
    return 0;
  } else {
    return null;
  }
};

export const getInitialState = () => ({
  order: {},
  shippingEstimates: {},
  activeStep: null,
  allStepsComplete: false,
  orderLoading: false,
  estimatesLoading: false,
  addressResponse: {},
  addressValidation: {},
  addressError: false,
  addressLoading: false,
  methodResponse: {},
  methodError: false,
  methodLoading: false,
  error: false,
  orderShouldUpdate: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_ORDER_DETAILS.PENDING:
      return {
        ...state,
        orderLoading: true
      };
    case GET_ORDER_DETAILS.FULFILLED:
      return {
        ...state,
        order: action.payload,
        activeStep: getFirstIncompleteStep(action.payload),
        allStepsComplete:
          Boolean(action.payload.shipping_address) &&
          Boolean(action.payload.billing_address) &&
          Boolean(_.get(action.payload, 'groups[0].shipping_method_set')),
        orderLoading: false,
        orderShouldUpdate: false
      };
    case GET_ORDER_DETAILS.REJECTED:
      return {
        ...state,
        error: action.payload,
        orderLoading: false,
        orderShouldUpdate: false
      };
    case GET_ORDER_SHIPPING_ESTIMATES.PENDING:
      return {
        ...state,
        estimatesLoading: true
      };
    case GET_ORDER_SHIPPING_ESTIMATES.FULFILLED:
      return {
        ...state,
        shippingEstimates: action.payload,
        estimatesLoading: false
      };
    case GET_ORDER_SHIPPING_ESTIMATES.REJECTED:
      return {
        ...state,
        error: action.payload,
        estimatesLoading: false
      };
    case UPDATE_ORDER_ADDRESS.PENDING:
      return {
        ...state,
        addressLoading: true
      };
    case UPDATE_ORDER_ADDRESS.FULFILLED:
      return {
        ...state,
        addressError: false,
        addressResponse: action.payload,
        addressLoading: false,
        orderShouldUpdate: true
      };
    case UPDATE_ORDER_ADDRESS.REJECTED:
      return {
        ...state,
        addressResponse: {},
        addressError: true,
        addressValidation: { errors: action.payload.address },
        addressLoading: false
      };
    case UPDATE_ORDER_SHIPPING_METHOD.PENDING:
      return {
        ...state,
        methodError: false,
        methodLoading: true
      };
    case UPDATE_ORDER_SHIPPING_METHOD.FULFILLED:
      return {
        ...state,
        methodResponse: action.payload,
        methodError: false,
        methodLoading: false,
        orderShouldUpdate: true
      };
    case UPDATE_ORDER_SHIPPING_METHOD.REJECTED:
      return {
        ...state,
        methodError: action.payload,
        methodLoading: false
      };
    case VALIDATE_ADDRESS.FULFILLED:
      return {
        ...state,
        addressError: false,
        addressValidation: action.payload
      };
    case VALIDATE_ADDRESS.REJECTED:
      return {
        ...state,
        addressError: true,
        addressValidation: action.payload
      };
    case SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.step
      };
    default:
      return state;
  }
};
