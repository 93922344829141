import { GET_COLLECTIONS_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  collections: []
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_COLLECTIONS_STACK.FULFILLED:
      return {
        ...state,
        collections: action.payload,
        loading: false
      };
    case GET_COLLECTIONS_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_COLLECTIONS_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
