import _ from 'lodash';
import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByUrl,
  queryContentStackById,
  queryContentStackByType
} from 'utilities/contentStack';

export const GET_GUIDED_EDIT_LANDING_STACK = actionComposer(
  'GET_GUIDED_EDIT_LANDING_STACK'
);
export const getGuidedEditLandingStack = () => ({
  type: GET_GUIDED_EDIT_LANDING_STACK.type,
  payload: queryContentStackByUrl('guided_edit_landing', '/guided-edit')
});

export const GET_GUIDED_EDIT_QUESTION_STACK = actionComposer(
  'GET_GUIDED_EDIT_QUESTION_STACK'
);
export const getGuidedEditQuestionStack = id => ({
  type: GET_GUIDED_EDIT_QUESTION_STACK.type,
  payload: queryContentStackById('guided_edit_question', id, {
    includedReferences: ['choices.to']
  })
});

export const CLEAR_GUIDED_EDIT_QUESTION_STACK =
  'CLEAR_GUIDED_EDIT_QUESTION_STACK';

export const clearGuidedEditQuestionStack = () => ({
  type: CLEAR_GUIDED_EDIT_QUESTION_STACK
});

export const CLEAR_GUIDED_EDIT_DESTINATION_STACK =
  'CLEAR_GUIDED_EDIT_DESTINATION_STACK';

export const clearGuidedEditDestinationStack = () => ({
  type: CLEAR_GUIDED_EDIT_DESTINATION_STACK
});

export const GET_GUIDED_EDIT_DESTINATION_STACK = actionComposer(
  'GET_GUIDED_EDIT_DESTINATION_STACK'
);
export const getGuidedEditDestinationStack = id => ({
  type: GET_GUIDED_EDIT_DESTINATION_STACK.type,
  payload: queryContentStackById('guided_edit_destination', id, {
    includedReferences: [
      'product_reference',
      'product_reference.family',
      'resources.list.reference'
    ]
  })
});

export const SAVE_GUIDED_EDIT_CHOICE = 'SAVE_GUIDED_EDIT_CHOICE';
export const saveGuidedEditChoice = choice => {
  let reducedChoice = _.pick(
    choice,
    'short_text',
    'to[0].uid',
    'to[0]._content_type_uid'
  );
  let guidedEditChoices = JSON.parse(
    localStorage.getItem('guidedEditChoices') || '[]'
  );
  guidedEditChoices.push(reducedChoice);
  localStorage.setItem('guidedEditChoices', JSON.stringify(guidedEditChoices));

  return {
    type: SAVE_GUIDED_EDIT_CHOICE,
    payload: guidedEditChoices
  };
};

export const REPLACE_GUIDED_EDIT_CHOICES = 'REPLACE_GUIDED_EDIT_CHOICES';
export const replaceGuidedEditChoices = choices => {
  localStorage.setItem('guidedEditChoices', JSON.stringify(choices));

  return {
    type: REPLACE_GUIDED_EDIT_CHOICES,
    payload: choices
  };
};

export const GET_GUIDED_EDIT_CHOICES = 'GET_GUIDED_EDIT_CHOICES';
export const getGuidedEditChoices = () => {
  const guidedEditChoices = JSON.parse(
    localStorage.getItem('guidedEditChoices') || '[]'
  );
  return {
    type: GET_GUIDED_EDIT_CHOICES,
    payload: guidedEditChoices
  };
};

export const CLEAR_GUIDED_EDIT_CHOICES = 'GET_GUIDED_EDIT_CHOICES';
export const clearGuidedEditChoices = () => {
  localStorage.setItem('guidedEditChoices', []);
  return {
    type: CLEAR_GUIDED_EDIT_CHOICES,
    payload: []
  };
};

export const RESET_GUIDED_EDIT = 'RESET_GUIDED_EDIT';
export const resetGuidedEdit = () => {
  localStorage.setItem('guidedEditChoices', []);
  return {
    type: RESET_GUIDED_EDIT
  };
};

export const TOGGLE_ZORK_MODE = 'TOGGLE_ZORK_MODE';
export const toggleZorkMode = () => {
  return {
    type: TOGGLE_ZORK_MODE
  };
};

export const GET_GUIDED_EDIT_ALL_QUESTIONS = actionComposer(
  'GET_GUIDED_EDIT_ALL_QUESTIONS'
);
export const getGuidedEditAllQuestions = () => ({
  type: GET_GUIDED_EDIT_ALL_QUESTIONS.type,
  payload: queryContentStackByType('guided_edit_question')
});

export const GET_GUIDED_EDIT_ALL_DESTINATIONS = actionComposer(
  'GET_GUIDED_EDIT_ALL_DESTINATIONS'
);
export const getGuidedEditAllDestinations = () => ({
  type: GET_GUIDED_EDIT_ALL_DESTINATIONS.type,
  payload: queryContentStackByType('guided_edit_destination')
});
