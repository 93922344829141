import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_TEAM_STACK = actionComposer('GET_TEAM_STACK');
export const getTeamStack = () => ({
  type: GET_TEAM_STACK.type,
  payload: queryContentStackByUrl('team', '/team', {
    includedReferences: ['groups.members', 'top_cta', 'cta']
  })
});
