import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PRODUCT_PRICING_STACK = actionComposer(
  'GET_PRODUCT_PRICING_STACK'
);
export const getProductPricingStack = url => ({
  type: GET_PRODUCT_PRICING_STACK.type,
  payload: queryContentStackByUrl('pricing', url, {
    includedReferences: [
      'products.product_reference',
      'quote',
      'resource_reference',
      'blog_reference'
    ]
  })
});
