import actionComposer from 'utilities/actionComposer';
import { queryContentStackById } from 'utilities/contentStack';

export const GET_FOOTER_STACK_DATA = actionComposer('GET_FOOTER_STACK_DATA');
export const getFooterStackData = () => ({
  type: GET_FOOTER_STACK_DATA.type,
  payload: queryContentStackById('footer', 'blt0e32e3ff5063e13b')
});

export const SET_FOOTER_MINIMAL = 'SET_FOOTER_MINIMAL';
export const setFooterMinimal = value => ({
  type: SET_FOOTER_MINIMAL,
  minimal: value
});

export const SET_FOOTER_HIDDEN = 'SET_FOOTER_HIDDEN';
export const setFooterHidden = value => ({
  type: SET_FOOTER_HIDDEN,
  hidden: value
});
