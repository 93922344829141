import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PRODUCT_FAMILY_STACK = actionComposer(
  'GET_PRODUCT_FAMILY_STACK'
);
export const getProductFamilyStack = url => ({
  type: GET_PRODUCT_FAMILY_STACK.type,
  payload: queryContentStackByUrl('product_family_pages', url, {
    includedReferences: [
      'quote',
      'product.reference',
      'product.enhanced_reference'
    ]
  })
});
