import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByType,
  queryContentStackByTypeWithCount
} from 'utilities/contentStack';
import { ARTICLES_PER_PAGE } from './constants';

export const GET_HELP_ARTICLES_STACK = actionComposer(
  'GET_HELP_ARTICLES_STACK'
);
export const LOAD_MORE_HELP_ARTICLES_STACK = actionComposer(
  'LOAD_MORE_HELP_ARTICLES_STACK'
);

export const getHelpArticles = (options = {}) => ({
  type: options.append
    ? LOAD_MORE_HELP_ARTICLES_STACK.type
    : GET_HELP_ARTICLES_STACK.type,
  payload: queryContentStackByTypeWithCount('help_article', {
    includedReferences: ['categories', 'categories_tags.product'],
    limit: options.limit || ARTICLES_PER_PAGE,
    skip: options.skip || 0,
    query: options.filter || options.query,
    ascending: 'title'
  })
});

export const GET_UNFILTERED_HELP_ARTICLES_STACK = actionComposer(
  'GET_UNFILTERED_HELP_ARTICLES_STACK'
);

export const getUnfilteredHelpArticles = slug => ({
  type: GET_UNFILTERED_HELP_ARTICLES_STACK.type,
  payload: queryContentStackByTypeWithCount('help_article', {
    includedReferences: ['categories', 'categories_tags.product'],
    query: { categories: { $in_query: { slug: slug } } },
    limit: 100,
    descending: 'categories_tags.product.uid'
  })
});

export const SET_HELP_ARTICLE_COUNT = 'SET_HELP_ARTICLE_COUNT';
export const setArticleCount = count => ({
  type: SET_HELP_ARTICLE_COUNT,
  article_count: count
});

export const GET_HELP_CATEGORY = actionComposer('GET_HELP_CATEGORY');
export const getHelpCategory = slug => ({
  type: GET_HELP_CATEGORY.type,
  payload: queryContentStackByType('help_category', { query: { slug: slug } })
});

export const GET_HELP_CATEGORIES = actionComposer('GET_HELP_CATEGORIES');
export const getHelpCategories = () => ({
  type: GET_HELP_CATEGORIES.type,
  payload: queryContentStackByType('help_category', {
    ascending: 'title'
  })
});
