import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';

export const GET_COLLECTIONS_STACK = actionComposer('GET_COLLECTIONS_STACK');

export const getBlogCollections = () => ({
  type: GET_COLLECTIONS_STACK.type,
  payload: queryContentStackByType('blog_collection', {
    includedReferences: ['posts']
  })
});
