import { GET_APPLICATIONS_DETAIL_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    uid: false
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_APPLICATIONS_DETAIL_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_APPLICATIONS_DETAIL_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_APPLICATIONS_DETAIL_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
