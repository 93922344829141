import actionComposer from 'utilities/actionComposer';
import { api_applyOrderCoupon, api_removeOrderCoupon } from './api';

export const APPLY_ORDER_COUPON = actionComposer('APPLY_ORDER_COUPON');
export const applyOrderCoupon = (orderToken, couponCode) => ({
  type: APPLY_ORDER_COUPON.type,
  payload: api_applyOrderCoupon(orderToken, couponCode)
});

export const REMOVE_ORDER_COUPON = actionComposer('REMOVE_ORDER_COUPON');
export const removeOrderCoupon = orderToken => ({
  type: REMOVE_ORDER_COUPON.type,
  payload: api_removeOrderCoupon(orderToken)
});
