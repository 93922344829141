import actionComposer from 'utilities/actionComposer';
import { queryContentStackById } from 'utilities/contentStack';

export const EXTENDED_FOOTER_GET_DATA = actionComposer(
  'EXTENDED_FOOTER_GET_DATA'
);
export const getLayoutContentStackData = (content_type, content_id) => ({
  type: EXTENDED_FOOTER_GET_DATA.type,
  payload: queryContentStackById(content_type, content_id),
  content_id: content_id
});
