import { GET_ORDER_DETAILS } from './actions';

export const getInitialState = () => ({
  order: {
    id: false,
    status: 0,
    groups: []
  },
  loading: false,
  error: null
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_ORDER_DETAILS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_ORDER_DETAILS.FULFILLED:
      return {
        ...state,
        order: action.payload,
        loading: false
      };
    case GET_ORDER_DETAILS.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
