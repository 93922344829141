import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByTypeWithCount,
  queryContentStackByUrl
} from 'utilities/contentStack';

import { RESOURCES_PER_PAGE } from 'features/resources/constants';

export const GET_RESOURCES_LANDING_STACK = actionComposer(
  'GET_RESOURCES_LANDING_STACK'
);
export const getResourcesLandingStack = () => ({
  type: GET_RESOURCES_LANDING_STACK.type,
  payload: queryContentStackByUrl('resources_main', '/resources', {
    includedReferences: ['category_filters']
  })
});

export const GET_RESOURCES_LANDING_RESOURCES_STACK = actionComposer(
  'GET_RESOURCES_LANDING_RESOURCES_STACK'
);
export const LOAD_MORE_RESOURCES_LANDING_RESOURCES_STACK = actionComposer(
  'LOAD_MORE_RESOURCES_LANDING_RESOURCES_STACK'
);

export const getResources = (options = {}) => ({
  type: options.append
    ? LOAD_MORE_RESOURCES_LANDING_RESOURCES_STACK.type
    : GET_RESOURCES_LANDING_RESOURCES_STACK.type,
  payload: queryContentStackByTypeWithCount('resources_landing_page', {
    includedReferences: ['resource_category'],
    limit: options.limit || RESOURCES_PER_PAGE,
    skip: options.skip || 0,
    query: options.filter || options.query,
    notEqualTo: { key: 'unlisted', value: true },
    descending: 'updated_at'
  })
});

export const SET_RESOURCES_LANDING_RESOURCE_COUNT =
  'SET_RESOURCES_LANDING_RESOURCE_COUNT';
export const setResourceCount = resource_count => ({
  type: SET_RESOURCES_LANDING_RESOURCE_COUNT,
  resource_count: resource_count
});
