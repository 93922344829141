import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_AddToBuckCart = gkoOrder => {
  let gkoOrderForCart = [];

  gkoOrderForCart.push({
    genome_name: gkoOrder.genome?.label,
    ensembl_id: gkoOrder.gene?.ensembl_id,
    gene_name: gkoOrder.gene?.gene_symbol,
    cell_line: gkoOrder.cellLine?.customValue
      ? `Other: ${gkoOrder.cellLine?.name}`
      : gkoOrder.cellLine?.name,
    product_slug: gkoOrder.genome?.product_slug,
    quantity: gkoOrder.quantity,
    quantity_unit: 'nmol'
  });

  if (gkoOrder.addOn?.cartData) {
    gkoOrderForCart.push(gkoOrder.addOn?.cartData);
  }

  console.log(gkoOrderForCart);

  return fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: gkoOrderForCart
  });
};

export const api_GetGKOGenes = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/ecl/genes/?species=${species}`,
    {
      method: 'GET'
    }
  );
};

export const api_GetGKOCellLines = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/cell-lines/?species=${species}`,
    {
      method: 'GET'
    }
  );
};

export const api_GetProductPricing = () =>
  fRequest(`${config.buckRootUrl}/api/products/options`, {
    method: 'GET'
  });
