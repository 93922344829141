import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';
import { fRequest } from 'utilities/api';

export const GET_CAREERS_STACK = actionComposer('GET_CAREERS_STACK');
export const getCareersStack = url => ({
  type: GET_CAREERS_STACK.type,
  payload: queryContentStackByUrl('careers', '/careers', {
    includedReferences: ['quotes']
  })
});

export const GET_LEVER_JOBS = actionComposer('GET_LEVER_JOBS');
export const getLeverJobs = () => ({
  type: GET_LEVER_JOBS.type,
  payload: fRequest(
    'https://api.lever.co/v0/postings/synthego?mode=json&group=team',
    {
      method: 'GET',
      excludeCredentials: true,
      excludeHeaders: true
    }
  )
});
