import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PUBLICATIONS_LANDING_STACK = actionComposer(
  'GET_PUBLICATIONS_LANDING_STACK'
);
export const getPublicationsLandingStack = () => ({
  type: GET_PUBLICATIONS_LANDING_STACK.type,
  payload: queryContentStackByUrl('publications', '/publications')
});
