import {
  GET_HELP_ARTICLES_STACK,
  LOAD_MORE_HELP_ARTICLES_STACK,
  GET_UNFILTERED_HELP_ARTICLES_STACK,
  SET_HELP_ARTICLE_COUNT,
  GET_HELP_CATEGORY,
  GET_HELP_CATEGORIES
} from './actions';
import { ARTICLES_PER_PAGE } from './constants';

export const getInitialState = () => ({
  stack: null,
  articles: [],
  total_article_count: 0,
  article_count: ARTICLES_PER_PAGE,
  category: '',
  categories: [],
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_HELP_ARTICLES_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_HELP_ARTICLES_STACK.FULFILLED:
      return {
        ...state,
        unfiltered_articles: action.unfiltered_payload,
        articles: action.payload[0],
        article_count: action.payload[0].length,
        total_article_count: action.payload[1],
        loading: false
      };
    case GET_HELP_ARTICLES_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case LOAD_MORE_HELP_ARTICLES_STACK.FULFILLED:
      return {
        ...state,
        articles: [...state.articles, ...action.payload[0]],
        article_count: state.articles.length + action.payload[0].length
      };
    case SET_HELP_ARTICLE_COUNT:
      return {
        ...state,
        article_count: action.article_count
      };
    case GET_HELP_CATEGORY.FULFILLED:
      return {
        ...state,
        category: action.payload[0]
      };

    case GET_UNFILTERED_HELP_ARTICLES_STACK.FULFILLED:
      return {
        ...state,
        all_articles: action.payload[0]
      };
    case GET_HELP_CATEGORIES.FULFILLED:
      return {
        ...state,
        categories: action.payload
      };
    default:
      return state;
  }
};
