import { REDIRECT_404 } from './actions';

export const getInitialState = () => ({});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case REDIRECT_404:
      window.location = action.redirectUrl;
      return state;

    default:
      return state;
  }
};
