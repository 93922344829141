import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';
import { api_AddToBuckCart } from './api';

export const GET_ADDONS_STACK = actionComposer('GET_ADDONS_STACK');
export const getAddonsStack = () => ({
  type: GET_ADDONS_STACK.type,
  payload: queryContentStackByType('order_add_ons', {
    includedReferences: ['categories.add_ons']
  })
});

export const ADD_ADDON_TO_CART = actionComposer('ADD_ADDON_TO_CART');
export const addAddOnToCart = (productSlug, quantity) => ({
  type: ADD_ADDON_TO_CART.type,
  payload: api_AddToBuckCart(productSlug, quantity)
});

export const CLEAR_ADDON_CART_ERRORS = 'CLEAR_ADDON_CART_ERRORS';
export const clearCartErrors = () => {
  return {
    type: CLEAR_ADDON_CART_ERRORS
  };
};
