import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_stripePayment = orderToken =>
  fRequest(`${config.buckRootUrl}/api/order/${orderToken}/payment/stripe/`, {
    method: 'POST'
  });

export const api_purchaseOrderPayment = (orderToken, formData) =>
  fRequest(
    `${config.buckRootUrl}/api/order/${orderToken}/payment/purchase_order/`,
    {
      method: 'POST',
      data: formData
    }
  );

export const api_blanketPurchaseOrderPayment = orderToken =>
  fRequest(
    `${config.buckRootUrl}/api/order/${orderToken}/payment/blanket_purchase_order/`,
    {
      method: 'POST'
    }
  );

export const api_editCreditPayment = orderToken =>
  fRequest(
    `${config.buckRootUrl}/api/order/${orderToken}/payment/edit_credit/`,
    {
      method: 'POST',
      data: {}
    }
  );

export const api_confirmOrder = orderToken =>
  fRequest(`${config.buckRootUrl}/api/order/${orderToken}/payment/confirm/`, {
    method: 'POST'
  });
