import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class ScrollToTop extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (this.props.scrollEnabled) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return null;
  }
}

export default connect(state => ({
  scrollEnabled: state.scrolltotop.enabled
}))(withRouter(ScrollToTop));
