import {
  GET_RESOURCES_LANDING_STACK,
  GET_RESOURCES_LANDING_RESOURCES_STACK,
  LOAD_MORE_RESOURCES_LANDING_RESOURCES_STACK,
  SET_RESOURCES_LANDING_RESOURCE_COUNT
} from './actions';
import { RESOURCES_PER_PAGE } from 'features/resources/constants';

export const getInitialState = () => ({
  loading: false,
  firstLoadComplete: false,
  resources: [],
  total_resource_count: 0,
  resource_count: RESOURCES_PER_PAGE,
  stack: {
    category_filters: [],
    description: '',
    title: ''
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_RESOURCES_LANDING_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        firstLoadComplete: true
      };
    case GET_RESOURCES_LANDING_RESOURCES_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_RESOURCES_LANDING_RESOURCES_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_RESOURCES_LANDING_RESOURCES_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        resources: action.payload[0],
        resource_count: action.payload[0].length,
        total_resource_count: action.payload[1]
      };
    case LOAD_MORE_RESOURCES_LANDING_RESOURCES_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        resources: [...state.resources, ...action.payload[0]],
        resource_count: state.resources.length + action.payload[0].length
      };
    case SET_RESOURCES_LANDING_RESOURCE_COUNT:
      return {
        ...state,
        resource_count: action.resource_count
      };
    default:
      return state;
  }
};
