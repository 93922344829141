import {
  GET_CONTACT_DETAIL_STACK,
  GET_HELP_CATEGORIES,
  GET_HELP_ARTICLES
} from './actions';

export const getInitialState = () => ({
  loading: false,
  articlesLoading: false,
  stack: {
    headline: '',
    description: '',
    form_id: ''
  },
  helpCategories: [],
  helpArticles: []
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_CONTACT_DETAIL_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_CONTACT_DETAIL_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_CONTACT_DETAIL_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_HELP_CATEGORIES.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_HELP_CATEGORIES.FULFILLED:
      return {
        ...state,
        loading: false,
        helpCategories: action.payload
      };
    case GET_HELP_CATEGORIES.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_HELP_ARTICLES.PENDING:
      return {
        ...state,
        articlesLoading: true
      };
    case GET_HELP_ARTICLES.FULFILLED:
      return {
        ...state,
        articlesLoading: false,
        helpArticles: action.payload
      };
    case GET_HELP_ARTICLES.REJECTED:
      return {
        ...state,
        articlesLoading: false
      };
    default:
      return state;
  }
};
