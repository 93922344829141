import { GET_EVENTS_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    title: '',
    url: '',
    type: [
      {
        color: '',
        image: {
          url: ''
        }
      }
    ],
    topic: [
      {
        image: {
          url: ''
        },
        content: {
          lead: '',
          body: ''
        }
      }
    ],
    location: {
      name: ''
    },
    content: {
      background_image: {
        url: ''
      },
      headline: '',
      subheadline: '',
      lead: '',
      body: ''
    }
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_EVENTS_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_EVENTS_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_EVENTS_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
