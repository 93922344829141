import { GET_HUBSPOT_CONTACT } from './actions';

export const getInitialState = () => ({
  hubspotContact: {}
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_HUBSPOT_CONTACT.FULFILLED:
      return {
        ...state,
        hubspotContact: action.payload
      };
    default:
      return state;
  }
};
