import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_BLOG_BASE_STACK = actionComposer('GET_BLOG_BASE_STACK');

export const getBlogBaseStack = () => ({
  type: GET_BLOG_BASE_STACK.type,
  payload: queryContentStackByUrl('blog', '/blog', {
    includedReferences: [
      'featured_posts.primary_post',
      'featured_posts.primary_post.author',
      'featured_posts.primary_post.blog_category',
      'featured_posts.secondary_posts',
      'featured_categories',
      'featured_items',
      'footer.cta'
    ]
  })
});
