import actionComposer from 'utilities/actionComposer';
import {
  queryContentStackByUrl,
  queryContentStackByType
} from 'utilities/contentStack';

export const GET_CONTACT_DETAIL_STACK = actionComposer(
  'GET_CONTACT_DETAIL_STACK'
);
export const getContactDetailStack = url => ({
  type: GET_CONTACT_DETAIL_STACK.type,
  payload: queryContentStackByUrl('contact_landing', url)
});

export const GET_HELP_CATEGORIES = actionComposer('GET_HELP_CATEGORIES');
export const getHelpCategories = () => ({
  type: GET_HELP_CATEGORIES.type,
  payload: queryContentStackByType('help_category', {
    ascending: 'title'
  })
});

export const GET_HELP_ARTICLES = actionComposer('GET_HELP_ARTICLES');
export const getHelpArticles = (options = {}) => ({
  type: GET_HELP_ARTICLES.type,
  payload: queryContentStackByType('help_article', {
    includedReferences: [
      'categories',
      'categories_tags.product',
      'categories_tags.product.family'
    ],
    limit: 100,
    skip: 0,
    query: options.filter || options.query,
    ascending: 'title'
  })
});
