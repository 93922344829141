import dayjs from 'dayjs';
import _ from 'lodash';
import { Stack as CreateStack } from 'contentstack';
import ContentstackLivePreview from '@contentstack/live-preview-utils';
import config from 'init/config';
import httpErrors from 'constants/HttpError';
import addEditableTags from './addEditableTags';

const Stack = CreateStack(config.contentStackConfig);

ContentstackLivePreview.init({
  enable:
    config.contentStackConfig.environment === 'profiteerprod' ? false : true,
  stackSdk: Stack,
  ssr: false
});

export const onEntryChange = ContentstackLivePreview.onEntryChange;

export const queryContentStackByUrl = (contentType, url, options = {}) => {
  const contentTypeStack = Stack.ContentType(contentType);

  return new Promise((resolve, reject) => {
    // url without trailing slash
    let strippedUrl = url.replace(/\/+$/, '');

    let strippedQuery = contentTypeStack
      .Query()
      .regex('url', `^${strippedUrl}$`, 'i');
    let slashedQuery = contentTypeStack
      .Query()
      .regex('url', `^${strippedUrl}/$`, 'i');
    let combinedQuery = contentTypeStack
      .Query()
      .or(strippedQuery, slashedQuery);

    // included references is an optional array of reference_field_uids
    if (options.includedReferences) {
      combinedQuery.includeReference(options.includedReferences);
    }

    combinedQuery.addParam('include_dimension', 'true');

    options = { redirectOnFail: true, ...options };

    combinedQuery
      .toJSON()
      .find()
      .then(
        result => {
          // write a new function or add an option object if more than one result is expected
          if (result[0][0]) {
            const data = result[0][0];

            //Add body attributes for Contentstack Chrome Plugin
            document.body.dataset.pageref = data.uid;
            document.body.dataset.contenttype = contentType;
            document.body.dataset.locale = data.locale;

            //Add editable tags data for select content types
            if (
              [
                'blog_pages',
                'enhanced_product',
                'landing',
                'case_study_detail'
              ].includes(contentType)
            ) {
              addEditableTags(data, contentType, true);
            }
            return resolve(data);
          } else {
            if (options.redirectOnFail) {
              return reject({
                ...httpErrors.HTTP404,
                error: {
                  message: 'Content Stack Fetch By URL Error',
                  log_data: {
                    requested_stack_info: {
                      url: url,
                      content_type: contentType
                    }
                  }
                }
              });
            }
          }
        },
        err => {
          return reject({
            error: {
              message: 'Content Stack Fetch By URL Error',
              log_data: {
                requested_stack_info: { url: url, content_type: contentType }
              }
            }
          });
        }
      );
  });
};

export const queryContentStackById = (contentType, entryId, options = {}) => {
  const Query = Stack.ContentType(contentType).Entry(entryId);

  // included references is an optional array of reference_field_uids
  if (options.includedReferences) {
    Query.includeReference(options.includedReferences);
  }

  Query.addParam('include_dimension', 'true');

  return new Promise((resolve, reject) => {
    Query.fetch().then(
      result => {
        return resolve(result.toJSON());
      },
      err => {
        return reject({
          error: {
            message: 'Content Stack Fetch By ID Error',
            log_data: {
              requested_stack_info: { id: entryId, content_type: contentType }
            }
          }
        });
      }
    );
  });
};

export const queryContentStackByType = (contentType, options = {}) => {
  const Query = Stack.ContentType(contentType).Query();

  if (options.includedReferences) {
    Query.includeReference(options.includedReferences);
  }

  if (options.limit) {
    Query.limit(options.limit);
  }

  if (options.skip) {
    Query.skip(options.skip);
  }

  if (options.query) {
    Query.query(options.query);
  }

  if (options.notEqualTo) {
    Query.notEqualTo(options.notEqualTo.key, options.notEqualTo.value);
  }

  if (options.notContainedIn) {
    Query.notContainedIn(
      options.notContainedIn.key,
      options.notContainedIn.value
    );
  }

  if (options.ascending) {
    Query.ascending(options.ascending);
  } else if (options.descending) {
    Query.descending(options.descending);
  } else {
    Query.descending('date');
  }

  Query.addParam('include_dimension', 'true');

  return new Promise((resolve, reject) => {
    Query.toJSON()
      .find()
      .then(
        result => {
          return resolve(result[0]);
        },
        err => {
          return reject({
            error: {
              message: 'Content Stack Fetch By Type Error',
              log_data: { requested_stack_info: { content_type: contentType } }
            }
          });
        }
      );
  });
};

export const queryContentStackByTypeWithCount = (contentType, options = {}) => {
  const Query = Stack.ContentType(contentType).Query();

  if (options.includedReferences) {
    Query.includeReference(options.includedReferences);
  }

  if (options.limit) {
    Query.limit(options.limit);
  }

  if (options.skip) {
    Query.skip(options.skip);
  }

  if (options.query) {
    Query.query(options.query);
  }

  if (options.notEqualTo) {
    Query.notEqualTo(options.notEqualTo.key, options.notEqualTo.value);
  }

  if (options.ascending) {
    Query.ascending(options.ascending);
  } else if (options.descending) {
    Query.descending(options.descending);
  } else {
    Query.descending('date');
  }

  return new Promise((resolve, reject) => {
    Query.includeCount(true)
      .toJSON()
      .find()
      .then(
        result => {
          return resolve(result);
        },
        err => {
          return reject({
            error: {
              message: 'Content Stack Fetch By Type Error',
              log_data: { requested_stack_info: { content_type: contentType } }
            }
          });
        }
      );
  });
};

const getAnswerFieldType = (contentStack, question_id) => {
  var answerFieldType = null;
  _.each(contentStack.sections, function(section) {
    _.each(section.form_fields, function(question) {
      if (question.field_id === question_id) {
        answerFieldType = question.type;
      }
    });
  });
  return answerFieldType;
};

export const serializeContentStackFormAnswers = (contentStack, answers) => {
  // Use to submit ContentStack Form answers to buckaneer backend
  let form_data = { ...answers };
  for (let question_id in form_data) {
    let question_type = getAnswerFieldType(contentStack, question_id);
    if (question_type === 'date') {
      form_data[question_id] = dayjs(form_data[question_id]).format();
    }
    if (Array.isArray(form_data[question_id])) {
      form_data[question_id] = form_data[question_id].join();
    }
    if (form_data[question_id] === true || form_data[question_id] === false) {
      form_data[question_id] = form_data[question_id] ? 'Yes' : 'No';
    }
  }
  for (let question_id in form_data) {
    if (question_id.endsWith('.extra')) {
      let parent_question_id = question_id.substr(0, question_id.length - 6);
      if (form_data[parent_question_id]) {
        form_data[
          parent_question_id
        ] = `${form_data[parent_question_id]},${form_data[question_id]}`;
        delete form_data[question_id];
      }
    }
  }
  // delete irrelevant keys which may have been introduced by toggling between forms
  var contentStackFieldIds = _.flatMap(contentStack.sections, function(
    section
  ) {
    return _.flatMap(section.form_fields, function(question) {
      if (question.type === 'country_select') {
        return [
          question.field_id,
          question.field_id + '_area',
          question.field_id.replace('country', 'postal_code')
        ];
      } else {
        return question.field_id;
      }
    });
  });
  var keysToDelete = [];
  _.each(form_data, function(value, key) {
    if (!_.includes(contentStackFieldIds, key)) {
      keysToDelete.push(key);
    }
  });
  _.each(keysToDelete, function(key) {
    delete form_data[key];
  });
  /*
  we use dots in form keys to create a nested data structure, if present,
  eg {'a':1, 'b.c': 2} >> {'a': 1, 'b': {'c': 2}}
  */
  var nestedFormData = {};
  for (var key in form_data) {
    if (form_data.hasOwnProperty(key)) {
      _.set(nestedFormData, key, form_data[key]);
    }
  }
  return nestedFormData;
};
