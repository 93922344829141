import { GET_PRODUCT_PRICING_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    title: '',
    icon: {
      url: ''
    },
    description: '',
    products: [],
    pricing: {
      pricing_toggle_label: ''
    },
    cta: [],
    resource_reference: [],
    blog_reference: [],
    quote: []
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PRODUCT_PRICING_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload
      };
    default:
      return state;
  }
};
