import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_APPLICATIONS_STACK = actionComposer('GET_APPLICATIONS_STACK');
export const getApplicationsStack = () => ({
  type: GET_APPLICATIONS_STACK.type,
  payload: queryContentStackByUrl('applications', '/applications', {
    includedReferences: ['list.applications']
  })
});
