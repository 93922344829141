import actionComposer from 'utilities/actionComposer';
import { api_GetProductPricing, api_GetSKUPricing } from './api';

export const GET_PRODUCT_PRICING = actionComposer('GET_PRODUCT_PRICING');
export const getProductPricing = () => ({
  type: GET_PRODUCT_PRICING.type,
  payload: api_GetProductPricing()
});

export const GET_SKU_PRICING = actionComposer('GET_SKU_PRICING');
export const getSKUPricing = query => ({
  type: GET_SKU_PRICING.type,
  payload: api_GetSKUPricing(query)
});
