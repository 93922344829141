import actionComposer from 'utilities/actionComposer';
import { api_refillEditCredit, api_buyEditCredit } from './api';

export const RESET_EDIT_CREDIT_REFILL = 'RESET_EDIT_CREDIT_REFILL';
export const resetEditCreditRefill = () => ({
  type: RESET_EDIT_CREDIT_REFILL
});

export const CREATE_EDIT_CREDIT_REFILL = actionComposer(
  'CREATE_EDIT_CREDIT_REFILL'
);
export const createEditCreditRefill = amount => ({
  type: CREATE_EDIT_CREDIT_REFILL.type,
  payload: api_refillEditCredit(amount)
});

export const BUY_EDIT_CREDIT = actionComposer('BUY_EDIT_CREDIT');
export const buyEditCredit = (amount, type) => ({
  type: BUY_EDIT_CREDIT.type,
  payload: api_buyEditCredit(amount, type)
});
