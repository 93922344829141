import actionComposer from 'utilities/actionComposer';
import { queryContentStackById } from 'utilities/contentStack';
import { identifyAndTrack } from 'utilities/hubspotTracker';
import {
  api_send_email_verification_email,
  api_login,
  api_logout,
  api_create_new_user,
  api_update_user,
  api_send_password_reset,
  api_reset_password
} from 'features/registration/api';
import { updateBuckUser } from 'features/buckaneer/actions';

export const REGISTRATION_SET_ACTION = 'REGISTRATION_SET_ACTION';
export const setRegistrationAction = action => ({
  type: REGISTRATION_SET_ACTION,
  action: action
});

export const REGISTRATION_END_ACTION = 'REGISTRATION_END_ACTION';
export const endAction = () => ({
  type: REGISTRATION_END_ACTION
});

export const REGISTRATION_UPDATE_ACCOUNT = actionComposer(
  'REGISTRATION_UPDATE_ACCOUNT'
);
export const updateAccount = (
  first_name,
  last_name,
  new_email,
  old_email,
  company_name,
  tshirt_size
) => dispatch => {
  if (new_email !== old_email) {
    identifyAndTrack(
      new_email,
      `User changed Email from ${old_email} to ${new_email}`
    );
  }
  dispatch({
    type: REGISTRATION_UPDATE_ACCOUNT.type,
    payload: api_update_user({
      company_name: company_name,
      email: new_email,
      first_name: first_name,
      last_name: last_name,
      tshirt_size: tshirt_size
    }).then(response => {
      if (response.success) {
        dispatch(updateBuckUser());
      }
      return response;
    })
  });
};

export const REGISTRATION_UPDATE_FORM = 'REGISTRATION_UPDATE_FORM';
export const updateForm = data => ({
  type: REGISTRATION_UPDATE_FORM,
  data: data
});

export const REGISTRATION_RESET_FORM = 'REGISTRATION_RESET_FORM';
export const resetForm = () => ({
  type: REGISTRATION_RESET_FORM
});

export const REGISTRATION_SEND_EMAIL_VERIFICATION_LINK = actionComposer(
  'REGISTRATION_SEND_EMAIL_VERIFICATION_LINK'
);
export const sendEmailVerificationLink = () => ({
  type: REGISTRATION_SEND_EMAIL_VERIFICATION_LINK.type,
  payload: api_send_email_verification_email()
});

export const REGISTRATION_RESET_PASSWORD = actionComposer(
  'REGISTRATION_RESET_PASSWORD'
);
export const resetPassword = password => ({
  type: REGISTRATION_RESET_PASSWORD.type,
  payload: api_reset_password({ password: password })
});

export const REGISTRATION_SEND_PASSWORD_RESET = actionComposer(
  'REGISTRATION_SEND_PASSWORD_RESET'
);
export const sendPasswordReset = email => ({
  type: REGISTRATION_SEND_PASSWORD_RESET.type,
  payload: api_send_password_reset({ email: email })
});

export const REGISTRATION_LOGOUT = actionComposer('REGISTRATION_LOGOUT');
export const logout = () => dispatch => ({
  type: REGISTRATION_LOGOUT.type,
  payload: api_logout().then(response => {
    try {
      dispatch(updateBuckUser());
    } catch (err) {
      // ignored
    }
    return response;
  })
});

export const REGISTRATION_LOGIN = actionComposer('REGISTRATION_LOGIN');
export const login = (email, password) => dispatch => {
  if (email) {
    identifyAndTrack(email, 'Login Form Submitted');
  }
  dispatch({
    type: REGISTRATION_LOGIN.type,
    payload: api_login({
      username: email,
      password: password
    }).then(response => {
      if (response.success) {
        dispatch(updateBuckUser());
      }
      return response;
    })
  });
};

export const REGISTRATION_GET_MENU_CONTENT_STACK_DATA = actionComposer(
  'REGISTRATION_GET_MENU_CONTENT_STACK_DATA'
);
export const getMenuContentStackData = () => ({
  type: REGISTRATION_GET_MENU_CONTENT_STACK_DATA.type,
  payload: queryContentStackById('signin', 'blt566d93fe4f34d9d2')
});

export const REGISTRATION_GET_LOGIN_CONTENT_STACK_DATA = actionComposer(
  'REGISTRATION_GET_LOGIN_CONTENT_STACK_DATA'
);
export const getLoginContentStackData = () => ({
  type: REGISTRATION_GET_LOGIN_CONTENT_STACK_DATA.type,
  payload: queryContentStackById('auth', 'blt66e5eeb543aabaae')
});

export const REGISTRATION_GET_ACCOUNT_CONTENT_STACK_DATA = actionComposer(
  'REGISTRATION_GET_ACCOUNT_CONTENT_STACK_DATA'
);
export const getAccountContentStackData = () => ({
  type: REGISTRATION_GET_ACCOUNT_CONTENT_STACK_DATA.type,
  payload: queryContentStackById('_id_account', 'blt9ad242c9aff3abea')
});

export const REGISTRATION_CREATE_NEW_USER = actionComposer(
  'REGISTRATION_CREATE_NEW_USER'
);
export const createNewUser = (
  first_name,
  last_name,
  email,
  company_name,
  tshirt_size,
  password
) => dispatch => {
  if (email) {
    identifyAndTrack(email, 'Create New User Form Submitted');
  }
  dispatch({
    type: REGISTRATION_CREATE_NEW_USER.type,
    payload: api_create_new_user({
      first_name: first_name,
      last_name: last_name,
      company_name: company_name,
      email: email,
      tshirt_size: tshirt_size,
      password: password
    }).then(response => {
      if (response.success) {
        dispatch(updateBuckUser());
      }
      return response;
    })
  });
};
