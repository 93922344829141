import {
  GET_GKO_STACK,
  GET_GKO_GENES,
  GET_GKO_CELL_LINES,
  ADD_GKO_ORDER_TO_CART,
  CLEAR_GKO_CART_ERRORS
} from './actions';

export const getInitialState = () => ({
  stack: {},
  stackLoading: true,
  validCellLines: [],
  validCellLinesLoading: false,
  validGenes: {},
  validGenesLoading: false,
  cartError: false,
  cartErrorMessage: null,
  cartLoading: false,
  redirectToCart: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_GKO_STACK.PENDING:
      return {
        ...state,
        stackLoading: true
      };
    case GET_GKO_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload[0],
        stackLoading: false
      };
    case GET_GKO_STACK.REJECTED:
      return {
        ...state,
        stackLoading: false
      };
    case GET_GKO_GENES.PENDING:
      return {
        ...state,
        validGenesLoading: true
      };
    case GET_GKO_GENES.FULFILLED:
      return {
        ...state,
        validGenes: action.payload,
        validGenesLoading: false
      };
    case GET_GKO_GENES.REJECTED:
      return {
        ...state,
        validGenesLoading: false
      };
    case GET_GKO_CELL_LINES.PENDING:
      return {
        ...state,
        validCellLinesLoading: true
      };
    case GET_GKO_CELL_LINES.FULFILLED:
      return {
        ...state,
        validCellLines: action.payload,
        validCellLinesLoading: false
      };
    case GET_GKO_CELL_LINES.REJECTED:
      return {
        ...state,
        validCellLinesLoading: false
      };
    case ADD_GKO_ORDER_TO_CART.PENDING:
      return {
        ...state,
        cartLoading: true,
        cartError: false
      };
    case ADD_GKO_ORDER_TO_CART.FULFILLED:
      return {
        ...state,
        cartLoading: false,
        cartError: false,
        redirectToCart: true
      };
    case ADD_GKO_ORDER_TO_CART.REJECTED:
      return {
        ...state,
        cartLoading: false,
        cartError: true,
        cartErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload
      };
    case CLEAR_GKO_CART_ERRORS:
      return {
        ...state,
        cartError: false,
        cartErrorMessage: null,
        redirectToCart: false
      };
    default:
      return state;
  }
};
