import {
  RESET_EDIT_CREDIT_REFILL,
  CREATE_EDIT_CREDIT_REFILL,
  BUY_EDIT_CREDIT
} from './actions';

export const getInitialState = () => ({
  editCreditRefill: {},
  orderToken: null,
  loading: false,
  error: null
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case RESET_EDIT_CREDIT_REFILL:
      return getInitialState();
    case CREATE_EDIT_CREDIT_REFILL.PENDING:
      return {
        ...state,
        loading: true
      };
    case CREATE_EDIT_CREDIT_REFILL.FULFILLED:
      return {
        ...state,
        editCreditRefill: action.payload,
        loading: false
      };
    case CREATE_EDIT_CREDIT_REFILL.REJECTED:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case BUY_EDIT_CREDIT.PENDING:
      return {
        ...state,
        loading: true
      };
    case BUY_EDIT_CREDIT.FULFILLED:
      return {
        ...state,
        loading: false,
        orderToken: action.payload.token
      };

    case BUY_EDIT_CREDIT.REJECTED:
      return {
        ...state,
        loading: false,
        orderToken: null,
        error: action.payload
      };

    default:
      return state;
  }
};
