import { GET_SURVEY_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    benefits: [],
    description: '',
    form_id: '',
    form_label: '',
    background_image: { url: '' },
    icon: { url: '' },
    title: '',
    related_references: {
      title: '',
      blog_references: [],
      resource_references: []
    }
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_SURVEY_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload
      };
    default:
      return state;
  }
};
