import {
  UPDATE_BUCK_USER,
  GET_BUCK_USER_ORDERS,
  GET_BUCK_USER_EDIT_CREDIT,
  GET_BUCK_USER_EDIT_CREDIT_TRANSACTIONS,
  GET_BUCK_USER_SHIPPING_ADDRESSES,
  GET_BUCK_USER_BILLING_ADDRESSES,
  GET_BUCK_USER_LAST_BILLING_ADDRESS,
  GET_BUCK_USER_LAST_SHIPPING_ADDRESS,
  SET_REQUESTED_ACADEMIC_PRICING
} from './actions';
import BuckaneerUser from 'utilities/buckaneerUser';

export const getInitialState = () => ({
  requestedAcademicPricing: false,
  user: {},
  userOrders: [],
  userGiftCards: [],
  selectedGiftCard: {},
  userEditCreditBalance: 0,
  refillEditCredit: {},
  shippingAddresses: [],
  billingAddresses: [],
  lastShippingAddress: {},
  lastBillingAddress: {},
  loading: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case UPDATE_BUCK_USER.FULFILLED:
      return {
        ...state,
        user: Object.assign({}, BuckaneerUser(action.payload))
      };
    case UPDATE_BUCK_USER.REJECTED:
      return {
        ...state,
        user: Object.assign({}, BuckaneerUser({}))
      };
    case GET_BUCK_USER_ORDERS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BUCK_USER_ORDERS.FULFILLED:
      return {
        ...state,
        userOrders: action.payload,
        loading: false
      };
    case GET_BUCK_USER_ORDERS.REJECTED:
      return {
        ...state,
        loading: false
      };

    case GET_BUCK_USER_EDIT_CREDIT.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BUCK_USER_EDIT_CREDIT.FULFILLED:
      return {
        ...state,
        userGiftCards: action.payload.gift_cards,
        userEditCreditBalance: action.payload.balance,
        loading: false
      };
    case GET_BUCK_USER_EDIT_CREDIT.REJECTED:
      return {
        ...state,
        loading: false
      };

    case GET_BUCK_USER_EDIT_CREDIT_TRANSACTIONS.PENDING:
      return {
        ...state,
        selectedGiftCard: {},
        loading: true
      };
    case GET_BUCK_USER_EDIT_CREDIT_TRANSACTIONS.FULFILLED:
      return {
        ...state,
        selectedGiftCard: action.payload,
        loading: false
      };
    case GET_BUCK_USER_EDIT_CREDIT_TRANSACTIONS.REJECTED:
      return {
        ...state,
        selectedGiftCard: {},
        loading: false
      };

    case GET_BUCK_USER_SHIPPING_ADDRESSES.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BUCK_USER_SHIPPING_ADDRESSES.FULFILLED:
      return {
        ...state,
        shippingAddresses: action.payload,
        loading: false
      };
    case GET_BUCK_USER_SHIPPING_ADDRESSES.REJECTED:
      return {
        ...state,
        loading: false
      };

    case GET_BUCK_USER_BILLING_ADDRESSES.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BUCK_USER_BILLING_ADDRESSES.FULFILLED:
      return {
        ...state,
        billingAddresses: action.payload,
        loading: false
      };
    case GET_BUCK_USER_BILLING_ADDRESSES.REJECTED:
      return {
        ...state,
        loading: false
      };

    case GET_BUCK_USER_LAST_BILLING_ADDRESS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BUCK_USER_LAST_BILLING_ADDRESS.FULFILLED:
      return {
        ...state,
        lastBillingAddress: action.payload,
        loading: false
      };
    case GET_BUCK_USER_LAST_BILLING_ADDRESS.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_BUCK_USER_LAST_SHIPPING_ADDRESS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BUCK_USER_LAST_SHIPPING_ADDRESS.FULFILLED:
      return {
        ...state,
        lastShippingAddress: action.payload,
        loading: false
      };
    case GET_BUCK_USER_LAST_SHIPPING_ADDRESS.REJECTED:
      return {
        ...state,
        loading: false
      };
    case SET_REQUESTED_ACADEMIC_PRICING:
      return {
        ...state,
        requestedAcademicPricing: true
      };
    default:
      return state;
  }
};
