import { GET_USER_PROMO_STACK } from 'features/userpromo/actions';

export const getInitialState = () => ({
  stack: {
    foreground_color: '',
    background_gradient: {
      color1: '',
      color2: ''
    },
    background_image: {
      url: ''
    },
    blocks: []
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_USER_PROMO_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload
      };
    default:
      return state;
  }
};
