import actionComposer from 'utilities/actionComposer';
import { queryContentStackByType } from 'utilities/contentStack';
import {
  api_AddToBuckCart,
  api_GetXKOGenes,
  api_GetXKOCellLines,
  api_CheckXKOSequence
} from './api';

export const GET_XKO_STACK = actionComposer('GET_XKO_STACK');
export const getXKOStack = () => ({
  type: GET_XKO_STACK.type,
  payload: queryContentStackByType('order_xko', {
    includedReferences: ['benefits']
  })
});

export const GET_XKO_GENES = actionComposer('GET_XKO_GENES');
export const getXKOGenes = species => ({
  type: GET_XKO_GENES.type,
  payload: api_GetXKOGenes(species)
});

export const GET_XKO_CELL_LINES = actionComposer('GET_XKO_CELL_LINES');
export const getXKOCellLines = species => ({
  type: GET_XKO_CELL_LINES.type,
  payload: api_GetXKOCellLines(species)
});

export const ADD_XKO_ORDER_TO_CART = actionComposer('ADD_XKO_ORDER_TO_CART');
export const addOrderToCart = values => ({
  type: ADD_XKO_ORDER_TO_CART.type,
  payload: api_AddToBuckCart(values)
});

export const CLEAR_XKO_CART_ERRORS = 'CLEAR_XKO_CART_ERRORS';
export const clearCartErrors = () => {
  return {
    type: CLEAR_XKO_CART_ERRORS
  };
};

export const CHECK_XKO_SEQUENCE_ELIGIBILITY = actionComposer(
  'CHECK_XKO_SEQUENCE_ELIGIBILITY'
);
export const checkSequenceEligibility = values => dispatch => {
  dispatch({
    type: CHECK_XKO_SEQUENCE_ELIGIBILITY.type,
    payload: api_CheckXKOSequence(
      values.sequence,
      values.genome?.commonName
    ).then(response => {
      if (response?.[values.sequence]?.valid) {
        dispatch(addOrderToCart(values));
      }
      return response;
    })
  });
};

export const CLEAR_XKO_SEQUENCE_ELIGIBILITY = 'CLEAR_XKO_SEQUENCE_ELIGIBILITY';
export const clearSequenceEligibility = () => {
  return {
    type: CLEAR_XKO_SEQUENCE_ELIGIBILITY
  };
};
