import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_GUIDE_CHAPTER_STACK = actionComposer(
  'GET_GUIDE_CHAPTER_STACK'
);
export const getGuideChapterStack = url => ({
  type: GET_GUIDE_CHAPTER_STACK.type,
  payload: queryContentStackByUrl('guides_chapter', url, {
    includedReferences: [
      'guide_home_reference',
      'guide_home_reference.chapter_references',
      'guide_home_reference.related_references.resource_references',
      'guide_home_reference.related_references.blog_references',
      'content.resource_inline.resource_reference',
      'content.blog_inline.blog_reference',
      'content.cta.cta_reference'
    ]
  })
});
