import { GET_PUBLICATIONS_DETAIL_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    details: {
      journal: '',
      journal_link: {
        title: '',
        href: ''
      },
      publish_date: null,
      title: '',
      first_author: '',
      authors: '',
      affiliations: [],
      abstract: ''
    },
    pubmed_data: {
      articleids: [],
      authors: [],
      affiliations: []
    }
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_PUBLICATIONS_DETAIL_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_PUBLICATIONS_DETAIL_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_PUBLICATIONS_DETAIL_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
