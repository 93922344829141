import {
  GET_XKO_STACK,
  GET_XKO_GENES,
  GET_XKO_CELL_LINES,
  ADD_XKO_ORDER_TO_CART,
  CLEAR_XKO_CART_ERRORS,
  CHECK_XKO_SEQUENCE_ELIGIBILITY,
  CLEAR_XKO_SEQUENCE_ELIGIBILITY
} from './actions';

export const getInitialState = () => ({
  stack: {},
  stackLoading: true,
  validCellLines: [],
  validCellLinesLoading: false,
  validGenes: {},
  validGenesLoading: false,
  sequenceEligibility: {},
  sequenceEligibilityLoading: false,
  cartError: false,
  cartErrorMessage: null,
  cartLoading: false,
  redirectToCart: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_XKO_STACK.PENDING:
      return {
        ...state,
        stackLoading: true
      };
    case GET_XKO_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload[0],
        stackLoading: false
      };
    case GET_XKO_STACK.REJECTED:
      return {
        ...state,
        stackLoading: false
      };
    case GET_XKO_GENES.PENDING:
      return {
        ...state,
        validGenesLoading: true
      };
    case GET_XKO_GENES.FULFILLED:
      return {
        ...state,
        validGenes: action.payload,
        validGenesLoading: false
      };
    case GET_XKO_GENES.REJECTED:
      return {
        ...state,
        validGenesLoading: false
      };
    case GET_XKO_CELL_LINES.PENDING:
      return {
        ...state,
        validCellLinesLoading: true
      };
    case GET_XKO_CELL_LINES.FULFILLED:
      return {
        ...state,
        validCellLines: action.payload,
        validCellLinesLoading: false
      };
    case GET_XKO_CELL_LINES.REJECTED:
      return {
        ...state,
        validCellLinesLoading: false
      };
    case ADD_XKO_ORDER_TO_CART.PENDING:
      return {
        ...state,
        cartLoading: true,
        cartError: false
      };
    case ADD_XKO_ORDER_TO_CART.FULFILLED:
      return {
        ...state,
        cartLoading: false,
        cartError: false,
        redirectToCart: true
      };
    case ADD_XKO_ORDER_TO_CART.REJECTED:
      return {
        ...state,
        cartLoading: false,
        cartError: true,
        cartErrorMessage:
          action.payload.error_message?.[0] ||
          action.payload.error_message ||
          action.payload
      };
    case CLEAR_XKO_CART_ERRORS:
      return {
        ...state,
        cartError: false,
        cartErrorMessage: null,
        redirectToCart: false
      };
    case CHECK_XKO_SEQUENCE_ELIGIBILITY.PENDING:
      return {
        ...state,
        sequenceEligibilityLoading: true
      };
    case CHECK_XKO_SEQUENCE_ELIGIBILITY.FULFILLED:
      return {
        ...state,
        sequenceEligibility: action.payload,
        sequenceEligibilityLoading: false
      };
    case CHECK_XKO_SEQUENCE_ELIGIBILITY.REJECTED:
      return {
        ...state,
        sequenceEligibilityLoading: false
      };
    case CLEAR_XKO_SEQUENCE_ELIGIBILITY:
      return {
        ...state,
        sequenceEligibility: null
      };
    default:
      return state;
  }
};
