import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_EXPERIENCE_DETAIL_STACK = actionComposer(
  'GET_EXPERIENCE_DETAIL_STACK'
);
export const getExperienceDetailStack = url => ({
  type: GET_EXPERIENCE_DETAIL_STACK.type,
  payload: queryContentStackByUrl('experience', url, {
    includedReferences: [
      'modular_blocks.section.modular_blocks.sub_section.primary_content.cta.cta_reference',
      'modular_blocks.section.modular_blocks.sub_section.secondary_content.figure.figure_reference',
      'modular_blocks.section.modular_blocks.sub_section.secondary_content.table.table_reference',
      'modular_blocks.finish.modular_blocks.blog_post.blog_post_reference',
      'modular_blocks.finish.modular_blocks.resource.resource_reference',
      'modular_blocks.finish.modular_blocks.quote.quote_reference',
      'modular_blocks.finish.modular_blocks.publication.publication_reference',
      'modular_blocks.finish.modular_blocks.publication.publication_reference.categories_tags.product',
      'modular_blocks.finish.modular_blocks.publication.publication_reference.categories_tags.application',
      'modular_blocks.finish.modular_blocks.publication.publication_reference.categories_tags.research_area',
      'modular_blocks.finish.modular_blocks.cta.cta_reference',
      'modular_blocks.finish.modular_blocks.guide.guide_reference',
      'modular_blocks.finish.modular_blocks.guide.guide_reference.guide_home_reference',
      'modular_blocks.finish.modular_blocks.resource_list.resource_references'
    ]
  })
});

export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const setActiveSection = section => {
  return {
    value: section,
    type: SET_ACTIVE_SECTION
  };
};

export const SET_NAV_COLLAPSED = 'SET_NAV_COLLAPSED';
export const setNavCollapsed = value => {
  return {
    value: value,
    type: SET_NAV_COLLAPSED
  };
};
