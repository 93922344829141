import {
  GET_EVENTS_LANDING_STACK,
  GET_EVENTS_LANDING_EVENTS_STACK,
  LOAD_MORE_EVENTS_LANDING_EVENTS_STACK,
  SET_EVENTS_LANDING_EVENT_COUNT
} from './actions';
import { EVENTS_PER_PAGE } from 'features/events/constants';

export const getInitialState = () => ({
  loading: false,
  firstLoadComplete: false,
  events: [],
  total_event_count: 0,
  event_count: EVENTS_PER_PAGE,
  stack: {
    title: '',
    description: '',
    filters: {
      types: [],
      locations: []
    }
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_EVENTS_LANDING_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        firstLoadComplete: true
      };
    case GET_EVENTS_LANDING_EVENTS_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_EVENTS_LANDING_EVENTS_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    case GET_EVENTS_LANDING_EVENTS_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        events: action.payload[0],
        event_count: action.payload[0].length,
        total_event_count: action.payload[1]
      };
    case LOAD_MORE_EVENTS_LANDING_EVENTS_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        events: [...state.events, ...action.payload[0]],
        event_count: state.events.length + action.payload[0].length
      };
    case SET_EVENTS_LANDING_EVENT_COUNT:
      return {
        ...state,
        event_count: action.event_count
      };
    default:
      return state;
  }
};
