import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_get_custom_request = requestSlug =>
  fRequest(`${config.buckRootUrl}/order/advanced_requests/${requestSlug}/`, {
    method: 'GET'
  });

export const api_update_custom_request = (requestSlug, data) =>
  fRequest(`${config.buckRootUrl}/order/advanced_requests/${requestSlug}/`, {
    method: 'PUT',
    data: data
  });

export const api_update_custom_request_sales_rep = (requestSlug, data) =>
  fRequest(
    `${
      config.buckRootUrl
    }/order/advanced_requests/${requestSlug}/set_sales_rep/`,
    {
      method: 'PUT',
      data: data
    }
  );

export const api_list_custom_request_sales_reps = () =>
  fRequest(`${config.buckRootUrl}/sales/sales_reps/`, {
    method: 'get'
  });
