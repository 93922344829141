import { GET_BLOG_BASE_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    header: {
      logo: {}
    },
    featured_posts: {
      primary_post: [],
      secondary_posts: []
    },
    featured_categories: [],
    featured_items: []
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_BLOG_BASE_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BLOG_BASE_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        loading: false
      };
    case GET_BLOG_BASE_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
