// Modified to add missing field groups
// https://github.com/contentstack/contentstack-utils-javascript/blob/master/src/entry-editable.ts
export default function addEditableTags(
  entry,
  contentTypeUid,
  tagsAsObject,
  locale = 'en-us'
) {
  if (entry)
    entry.$ = getTag(
      entry,
      `${contentTypeUid}.${entry.uid}.${locale}`,
      tagsAsObject,
      locale
    );
}

function getTag(content, prefix, tagsAsObject, locale) {
  let tags = {};
  Object.entries(content).forEach(([key, value]) => {
    //This adds missing field groups
    tags[key] = getTagsValue(`${prefix}.${key}`, tagsAsObject);
    switch (typeof value) {
      case 'object':
        if (Array.isArray(value)) {
          value.forEach((obj, index) => {
            if (
              (typeof obj !== 'undefined' || obj !== null) &&
              obj._content_type_uid !== undefined &&
              obj.uid !== undefined
            ) {
              value[index].$ = getTag(
                obj,
                `${obj._content_type_uid}.${obj.uid}.${obj.locale || locale}`,
                tagsAsObject,
                locale
              );
            } else {
              if (typeof obj === 'object') {
                obj.$ = getTag(
                  obj,
                  `${prefix}.${key}.${index}`,
                  tagsAsObject,
                  locale
                );
              } else {
                tags[key] = getTagsValue(`${prefix}.${key}`, tagsAsObject);
              }
            }
          });
        } else {
          if (value) {
            value.$ = getTag(value, `${prefix}.${key}`, tagsAsObject, locale);
          }
        }
        break;
      default:
        tags[key] = getTagsValue(`${prefix}.${key}`, tagsAsObject);
    }
  });
  return tags;
}

function getTagsValue(dataValue, tagsAsObject) {
  if (tagsAsObject) {
    return { 'data-cslp': dataValue };
  } else {
    return `data-cslp=${dataValue}`;
  }
}
