import { RECALCULATE_ORDER_QUOTE } from './actions';

export const getInitialState = () => ({
  loading: false,
  recalculated: false,
  error: null
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case RECALCULATE_ORDER_QUOTE.PENDING:
      return {
        ...state,
        loading: true,
        recalculated: false
      };
    case RECALCULATE_ORDER_QUOTE.FULFILLED:
      return {
        ...state,
        loading: false,
        recalculated: true
      };
    case RECALCULATE_ORDER_QUOTE.REJECTED:
      return {
        ...state,
        loading: false,
        recalculated: true,
        error: action.payload
      };
    default:
      return state;
  }
};
