import {
  GET_BLOG_POSTS,
  LOAD_MORE_BLOG_POSTS,
  GET_RECENT_BLOG_POSTS,
  SET_ACTIVE_CATEGORY_TAG,
  RESET_BLOG_POSTS
} from './actions';

export const getInitialState = () => ({
  loading: true,
  posts: [],
  recent_posts: [],
  post_count: 0,
  total_post_count: 0,
  category_tag: null
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_BLOG_POSTS.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_BLOG_POSTS.FULFILLED:
      return {
        ...state,
        posts: action.payload[0],
        post_count: action.payload[0].length,
        total_post_count: action.payload[1],
        loading: false
      };
    case GET_BLOG_POSTS.REJECTED:
      return {
        ...state,
        loading: false
      };
    case LOAD_MORE_BLOG_POSTS.FULFILLED:
      return {
        ...state,
        posts: [...state.posts, ...action.payload[0]],
        post_count: state.posts.length + action.payload[0].length
      };
    case GET_RECENT_BLOG_POSTS.FULFILLED:
      return {
        ...state,
        recent_posts: action.payload[0]
      };

    case SET_ACTIVE_CATEGORY_TAG:
      return {
        ...state,
        category_tag: action.payload
      };
    case RESET_BLOG_POSTS:
      return getInitialState();
    default:
      return state;
  }
};
