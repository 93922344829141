import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_HUB_STACK = actionComposer('GET_HUB_STACK');
export const getHubStack = url => ({
  type: GET_HUB_STACK.type,
  payload: queryContentStackByUrl('hubs', url, {
    includedReferences: [
      'sections.resource_inline.resource_reference',
      'sections.blog_inline.blog_reference',
      'sections.cta_references',
      'footer_ctas.resource_reference',
      'footer_ctas.blog_reference',
      'sidebar_cta_references'
    ]
  })
});
