import {
  ORDER_ADVANCED_CELLS_RESET,
  ORDER_ADVANCED_CELLS_GET_CS_DATA,
  ORDER_ADVANCED_CELLS_SUBMIT,
  ORDER_ADVANCED_CELLS_SET_ANSWER,
  ORDER_ADVANCED_CELLS_CHECK_CHECKBOX,
  ORDER_ADVANCED_CELLS_UNCHECK_CHECKBOX,
  ORDER_ADVANCED_CELLS_TOGGLE_TOGGLE,
  TOGGLE_EXPANDED,
  ORDER_ADVANCED_CELLS_GET_ADDRESS_SUGGESTION
} from 'features/order/advanced/cells/actions';

export const getInitialState = () => ({
  address_suggestion: null,
  address_suggestion_status: null,
  expanded_panel: 'panel0',
  checkboxes: {},
  content_stack: {},
  answers: {},
  submit_status: 'none',
  submit_response: {},
  title: '',
  instructions: ''
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case ORDER_ADVANCED_CELLS_GET_ADDRESS_SUGGESTION.PENDING:
      return {
        ...state,
        address_suggestion_status: 'pending'
      };
    case ORDER_ADVANCED_CELLS_GET_ADDRESS_SUGGESTION.FULFILLED:
      return {
        ...state,
        address_suggestion_status: 'success',
        address_suggestion: action.payload
      };
    case ORDER_ADVANCED_CELLS_GET_ADDRESS_SUGGESTION.REJECTED:
      return {
        ...state,
        address_suggestion_status: 'failed'
      };

    case ORDER_ADVANCED_CELLS_GET_CS_DATA.FULFILLED:
      return {
        ...state,
        content_stack: action.payload
      };
    case ORDER_ADVANCED_CELLS_SUBMIT.PENDING:
      return {
        ...state,
        submit_status: 'pending'
      };
    case ORDER_ADVANCED_CELLS_SUBMIT.FULFILLED:
      return {
        ...state,
        submit_status: 'success',
        submit_response: action.payload
      };
    case ORDER_ADVANCED_CELLS_SUBMIT.REJECTED:
      return {
        ...state,
        submit_status: 'failed'
      };

    case ORDER_ADVANCED_CELLS_SET_ANSWER:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.id]: action.answer
        }
      };
    case TOGGLE_EXPANDED:
      return {
        ...state,
        expanded_panel: action.expanded_panel
      };
    case ORDER_ADVANCED_CELLS_TOGGLE_TOGGLE:
      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          [action.question_id]: action.value
        },
        answers: {
          ...state.answers,
          [action.question_id]: action.value
        }
      };
    case ORDER_ADVANCED_CELLS_CHECK_CHECKBOX:
      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          [action.box]: true
        },
        answers: {
          ...state.answers,
          [action.parent]: state.answers[action.parent]
            ? [...state.answers[action.parent], action.value]
            : [action.value]
        }
      };
    case ORDER_ADVANCED_CELLS_UNCHECK_CHECKBOX:
      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          [action.box]: false
        },
        answers: {
          ...state.answers,
          [action.parent]: state.answers[action.parent].filter(el => {
            return el !== action.value;
          })
        }
      };
    case ORDER_ADVANCED_CELLS_RESET:
      return {
        ...getInitialState(),
        content_stack: state.content_stack
      };
    default:
      return state;
  }
};
