import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_send_email_verification_email = () =>
  fRequest(`${config.buckRootUrl}/account/send-email-verification-email`, {
    method: 'GET'
  });

export const api_send_password_reset = data =>
  fRequest(`${config.buckRootUrl}/account/send-password-reset`, {
    method: 'POST',
    data: data
  });

export const api_reset_password = data =>
  fRequest(`${config.buckRootUrl}/account/reset-password`, {
    method: 'POST',
    data: data
  });

export const api_logout = () =>
  fRequest(`${config.buckRootUrl}/account/api-logout`, {
    method: 'GET'
  });

export const api_login = data =>
  fRequest(`${config.buckRootUrl}/account/api-login`, {
    method: 'POST',
    data: data
  });

export const api_update_user = data =>
  fRequest(`${config.buckRootUrl}/account/update-user`, {
    method: 'POST',
    data: data
  });

export const api_create_new_user = data =>
  fRequest(`${config.buckRootUrl}/account/create-new-user`, {
    method: 'POST',
    data: data
  });
