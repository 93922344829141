import { GET_CONTACT_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    headline: '',
    benefits: [],
    ctas: [],
    background_image: {
      url: ''
    }
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_CONTACT_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload
      };
    default:
      return state;
  }
};
