import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_THANKYOU_DETAIL_STACK = actionComposer(
  'GET_THANKYOU_DETAIL_STACK'
);
export const getThankYouDetailStack = url => ({
  type: GET_THANKYOU_DETAIL_STACK.type,
  payload: queryContentStackByUrl('resources_thanks', url, {
    includedReferences: [
      'related_references.resource_references',
      'related_references.blog_references'
    ]
  })
});
