// Wrapper for Hubspot tracker
export const identifyAndTrack = (email, eventName) => {
  try {
    var _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['identify', { email: email }]);
    _hsq.push([
      'trackEvent',
      {
        id: eventName,
        value: true
      }
    ]);
  } catch (error) {
    console.error(error);
  }
};
