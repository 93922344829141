import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_PRODUCT_STACK = actionComposer('GET_PRODUCT_STACK');
export const getProductStack = url => dispatch => {
  queryContentStackByUrl('product_pages', url, {
    includedReferences: [
      'quotes',
      'resources.resource_references',
      'pricing.order_page',
      'order_benefits'
    ]
  })
    .then(payload => {
      dispatch({
        type: GET_PRODUCT_STACK.type,
        payload: Promise.resolve(payload)
      });
      dispatch(setLegacyProduct(true));
    })
    .catch(() => {
      dispatch({
        type: GET_PRODUCT_STACK.type,
        payload: queryContentStackByUrl('enhanced_product', url, {
          includedReferences: [
            'content.section.cta',
            'content.section.modular_blocks.figure.figure_reference',
            'content.section.modular_blocks.quote.quote_reference',
            'content.section.modular_blocks.publication.publication_reference',
            'content.section.modular_blocks.publication.publication_reference.categories_tags.product',
            'content.section.modular_blocks.publication.publication_reference.categories_tags.application',
            'content.section.modular_blocks.publication.publication_reference.categories_tags.research_area',
            'content.section.modular_blocks.reference_list.references',
            'content.section.modular_blocks.publication_list.product_reference',
            'footer.cta.cta_reference',
            'footer.quotes.quotes_reference',
            'categories_tags.application',
            'categories_tags.research_area',
            'categories_tags.editing_method',
            'categories_tags.paper_type',
            'categories_tags.product',
            'categories_tags.product.family',
            'categories_tags.model_organism',
            'categories_tags.customer_affiliation'
          ]
        })
      });
      dispatch(setLegacyProduct(false));
    });
};

export const CLEAR_PRODUCT_STACK = 'CLEAR_PRODUCT_STACK';
export const clearProductStack = () => ({
  type: CLEAR_PRODUCT_STACK
});

export const SET_LEGACY_PRODUCT = 'SET_LEGACY_PRODUCT';
export const setLegacyProduct = value => ({
  type: SET_LEGACY_PRODUCT,
  legacy: value
});

export const SET_FLAVOR_IDX = 'SET_FLAVOR_IDX';
export const setFlavorIdx = idx => ({
  type: SET_FLAVOR_IDX,
  selectedIdx: idx
});
