import actionComposer from 'utilities/actionComposer';
import { queryContentStackById } from 'utilities/contentStack';

export const GET_TOP_NAV_STACK_DATA = actionComposer('GET_TOP_NAV_STACK_DATA');
export const getTopNavStackData = url => ({
  type: GET_TOP_NAV_STACK_DATA.type,
  payload: queryContentStackById('header_nav', 'blt23748e20d7bd47c5', {
    includedReferences: [
      'primary.main_item.sections.column.items.product_menu.product_family',
      'primary.main_item.sections.column.items.product_menu.product_family.page',
      'primary.main_item.sections.column.items.product_menu.groups.items.product'
    ]
  })
});

export const SET_TOP_NAV_COLOR = 'SET_TOP_NAV_COLOR';
export const setTopNavColor = color => ({
  type: SET_TOP_NAV_COLOR,
  color: color
});

export const setTopNavColorLight = () => ({
  type: SET_TOP_NAV_COLOR,
  color: 'default'
});

export const setTopNavColorDark = () => ({
  type: SET_TOP_NAV_COLOR,
  color: 'primary'
});

export const SET_TOP_NAV_MINIMAL = 'SET_TOP_NAV_MINIMAL';
export const setTopNavMinimal = value => ({
  type: SET_TOP_NAV_MINIMAL,
  minimal: value
});

export const SET_TOP_NAV_HIDDEN = 'SET_TOP_NAV_HIDDEN';
export const setTopNavHidden = value => ({
  type: SET_TOP_NAV_HIDDEN,
  hidden: value
});
