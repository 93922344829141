import actionComposer from 'utilities/actionComposer';
import {
  api_stripePayment,
  api_purchaseOrderPayment,
  api_blanketPurchaseOrderPayment,
  api_editCreditPayment,
  api_confirmOrder
} from './api';

export const RESET_ORDER_PAYMENT = 'RESET_ORDER_PAYMENT';
export const resetOrderPayment = () => ({
  type: RESET_ORDER_PAYMENT
});

export const CREATE_STRIPE_PAYMENT = actionComposer('CREATE_STRIPE_PAYMENT');
export const createStripePayment = orderToken => ({
  type: CREATE_STRIPE_PAYMENT.type,
  payload: api_stripePayment(orderToken)
});

export const CREATE_PURCHASE_ORDER_PAYMENT = actionComposer(
  'CREATE_PURCHASE_ORDER_PAYMENT'
);
export const createPurchaseOrderPayment = (orderToken, formData) => ({
  type: CREATE_PURCHASE_ORDER_PAYMENT.type,
  payload: api_purchaseOrderPayment(orderToken, formData)
});

export const CREATE_BLANKET_PURCHASE_ORDER_PAYMENT = actionComposer(
  'CREATE_BLANKET_PURCHASE_ORDER_PAYMENT'
);
export const createBlanketPurchaseOrderPayment = orderToken => ({
  type: CREATE_BLANKET_PURCHASE_ORDER_PAYMENT.type,
  payload: api_blanketPurchaseOrderPayment(orderToken)
});

export const CREATE_EDIT_CREDIT_PAYMENT = actionComposer(
  'CREATE_EDIT_CREDIT_PAYMENT'
);
export const createEditCreditPayment = orderToken => ({
  type: CREATE_EDIT_CREDIT_PAYMENT.type,
  payload: api_editCreditPayment(orderToken)
});

export const CONFIRM_ORDER = actionComposer('CONFIRM_ORDER');
export const confirmOrder = orderToken => ({
  type: CONFIRM_ORDER.type,
  payload: api_confirmOrder(orderToken)
});
