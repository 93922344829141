import { SET_SELECTED_FILTERS } from './actions';
import { buildFilterQuery } from './utils';

export const getInitialState = () => ({
  selected_filters: {},
  filter_query: {}
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selected_filters: { ...state.selected_filters, ...action.filters },
        filter_query: buildFilterQuery({
          ...state.selected_filters,
          ...action.filters
        })
      };
    default:
      return state;
  }
};
