import { GET_HUB_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    title: '',
    hero_image: {
      url: ''
    },
    summary: '',
    sections: [],
    sidebar_ctas: [],
    footer_ctas: {
      title: '',
      blog_reference: [],
      resource_reference: [],
      form: {},
      learn_more: {
        icon: {
          url: ''
        },
        button_label: ''
      }
    }
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_HUB_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_HUB_STACK.FULFILLED:
      return {
        stack: action.payload,
        loading: false
      };
    case GET_HUB_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
