import _ from 'lodash';
import {
  ORDER_CUSTOM_REQUEST_CHANGE_REQUEST_TYPE,
  ORDER_CUSTOM_REQUEST_SUBMIT,
  ORDER_CUSTOM_REQUEST_GET,
  ORDER_CUSTOM_REQUEST_SET_ANNOTATION,
  ORDER_CUSTOM_REQUEST_SET_ATTACHMENT,
  ORDER_CUSTOM_REQUEST_PARENT_FORM_RESET,
  ORDER_CUSTOM_REQUEST_PARENT_FORM_GET_CS_DATA,
  ORDER_CUSTOM_REQUEST_PARENT_FORM_SET_ANSWER,
  ORDER_CUSTOM_REQUEST_PARENT_FORM_CHECK_CHECKBOX,
  ORDER_CUSTOM_REQUEST_PARENT_FORM_UNCHECK_CHECKBOX,
  ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_TOGGLE,
  ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_EXPANDED,
  ORDER_CUSTOM_REQUEST_CHILD_FORM_RESET,
  ORDER_CUSTOM_REQUEST_CHILD_FORM_GET_CS_DATA,
  ORDER_CUSTOM_REQUEST_CHILD_FORM_SET_ANSWER,
  ORDER_CUSTOM_REQUEST_CHILD_FORM_CHECK_CHECKBOX,
  ORDER_CUSTOM_REQUEST_CHILD_FORM_UNCHECK_CHECKBOX,
  ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_TOGGLE,
  ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_EXPANDED,
  ORDER_CUSTOM_REQUEST_SET_SALES_REP,
  ORDER_CUSTOM_REQUEST_GET_SALES_REP_LIST
} from './actions';

const getFormInitialState = () => ({
  expandedPanel: 'none',
  checkboxes: {},
  contentStack: {},
  answers: {},
  submitStatus: 'none',
  title: '',
  instructions: '',
  salesRepId: null,
  salesReps: [],
  salesRepsFetchStatus: null,
  salesRepUpdateStatus: null
});

export const getInitialState = () => ({
  annotation: null,
  attachment: {},
  attachmentError: null,
  childForm: getFormInitialState(),
  data: {},
  parentForm: getFormInitialState(),
  submitStatus: 'none',
  salesRepsFetchStatus: null,
  salesRepUpdateStatus: null
});

const MAX_ATTACHED_FILE_SIZE = 100000000;

export default (state = getInitialState(), action) => {
  switch (action.type) {
    // CORE COMPONENT ACTIONS

    case ORDER_CUSTOM_REQUEST_CHANGE_REQUEST_TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          request_type: action.requestType
        }
      };

    case ORDER_CUSTOM_REQUEST_SET_ANNOTATION:
      return {
        ...state,
        annotation: action.text
      };

    case ORDER_CUSTOM_REQUEST_SET_ATTACHMENT: {
      const effectiveAttachment =
        !_.isUndefined(action.file.size) &&
        action.file.size < MAX_ATTACHED_FILE_SIZE
          ? action.file
          : {};
      const errorMessage =
        _.isUndefined(action.file.size) ||
        action.file.size < MAX_ATTACHED_FILE_SIZE
          ? null
          : `${
              action.file.name
            } exceeds max file size of ${MAX_ATTACHED_FILE_SIZE / 1000000}MB`;
      return {
        ...state,
        attachment: effectiveAttachment,
        attachmentError: errorMessage
      };
    }
    case ORDER_CUSTOM_REQUEST_GET.FULFILLED:
      return {
        ...state,
        data: action.payload,
        submitStatus: 'none',
        annotation: null,
        attachment: action.payload.attached_file,
        salesRepId: action.payload.sales_rep
          ? action.payload.sales_rep.id
          : null
      };

    case ORDER_CUSTOM_REQUEST_SUBMIT.PENDING:
      return {
        ...state,
        submitStatus: 'pending'
      };

    case ORDER_CUSTOM_REQUEST_SUBMIT.FULFILLED:
      return {
        ...state,
        data: action.payload,
        submitStatus: 'success'
      };

    case ORDER_CUSTOM_REQUEST_SUBMIT.REJECTED:
      return {
        ...state,
        submitStatus: 'failed'
      };

    case ORDER_CUSTOM_REQUEST_SET_SALES_REP.PENDING:
      return {
        ...state,
        salesRepUpdateStatus: 'pending'
      };

    case ORDER_CUSTOM_REQUEST_SET_SALES_REP.FULFILLED:
      return {
        ...state,
        salesRepId: action.payload.sales_rep_id,
        salesRepUpdateStatus: 'success'
      };

    case ORDER_CUSTOM_REQUEST_SET_SALES_REP.REJECTED:
      return {
        ...state,
        salesRepUpdateStatus: 'failed'
      };

    case ORDER_CUSTOM_REQUEST_GET_SALES_REP_LIST.PENDING:
      return {
        ...state,
        salesRepsFetchStatus: 'pending'
      };

    case ORDER_CUSTOM_REQUEST_GET_SALES_REP_LIST.FULFILLED:
      return {
        ...state,
        salesReps: action.payload,
        salesRepsFetchStatus: 'success'
      };

    case ORDER_CUSTOM_REQUEST_GET_SALES_REP_LIST.REJECTED:
      return {
        ...state,
        salesReps: action.payload,
        salesRepsFetchStatus: 'failed'
      };

    // PARENT FORM ACTIONS

    case ORDER_CUSTOM_REQUEST_PARENT_FORM_GET_CS_DATA.FULFILLED:
      return {
        ...state,
        parentForm: {
          ...state.parentForm,
          contentStack: action.payload
        }
      };
    case ORDER_CUSTOM_REQUEST_PARENT_FORM_SET_ANSWER:
      return {
        ...state,
        parentForm: {
          ...state.parentForm,
          answers: {
            ...state.parentForm.answers,
            [action.id]: action.answer
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_EXPANDED:
      return {
        ...state,
        parentForm: {
          ...state.parentForm,
          expandedPanel: action.expandedPanel
        }
      };
    case ORDER_CUSTOM_REQUEST_PARENT_FORM_TOGGLE_TOGGLE:
      return {
        ...state,
        parentForm: {
          ...state.parentForm,
          checkboxes: {
            ...state.parentForm.checkboxes,
            [action.question_id]: action.value
          },
          answers: {
            ...state.parentForm.answers,
            [action.question_id]: action.value
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_PARENT_FORM_CHECK_CHECKBOX:
      return {
        ...state,
        parentForm: {
          ...state.parentForm,
          checkboxes: {
            ...state.parentForm.checkboxes,
            [action.box]: true
          },
          answers: {
            ...state.parentForm.answers,
            [action.parent]: state.parentForm.answers[action.parent]
              ? [...state.parentForm.answers[action.parent], action.value]
              : [action.value]
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_PARENT_FORM_UNCHECK_CHECKBOX:
      return {
        ...state,
        parentForm: {
          ...state.parentForm,
          checkboxes: {
            ...state.parentForm.checkboxes,
            [action.box]: false
          },
          answers: {
            ...state.parentForm.answers,
            [action.parent]: state.parentForm.answers[action.parent].filter(
              el => {
                return el !== action.value;
              }
            )
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_PARENT_FORM_RESET:
      return {
        ...state,
        parentForm: {
          ...getFormInitialState(),
          contentStack: state.parentForm.contentStack
        }
      };

    // CHILD FORM ACTIONS

    case ORDER_CUSTOM_REQUEST_CHILD_FORM_GET_CS_DATA.FULFILLED:
      return {
        ...state,
        childForm: {
          ...state.childForm,
          contentStack: action.payload
        }
      };
    case ORDER_CUSTOM_REQUEST_CHILD_FORM_SET_ANSWER:
      return {
        ...state,
        childForm: {
          ...state.childForm,
          answers: {
            ...state.childForm.answers,
            [action.id]: action.answer
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_EXPANDED:
      return {
        ...state,
        childForm: {
          ...state.childForm,
          expandedPanel: action.expandedPanel
        }
      };
    case ORDER_CUSTOM_REQUEST_CHILD_FORM_TOGGLE_TOGGLE:
      return {
        ...state,
        childForm: {
          ...state.childForm,
          checkboxes: {
            ...state.childForm.checkboxes,
            [action.question_id]: action.value
          },
          answers: {
            ...state.childForm.answers,
            [action.question_id]: action.value
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_CHILD_FORM_CHECK_CHECKBOX:
      return {
        ...state,
        childForm: {
          ...state.childForm,
          checkboxes: {
            ...state.childForm.checkboxes,
            [action.box]: true
          },
          answers: {
            ...state.childForm.answers,
            [action.parent]: state.childForm.answers[action.parent]
              ? [...state.childForm.answers[action.parent], action.value]
              : [action.value]
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_CHILD_FORM_UNCHECK_CHECKBOX:
      return {
        ...state,
        childForm: {
          ...state.childForm,
          checkboxes: {
            ...state.childForm.checkboxes,
            [action.box]: false
          },
          answers: {
            ...state.childForm.answers,
            [action.parent]: state.childForm.answers[action.parent].filter(
              el => {
                return el !== action.value;
              }
            )
          }
        }
      };
    case ORDER_CUSTOM_REQUEST_CHILD_FORM_RESET:
      return {
        ...state,
        childForm: {
          ...getFormInitialState(),
          contentStack: state.childForm.contentStack
        }
      };

    default:
      return state;
  }
};
