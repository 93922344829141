import config from 'init/config';
import _ from 'lodash';
import { fRequest } from 'utilities/api';

export const api_AddToBuckCart = xkoOrder => {
  let xkoOrderForCart = {};

  xkoOrderForCart.cell_line = xkoOrder.cellLine?.name;
  xkoOrderForCart.product_slug = xkoOrder.editType?.productSlug;
  xkoOrderForCart.species = xkoOrder.genome?.commonName;
  xkoOrderForCart.edit_type = xkoOrder.editType?.slug;

  if (xkoOrder.editType?.slug === 'multi_guide') {
    xkoOrderForCart.ensembl_id = xkoOrder.gene?.ensembl_id;
    xkoOrderForCart.gene_name = xkoOrder.gene?.gene_symbol;
  }

  if (xkoOrder.editType?.slug === 'single_guide') {
    xkoOrderForCart.sequence = xkoOrder.sequence;
  }

  console.log(xkoOrderForCart);
  return fRequest(`${config.buckRootUrl}/api/order/add/`, {
    method: 'POST',
    data: [
      {
        ...xkoOrderForCart
      }
    ]
  });
};

export const api_GetXKOGenes = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/ecl/genes/?species=${species}`,
    {
      method: 'GET'
    }
  );
};

export const api_GetXKOCellLines = species => {
  return fRequest(
    `${config.buckRootUrl}/api/products/cell-lines/?species=${species}`,
    {
      method: 'GET'
    }
  );
};

export const api_CheckXKOSequence = (sequence, species) => {
  return fRequest(
    `${config.buckRootUrl}/api/products/express-ko-pool/sequences/validate/`,
    {
      method: 'POST',
      data: { species: species, sequences: [sequence] }
    }
  );
};

export const api_CheckXKOSequences = async (sequences, species) => {
  //API limit of 32 sequences per request
  const chunkedSquences = _.chunk(sequences, 32);
  const result = await Promise.all(
    chunkedSquences.map(chunk =>
      fRequest(
        `${config.buckRootUrl}/api/products/express-ko-pool/sequences/validate/`,
        {
          method: 'POST',
          data: { species: species, sequences: chunk }
        }
      )
    )
  );
  return _.merge.apply(null, [{}].concat(result));
};
