import { GET_OFFERS_LANDING_STACK } from './actions';

export const getInitialState = () => ({
  loading: true,
  stack: {
    title: '',
    subheading: '',
    offers: [
      {
        offer_reference: [
          {
            general: {
              product_icon: {
                url: ''
              }
            }
          }
        ],
        primary_button: {
          label: ''
        },
        secondary_button: {
          icon: {
            url: ''
          },
          label: '',
          url: ''
        }
      }
    ],
    no_offers: {}
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_OFFERS_LANDING_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_OFFERS_LANDING_STACK.FULFILLED:
      return {
        ...state,
        loading: false,
        stack: action.payload
      };
    case GET_OFFERS_LANDING_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
