import { SET_SCROLL_TO_TOP } from 'features/scrolltotop/actions';

export const getInitialState = () => ({
  enabled: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_SCROLL_TO_TOP:
      return {
        ...state,
        enabled: action.value
      };
    default:
      return state;
  }
};
