const minVw = 320;
const maxVw = 1440;

const getFontSize = (min, max) =>
  `${min}px + (${max} - ${min}) * ((100vw - ${minVw}px) / (${maxVw} - ${minVw}))`;
const getLineHeight = (min, max, lineHeight) =>
  `${getFontSize(min, max)} + ${lineHeight || 0.1}em`;

export const responsiveText = (min, max, lineHeight) =>
  `
  font-size: ${min}px;
  line-height: calc(${min}px + ${lineHeight || 0.1}em);
  
  @media (min-width: ${minVw}px) {
      font-size: calc(${getFontSize(min, max)});
      line-height: calc(${getLineHeight(min, max, lineHeight)});
  }
  
  @media (min-width: ${maxVw}px) {
    font-size: ${max}px;
    line-height: calc(${max}px + ${lineHeight || 0.1}em);
  }  
  `;

export const responsiveTextConfig = (min, max, lineHeight) => ({
  fontSize: `${min}px`,
  lineHeight: `calc(${min}px + ${lineHeight || 0.1}em);`,
  [`@media (min-width:${minVw}px)`]: {
    fontSize: `calc(${getFontSize(min, max)});`,
    lineHeight: `calc(${getLineHeight(min, max, lineHeight)})`
  },

  [`@media (min-width:${maxVw}px)`]: {
    fontSize: `${max}px`,
    lineHeight: `calc(${max}px + ${lineHeight || 0.1}em);`
  }
});

export const fixWidows = text => text.replace(/ (?=[^ ]*$)/i, '\u00a0');
