import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_APPLICATIONS_DETAIL_STACK = actionComposer(
  'GET_APPLICATIONS_DETAIL_STACK'
);
export const getApplicationsDetailStack = url => ({
  type: GET_APPLICATIONS_DETAIL_STACK.type,
  payload: queryContentStackByUrl('application_detail', url, {
    includedReferences: [
      'sections.testimonials',
      'sections.subsections.product_references',
      'related_content.enhanced_publication.publication_reference',
      'related_content.blog_post.blog_post_reference',
      'related_content.resource.resource_reference',
      'ctas.product.product_reference',
      'ctas.enhanced_product.product_reference'
    ]
  })
});
