import { GET_THANKYOU_DETAIL_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    description: '',
    main_image: { url: '' },
    title: '',
    cta_link: '',
    cta_label: '',
    cta: []
  }
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_THANKYOU_DETAIL_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload
      };
    default:
      return state;
  }
};
