import { GET_LANDING_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    title: '',
    page_options: {},
    header: {
      background_video: {
        video_files: [],
        poster: {
          url: ''
        }
      },
      background_image: {
        url: ''
      },
      typed_suffixes: []
    },
    content: [],
    section_content: { sections: [] },
    footer: {}
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_LANDING_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_LANDING_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        loading: false
      };
    case GET_LANDING_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
