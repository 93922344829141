import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_OFFERS_LANDING_STACK = actionComposer(
  'GET_OFFERS_LANDING_STACK'
);
export const getOfferLandingStack = url => ({
  type: GET_OFFERS_LANDING_STACK.type,
  payload: queryContentStackByUrl('offers', '/success', {
    includedReferences: ['offers.offer_reference']
  })
});
