import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';

export const GET_COMPANY_STACK = actionComposer('GET_COMPANY_STACK');
export const getCompanyStack = url => ({
  type: GET_COMPANY_STACK.type,
  payload: queryContentStackByUrl('company', '/company', {
    includedReferences: ['quotes']
  })
});
