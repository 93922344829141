import config from 'init/config';
import { fRequest } from 'utilities/api';

export const api_getCurrentBuckUser = () =>
  fRequest(`${config.buckRootUrl}/api/users/me/`, {
    method: 'GET'
  });

export const api_getCurrentBuckUserOrdersList = () =>
  fRequest(`${config.buckRootUrl}/api/user/list_orders/`, {
    method: 'GET'
  });

export const api_getCurrentBuckUserEditCredit = () =>
  fRequest(`${config.buckRootUrl}/api/user/edit_credit/`, {
    method: 'GET'
  });

export const api_getBuckUserEditCreditTransactions = authcode =>
  fRequest(
    `${config.buckRootUrl}/api/user/edit_credit/${authcode}/transactions/`,
    {
      method: 'GET'
    }
  );

export const api_getCurrentBuckUserShippingAddresses = () =>
  fRequest(`${config.buckRootUrl}/api/user/used_shipping_addresses/`, {
    method: 'GET'
  });

export const api_getCurrentBuckUserBillingAddresses = () =>
  fRequest(`${config.buckRootUrl}/api/user/used_billing_addresses/`, {
    method: 'GET'
  });

export const api_getCurrentBuckUserLastAddress = addressType =>
  fRequest(
    `${config.buckRootUrl}/api/user/last_used_address/?address_type=${addressType}`,
    {
      method: 'GET'
    }
  );

export const api_requestAcademicPricing = email =>
  fRequest(`${config.buckRootUrl}/api/users/login-or-create/`, {
    method: 'POST',
    data: { email: email }
  });
