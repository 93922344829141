import {
  GET_FOOTER_STACK_DATA,
  SET_FOOTER_MINIMAL,
  SET_FOOTER_HIDDEN
} from 'features/navigation/footer/actions';

export const getInitialState = () => ({
  contentStack: {
    logo: { url: '' },
    sub_heading: '',
    menu_footer: [],
    bottom_footer: {
      copyright_title: '',
      legacy_menu: [],
      social_media: []
    }
  },
  hidden: false,
  loading: true,
  minimal: false
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_FOOTER_STACK_DATA.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_FOOTER_STACK_DATA.FULFILLED:
      return {
        ...state,
        loading: false,
        contentStack: action.payload
      };
    case GET_FOOTER_STACK_DATA.REJECTED:
      return {
        ...state,
        loading: false
      };
    case SET_FOOTER_MINIMAL:
      return {
        ...state,
        minimal: action.minimal
      };
    case SET_FOOTER_HIDDEN:
      return {
        ...state,
        hidden: action.hidden
      };
    default:
      return state;
  }
};
