import actionComposer from 'utilities/actionComposer';
import { queryContentStackByUrl } from 'utilities/contentStack';
import {
  api_clearCart,
  api_getEditItem,
  api_getCurrentBuckUserCart,
  api_setActiveBuckUserCart,
  api_removeItem,
  api_updateItemQuantity
} from './api';

import { api_updateOrderStatus } from 'features/order/status/api';

export const CLEAR_CART = actionComposer('CLEAR_CART');
export const clearCart = orderId => ({
  type: CLEAR_CART.type,
  payload: api_clearCart(orderId)
});

export const EDIT_LINE_ITEM = actionComposer('EDIT_LINE_ITEM');
export const editLineItem = (itemIdentifier, url) => ({
  type: EDIT_LINE_ITEM.type,
  payload: api_getEditItem(itemIdentifier),
  meta: { url: url }
});

export const GET_BUCK_USER_CART = actionComposer('GET_BUCK_USER_CART');
export const getBuckUserCart = () => ({
  type: GET_BUCK_USER_CART.type,
  payload: api_getCurrentBuckUserCart()
});

export const SET_ACTIVE_BUCK_USER_CART = actionComposer(
  'SET_ACTIVE_BUCK_USER_CART'
);
export const setActiveBuckUserCart = orderToken => ({
  type: SET_ACTIVE_BUCK_USER_CART.type,
  payload: api_updateOrderStatus(orderToken, 'cart').then(() => {
    api_setActiveBuckUserCart(orderToken);
  })
});

export const GET_CART_STACK = actionComposer('GET_CART_STACK');
export const getContentStackData = url => ({
  type: GET_CART_STACK.type,
  payload: queryContentStackByUrl('cart', url, {
    includedReferences: [
      'related_references.resource_references',
      'related_references.blog_references'
    ]
  })
});

export const REMOVE_ITEM = actionComposer('REMOVE_ITEM');
export const removeItem = orderedItemId => ({
  type: REMOVE_ITEM.type,
  payload: api_removeItem(orderedItemId)
});

export const UPDATE_ITEM_QUANTITY = actionComposer('UPDATE_ITEM_QUANTITY');
export const updateItemQuantity = (orderedItemId, quantity) => ({
  type: UPDATE_ITEM_QUANTITY.type,
  payload: api_updateItemQuantity(orderedItemId, quantity)
});
