import { GET_COMPANY_FOOTER_STACK } from './actions';

export const getInitialState = () => ({
  stack: {
    investors: {
      title: '',
      investor_logos: []
    },
    in_the_news: {
      title: '',
      blog_reference: [],
      resource_reference: [],
      press_reference: []
    }
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_COMPANY_FOOTER_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_COMPANY_FOOTER_STACK.FULFILLED:
      return {
        ...state,
        stack: action.payload,
        loading: false
      };
    case GET_COMPANY_FOOTER_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
