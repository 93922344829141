import { GET_GUIDE_HOME_STACK } from 'features/guide/home/actions';

export const getInitialState = () => ({
  stack: {
    summary: '',
    body: '',
    description: '',
    background_image: { url: '' },
    title: '',
    chapter_references: [{ url: '' }],
    background_gradient: {
      color1: '',
      color2: ''
    }
  },
  loading: true
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case GET_GUIDE_HOME_STACK.PENDING:
      return {
        ...state,
        loading: true
      };
    case GET_GUIDE_HOME_STACK.FULFILLED:
      return {
        stack: action.payload,
        loading: false
      };
    case GET_GUIDE_HOME_STACK.REJECTED:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
